<mat-card class="form-padding" *ngIf="loading1||loading2">
    <mat-spinner diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>
</mat-card>
<mat-card *ngIf="!loading1&&!loading2">
    <mat-card-subtitle class="billing-font " style="padding: 20px 20px 20px 120px; color:#000; font-size: 22px">
        <span *ngIf="!currentTier">Please select a tier:</span>
        <mat-select [value]="currency" (selectionChange)="currencyChange($event)" class="currency-dropdown tier-base"
            [ngClass]="currency" disableOptionCentering panelClass="currencyPanel" *ngIf="!currentTier">
            <mat-option *ngFor="let currencyType of currencyTypes" [value]="currencyType.value">
                <img [src]="currencyType.flag" alt="" style=" width: 20px;margin-right: 2px; ">
                {{currencyType.viewValue}}
            </mat-option>
        </mat-select>
    </mat-card-subtitle>
    <!-- <div class="tier-container"> -->
    <!-- 100 -->
    <!-- <mat-card class="tier-card tier-current">
            <span class="tier-title ">
                <b *ngIf="sliderval==3">Up to 3</b><b *ngIf="sliderval>3&&sliderval<25">{{sliderval}}</b><b
                    *ngIf="sliderval==25">Unlimited</b> Users <br><br>
                <span
                    class="billing-font"><b>{{this.currencyValues.sign}}{{this.sliderval*this.currencyValues.t100.price}}</b>
                    /month</span>
            </span>
            <mat-card-content class="billing-font" style="height: fit-content;">
                {{30*this.sliderval}} unique recipients<br>
                Includes {{3*this.sliderval}} GB of storage<br>
                Unlimited emails<br>
                Extra units of 50 recipients: {{this.currencyValues.sign}}{{currencyValues.t100.extraUsage}} /month<br>
                Extra units of 5GB storage: {{this.currencyValues.sign}}{{currencyValues.t100.extraUsage}} /month<br>
            </mat-card-content>
            <mat-card-content style="height: fit-content;">
                License count:<br>
                <mat-slider thumbLabel [displayWith]="formatLabel" [(ngModel)]="sliderval" min="3" max="25"
                    aria-label="licenses" style="max-width: 300px;"></mat-slider>
            </mat-card-content>
        </mat-card>
    </div> -->
    <div style="display: flex;     justify-content: space-evenly;width: 100%;">
        <!-- 100 -->
        <!--<div class="tier-container">
            <mat-card class="tier-card"
                [ngClass]="[(currentTier=='1')? 'tier-current':'',(selectedTier=='1')? 'tier-highlight':'tier-base',(incNumber!=currentSeats)? 'billing-gridadj':'']">
                <span class="tier-title ">
                    <b>Basic</b> Business <br><br>
                    <span class="billing-font"><b>FREE</b></span>
                        <br *ngIf="incNumber!=currentSeats">
                        <span *ngIf="incNumber!=currentSeats"
                        class="billing-font"> </span>
                </span>
                <mat-card-content class="billing-font" style="height: fit-content;">
                    Unlimited users<br>
                    Includes 1 GB of storage<br>
                    Unlimited emails<br>
                    Extra units of 10GB storage: {{this.currencyValues.sign}}{{currencyValues.t100.extraUsage}}
                    per month<br>
                </mat-card-content>
                <mat-card-content style="display: flex; align-items: flex-end; justify-content: center;">
                    <button mat-stroked-button disabled class="current-pill" *ngIf="currentTier=='1'">Current</button>
                    <div class="select-pill" *ngIf="selectedTier!='1' &&currentTier!='1'" (click)="tierSelect('1')">
                        Select</div>
                    <div class="selected-pill" *ngIf="selectedTier=='1'" (click)="tierSelect('')">Selected</div>
                </mat-card-content>
            </mat-card>
        </div>-->
        <div class="tier-container">
            <!-- 100 -->
            <mat-card class="tier-card"
                [ngClass]="[(currentTier=='2')? 'tier-current':'',(selectedTier=='2')? 'tier-highlight':'tier-base',(incNumber!=currentSeats)? 'billing-gridadj':'']">
                <span class="tier-title ">
                    <b>Professional</b><br><br>
                    <!-- <span *ngIf="selectedTier=='2'||(currentTier=='2'&&selectedTier=='')"
                        class="billing-font"><b>{{this.currencyValues.sign}}{{(this.incNumber*this.currencyValues.t200.extraUsage)+this.currencyValues.t200.price}}</b>
                        /month</span>
                    <span *ngIf="selectedTier!='2'"
                        class="billing-font"><b>{{this.currencyValues.sign}}25</b>
                        /month</span> -->
                    <span *ngIf="incNumber!=currentSeats && selectedTier!='1' && currentTier!=''"
                        class="strike-billing"><b>{{this.currencyValues.sign}}{{PriceA1}}</b>
                        per month <div class="strike-billing-line"></div></span>
                    <br *ngIf="incNumber!=currentSeats && selectedTier!='1'">

                    <span *ngIf="incNumber>4" class="billing-font"><b>{{this.currencyValues.sign}}{{PriceA}}</b>
                        per month</span>
                    <span *ngIf="incNumber<5 && currentTier!=''"
                        class="billing-font"><b>{{this.currencyValues.sign}}{{(5*this.currencyValues.t200.extraUsage)+this.currencyValues.t200.price}}</b>
                        per month</span>
                </span>
                <mat-card-content class="billing-font" style="height: fit-content;">
                    Includes 5 users<br>
                    Includes 10GB of storage<br>
                    Unlimited emails<br>
                    Branding<br>
                    Each additional user: {{this.currencyValues.sign}}{{currencyValues.t200.extraUsage}} per month<br>
                    Extra units of 10GB storage: {{this.currencyValues.sign}}{{currencyValues.t100.extraUsage}}
                    per month<br>
                </mat-card-content>
                <mat-card-content style="display: flex; align-items: flex-end; justify-content: center;">
                    <button mat-stroked-button disabled class="current-pill" *ngIf="currentTier=='2'">Current</button>
                    <div class="select-pill" *ngIf="selectedTier!='2' &&currentTier!='2'" (click)="tierSelect('2')">
                        Select</div>
                    <div class="selected-pill" *ngIf="selectedTier=='2'" (click)="tierSelect('')">Selected</div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="tier-container">
            <!-- 100 -->
            <mat-card class="tier-card"
                [ngClass]="[(currentTier=='3')? 'tier-current':'',(selectedTier=='3')? 'tier-highlight':'tier-base',(incNumber!=currentSeats)? 'billing-gridadj':'']">
                <span class="tier-title ">
                    <b>Professional plus</b><br><br>
                    <!-- <span *ngIf="selectedTier=='3'||(currentTier=='3'&&selectedTier=='')"
                        class="billing-font"><b>{{this.currencyValues.sign}}{{(this.incNumber*this.currencyValues.t300.extraUsage)+this.currencyValues.t300.price}}</b>
                        /month</span>
                    <span *ngIf="selectedTier!='3'"
                        class="billing-font"><b>{{this.currencyValues.sign}}75</b>
                        /month</span> -->
                    <span *ngIf="incNumber!=currentSeats && incNumber>10&& selectedTier!='1' && currentTier!=''"
                        class="strike-billing"><b>{{this.currencyValues.sign}}{{PriceB1}}</b>
                        per month <div class="strike-billing-line"></div></span>
                    <br *ngIf="incNumber!=currentSeats">

                    <span *ngIf="incNumber>9" class="billing-font"><b>{{this.currencyValues.sign}}{{PriceB}}</b>
                        per month</span>
                    <span *ngIf="incNumber<10"
                        class="billing-font"><b>{{this.currencyValues.sign}}{{(10*this.currencyValues.t300.extraUsage)+this.currencyValues.t300.price}}</b>
                        per month</span>
                </span>
                <mat-card-content class="billing-font" style="height: fit-content;">

                    Includes 10 users<br>
                    Includes 20GB of storage<br>
                    Unlimited emails<br>
                    Branding<br>
                    Electronic document signing<br>
                    Auto-deleting emails<br>
                    Each additional user: {{this.currencyValues.sign}}{{currencyValues.t300.extraUsage}} per month<br>
                    Extra units of 10GB storage: {{this.currencyValues.sign}}{{currencyValues.t100.extraUsage}}
                    per month<br>
                </mat-card-content>
                <mat-card-content style="display: flex; align-items: flex-end; justify-content: center;">
                    <button mat-stroked-button disabled class="current-pill" *ngIf="currentTier=='3'">Current</button>
                    <div class="select-pill" *ngIf="selectedTier!='3' &&currentTier!='3'" (click)="tierSelect('3')">
                        Select</div>
                    <div class="selected-pill" *ngIf="selectedTier=='3'" (click)="tierSelect('')">Selected</div>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
    <!--<div class="tier-input-center" *ngIf="
            ( currentTier=='' && (selectedTier!='1'&&selectedTier!='')) || 
            ((currentTier=='2'||currentTier=='3') && selectedTier!='1') || 
            (currentTier=='1' && (selectedTier!='1'&&selectedTier!=''))">User count:<br>

        <div [ngClass]="( this.incNumber!=this.currentSeats && !(this.selectedTier == '3' && this.currentSeats < 10  && this.incNumber == 10)&& !(this.selectedTier == '2' && this.currentSeats < 5  && this.incNumber == 5)
        )? 'tier-input-centeradj':''">
            <span class="input-number-decrement" (click)="add(-1)">-</span><input class="input-number" type="number"
                [(ngModel)]="incNumber" min="5" (change)="add(0)"><span class="input-number-increment"
                (click)="add(1)">+</span>
            <button mat-icon-button aria-label="Reset user count" (click)="resetUserCount()" class="field-icon-adj"
                #tooltip="matTooltip" matTooltip="Reset to current user count" matTooltipPosition="right"
                *ngIf="( this.incNumber!=this.currentSeats && !(this.selectedTier == '3' && this.currentSeats < 10  && this.incNumber == 10) && !(this.selectedTier == '2' && this.currentSeats < 5  && this.incNumber == 5))">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

    </div>-->
    <!-- {{this.incNumber!=this.currentSeats}}
    {{!(this.selectedTier == '3' && this.currentSeats < 10 && this.incNumber==10)}} {{showRefresh}} <br><br>
        {{( currentTier!='' && (selectedTier!='1'&&selectedTier!=''))}}
        {{((currentTier=='2'||currentTier=='3') && (currentTier=='2'))}}
        {{(currentTier=='1' && (selectedTier!='1'&&selectedTier!=''))}}
        {{currentTier}} - {{selectedTier}} || {{incNumber}} - {{currentSeats}} -->
    <button mat-stroked-button *ngIf=" !(subActive) && (selectedTier && selectedTier!='')" class="tier-center"
        (click)="checkout()">Continue to
        payment</button>
    <button mat-stroked-button *ngIf=" (subActive) && (selectedTier && selectedTier!='')" class="tier-center"
        (click)="subChange()">Update Plan</button>

    <button mat-stroked-button
        *ngIf="(subActive&&incNumber!=currentSeats) && !(selectedTier && selectedTier!='') && currentTier!='1'"
        class="tier-center" (click)="subChange()">Change users</button>

    <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title" *ngIf="details">Billing
        information
    </mat-card-subtitle>
    <div style="margin: 0px 10px 10px 10px;">
        <div *ngIf="details">
            Name: {{details.name}}<br>
            Email: {{details.email}}<br><br>
            Address:<br>
            <span *ngIf="details.address.line1">{{details.address.line1}}<br></span>
            <span *ngIf="details.address.line2">{{details.address.line2}}<br></span>
            <span *ngIf="details.address.city">{{details.address.city}}<br></span>
            <span *ngIf="details.address.state">{{details.address.state}}<br></span>
            <span *ngIf="details.address.country">{{details.address.country}}<br></span>
            <span *ngIf="details.address.postal_code">{{details.address.postal_code}}<br></span>
        </div>

        <br>
        <div style="display: flex;">
            <button mat-stroked-button *ngIf="customerId" style="margin-right: auto;" (click)="infoChange()">Update
                billing information</button>
            <button mat-stroked-button *ngIf="currentTier" class="cancel-sub" (click)="cancelSub()">Cancel
                subscription</button>
        </div>
    </div>
</mat-card>