import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/company.model';
import { Globals } from 'src/app/globals';
import { CompanyCheckerComponent } from '../company-checker/company-checker.component';
interface SelectorType {
  value: string;
  viewValue: string;
  flag?: string;
}
@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent implements OnInit, DoCheck {

  constructor(
    public globals: Globals,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private locations: Location,
    public dialog: MatDialog,
    private titleService: Title
  ) {
    route.params.subscribe(params => {

      if (this.companyID != params.id) {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
        // this.loading=true;location.reload();
      }


    })
  }
  @ViewChild('tabGroup') tabGroup;
  @ViewChild('setupTab') setupTab;
  @ViewChild('hierarchyTab') hierarchyTab;
  tabNumber=0;
  company: any;
  companytype: string;
  keyword: string;
  expireKeyword: string;
  setupMethod: string;
  canAutoDelete: boolean;
  editMode: boolean;
  tier = "";
  companyID = this.route.snapshot.params['id'];
  myCompanyType = this.globals.getCompanyType();
  companyIdentTitle;
  notFound = false;
  companyTypes: SelectorType[] = [
    { value: 'S', viewValue: 'Super', flag: 'N' },
    { value: 'W', viewValue: 'Wholesaler', flag: 'N' },
    { value: 'W', viewValue: 'Wholesaler', flag: 'Y' },
    { value: 'R', viewValue: 'Partner', flag: 'Y' },
    { value: 'C', viewValue: 'Customer', flag: 'N' },
  ];
  timeFormats: SelectorType[] = [
    { value: 'D', viewValue: 'Days', },
    { value: 'H', viewValue: 'Hours', },
    { value: 'M', viewValue: 'Minutes' },
    { value: 'S', viewValue: 'Seconds' },
  ];
  initialTiers = this.globals.getTiers();
  addCompanyForm = this.fb.group({
    CompanyIdent: [null, Validators.required],
    CompanyName: [null, Validators.required],
    CompanyType: [null, Validators.required],
    CompanyAddress: [null],
    CompanyTelNo: [null],
    CompanyVatNo: [null],
    InitialTier: [null],
    ContactName: [null],
    ContactEmail: [null],
    ContactTelNo: [null],
    DataCountryCode: [null],
    StatusInd: [null],
    AllowMsgAutoDeleteFlag: [null],
    DomainList: new FormArray([]),
    SetUpUserEmail: [null],
    SeatCount: [null]
  });
  showEdit = true;
  onTabChanged($event) {
    if ($event.index === 0) {
      this.route.snapshot.fragment = null
      this.showEdit = true;
      this.onCancel()

      this.update();
      this.addCompanyForm.disable();
      this.editMode = false;
    } else { this.showEdit = false; this.update(); }
  }
  checked = false;
  loading = true;
  hierarchy = []
  tempHierarchy = []
  childHeirarchy = []

  ngDoCheck() {
    if (this.checked == false) {
      if (this.route.snapshot.fragment == 'setup' && this.tabGroup) {
        const pathWithoutHash = this.locations.path(false);
        this.locations.replaceState(pathWithoutHash);
        this.tabGroup.animationDuration = 0;
        this.tabGroup.selectedIndex = this.setupTab.position
        let animationSub = this.tabGroup.animationDone.subscribe((event) => {
          this.tabGroup.animationDuration = 500;
          animationSub.unsubscribe()
        });
        this.checked = true
      }else if(this.tabGroup && this.hierarchyTab){this.tabNumber = this.hierarchyTab.position;this.checked = true}
    }
  }
  async ngOnInit(): Promise<void> {
    this.editMode = false;
    this.globals.refreshAuth();
    this.company = { Name: "", Id: "", CompanyId: "", CompanyIdent: "", CompanyName: "", ParentCompanyId: "", MsgAutoDeleteTag: "", IsDealerFlag: "", SPMCompanyType: "", AllowMsgAutoDeleteFlag: "", MaxTimeToDeleteInd: "", TimeToDeleteAfterReadInd: "", DataCountryCode: "", StatusInd: "" }
    this.update()

    if (this.editMode) { this.addCompanyForm.enable(); }
    else { this.addCompanyForm.disable(); }

    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('DataIncludeInd', 'F');
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyHierarchy.php', { params: param }).subscribe({
      next: data => {
        for (let company of data.CompanyList) {
          if (company.Id == this.companyID) {
            let cycle = [company]
            for (let companyCycle of cycle) {
              let i = 0;
              this.hierarchy.push(companyCycle)
              this.tempHierarchy.push(companyCycle)

              for (let parent of data.CompanyList) {
                if (parent.Id == companyCycle.ParentCompanyId) {
                  cycle.push(parent)
                } else {
                  i++; if (i == data.CompanyList.length) {
                    this.hierarchy.reverse()
                    this.tempHierarchy.reverse()
                    // ------------------------------------
                    for (let company of data.CompanyList) {
                      if (company.Id == this.companyID) {
                        let cycle = [company]
                        let i = 0;
                        let imax=0;

                        for (let companyCycle of cycle) {
                          imax++
                          i=0

                          for (let child of data.CompanyList) {
                            i++;

                            if (child.ParentCompanyId == companyCycle.Id) {
                              this.childHeirarchy.push(child)
                              cycle.push(child)
                            } else {
                               if (i == data.CompanyList.length&&imax==cycle.length) {
                                
                                // dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd



                                this.childHeirarchy.sort(function (x, y) {
                                  let a = x.Id.toUpperCase(),
                                    b = y.Id.toUpperCase();
                                  return a == b ? 0 : a > b ? 1 : -1;
                                });



                                // var iii, ii
                                // for (iii = (this.childHeirarchy.length - 1); iii >= 0; iii--) {
                                //   if (this.childHeirarchy[iii].ParentCompanyId != this.globals.getCompanyId()) {
                                //     for (ii = (this.childHeirarchy.length - 1); ii >= 0; ii--) {
                                //       if (this.childHeirarchy[ii].Id == this.childHeirarchy[iii].ParentCompanyId) {

                                //         if (this.childHeirarchy[ii].children != null) {
                                //           this.childHeirarchy[ii].children.push(this.childHeirarchy[iii]);
                                //           this.childHeirarchy[ii].children.sort(function (x, y) {
                                //             let a = x.Name.toUpperCase(),
                                //               b = y.Name.toUpperCase();
                                //             return a == b ? 0 : a > b ? 1 : -1;
                                //           });

                                //           this.childHeirarchy.splice(iii, 1)
                                //           break;
                                //         }
                                //         if (this.childHeirarchy[ii].children == null) {
                                //           this.childHeirarchy[ii].children = [this.childHeirarchy[iii]];
                                //           this.childHeirarchy.splice(iii, 1)
                                //           break;
                                //         }
                                //       }
                                //     }
                                //   }
                                // }




                                var x, y
                                for (x = (this.childHeirarchy.length - 1); x >= 0; x--) {
                                  if (this.childHeirarchy[x].ParentCompanyId != this.globals.getCompanyId()) {
                                    for (y = (this.childHeirarchy.length - 1); y >= 0; y--) {
                                      if (this.childHeirarchy[y].Id == this.childHeirarchy[x].ParentCompanyId) {

                                        if (this.childHeirarchy[y].children != null) {
                                          this.childHeirarchy[y].children.push(this.childHeirarchy[x]);
                                          this.childHeirarchy[y].children.sort(function (x, y) {
                                            let a = x.Name.toUpperCase(),
                                              b = y.Name.toUpperCase();
                                            return a == b ? 0 : a > b ? 1 : -1;
                                          });

                                          this.childHeirarchy.splice(x, 1)
                                          break;
                                        }
                                        if (this.childHeirarchy[y].children == null) {
                                          this.childHeirarchy[y].children = [this.childHeirarchy[x]];
                                          this.childHeirarchy.splice(x, 1)
                                          break;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    // ------------------------------------
                  }
                }
              }
            }

          }
        }

      },
      error: error => { console.error('There was an error!'); }
    })
    // this.tabNumber = this.hierarchyTab.position
  }
  editClicked() {
    this.editMode = !this.editMode;
    this.addCompanyForm.enable();
    this.addCompanyForm.controls['CompanyIdent'].disable();
    this.addCompanyForm.controls['DataCountryCode'].disable();
    this.addCompanyForm.controls['CompanyType'].disable();
    if (!(this.tier == '0' || this.tier == '')) {
      this.addCompanyForm.controls['InitialTier'].disable();
    } else if (this.tier == '0') { this.addCompanyForm.controls['InitialTier'].setValue('0') }
  }
  seatCount = 0;
  currentSeatCount = 0;
  seatCountView;
  minSeat = 5;

  async update() {
    
    let param1 = new HttpParams();
    param1 = param1.append('AuthToken', await this.globals.getAuthToken());
    param1 = param1.append('UserName', this.globals.getSystemUserName());
    param1 = param1.append('companyId', this.companyID);
    this.http.get(this.globals.getServicesUrl() + 'company/getCompanyDetails', { params: param1 }).subscribe({
      next: data => {
        if (data[0] && data[0].keyword && data[0].keyword != "") {
          this.keyword = data[0].keyword
          if (this.expireKeyword == "undefined-expire") {
            this.expireKeyword = data[0].keyword + "-expire"
          }
          if (data[0].setupMethod == 'mx') {
            this.setupMethod = 'Microsoft 365';
          }
          if (data[0].setupMethod == 'google') {
            this.setupMethod = 'Google Workspace'
          }
          if (data[0].setupMethod == 'othermx') {
            this.setupMethod = 'Other'
          }
          if (data[0].setupMethod == 'spf') {
            this.setupMethod = 'Other'
          }
        }
        if (data[0] && data[0].autoDeleteFlag) {
          this.canAutoDelete = data[0].autoDeleteFlag.includes("Y")
        }
        if (data[0] && data[0].seatCount) {
          this.seatCount=parseInt(data[0].seatCount)
          this.currentSeatCount=parseInt(data[0].seatCount)
          this.addCompanyForm.controls['SeatCount'].setValue(this.seatCount);
          if(this.seatCount<=3){this.seatCountView = "Up to 3 Users"}
          if(this.seatCount>3 && this.seatCount < 25){ this.seatCountView = this.seatCount+" Users"}
          if(this.seatCount>=25){this.seatCountView = "Unlimtied Users"}

        }
       

      },
      error: error => { console.error('There was an error!'); }
    });
    let param = new HttpParams();
    param = param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('CompanyId', this.companyID);
    this.http.get<Company>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: data => {
        this.company = data
        this.tier = this.company.InitialTier
        if (this.company.SPMCompanyType == "S") { this.companytype = "Super" }
        if (this.company.SPMCompanyType == "W" && this.company.IsDealerFlag == "N") { this.companytype = "Wholesaler" }
        if (this.company.SPMCompanyType == "W" && this.company.IsDealerFlag == "Y") { this.companytype = "Wholesaler" }
        if (this.company.SPMCompanyType == "R") { this.companytype = "Partner" }
        if (this.company.SPMCompanyType == "C") { this.companytype = "Customer" }
        try {
          this.company.InitialTier = this.globals.getTier(this.company.InitialTier).name;
            
          } catch (error) {
            this.company.InitialTier = "Unknown"
            
          }
          // if (this.company.InitialTier == "0") { this.company.InitialTier = "Not applicable" }
        // if (this.company.InitialTier == "100") { this.company.InitialTier = "100 Recipients" }
        // if (this.company.InitialTier == "101") { this.company.InitialTier = "100 Recipients + Branding" }
        // if (this.company.InitialTier == "500") { this.company.InitialTier = "500 Recipients" }
        // if (this.company.InitialTier == "501") { this.company.InitialTier = "500 Recipients + Branding" }
        // if (this.company.InitialTier == "1000") { this.company.InitialTier = "1,000 Recipients" }
        // if (this.company.InitialTier == "99999") { this.company.InitialTier = "Unlimited Recipients" }
        if (this.company.DataCountryCode == "GB") { this.company.DataCountryCode = "United Kingdom" }
        if (this.company.DataCountryCode == "DE") { this.company.DataCountryCode = "European Union" }
        if (this.company.DataCountryCode == "US") { this.company.DataCountryCode = "United States" }
        if (this.company.MaxTimeToDeleteInd == "") { this.company.MaxTimeToDeleteInd = "D:7" }
        if (this.company.TimeToDeleteAfterReadInd == "") { this.company.TimeToDeleteAfterReadInd = "M:10" }
        if (this.company.MsgAutoDeleteTag == "") { this.expireKeyword = this.keyword + "-expire" } else { this.expireKeyword = this.company.MsgAutoDeleteTag }
        let deleteTime = this.company.MaxTimeToDeleteInd.split(":")
        let deleteRead = this.company.TimeToDeleteAfterReadInd.split(":")
        this.titleService.setTitle(this.company.CompanyName + " - STAY PRIVATE");
        this.addCompanyForm = this.fb.group({
          CompanyIdent: [{ value: this.company.CompanyIdent, disabled: this.editMode }, Validators.required],
          CompanyName: [{ value: this.company.CompanyName, disabled: this.editMode }, Validators.required],
          CompanyType: [{ value: this.companytype, disabled: this.editMode }, Validators.required],
          CompanyAddress: [{ value: this.company.CompanyAddress, disabled: this.editMode }],
          CompanyTelNo: [{ value: this.company.CompanyTelNo, disabled: this.editMode }],
          CompanyVatNo: [{ value: this.company.CompanyVatNo, disabled: this.editMode }],
          InitialTier: [{ value: this.company.InitialTier, disabled: this.editMode }],
          DataCountryCode: [{ value: this.company.DataCountryCode, disabled: this.editMode }],
          StatusInd: [{ value: this.company.StatusInd, disabled: this.editMode }],
          ContactName: [{ value: this.company.ContactName, disabled: this.editMode }],
          ContactEmail: [{ value: this.company.ContactEmail, disabled: this.editMode }],
          ContactTelNo: [{ value: this.company.ContactTelNo, disabled: this.editMode }],
          AllowMsgAutoDeleteFlag: [{ value: this.company.AllowMsgAutoDeleteFlag.includes("Y"), disabled: this.editMode }],
          MaxTimeToDeleteInd: [{ value: deleteTime[1], disabled: this.editMode }],
          MaxTimeToDeleteIndFormat: [{ value: deleteTime[0], disabled: this.editMode }],
          TimeToDeleteAfterReadInd: [{ value: deleteRead[1], disabled: this.editMode }],
          TimeToDeleteAfterReadIndFormat: [{ value: deleteRead[0], disabled: this.editMode }],
          DomainList: [{ value: this.company.DomainList, disabled: this.editMode }],
          EmailProcessRulesDomainList: [{ value: this.company.EmailProcessRulesDomainList, disabled: this.editMode }],
          SeatCount:[{value: this.seatCount,disabled:this.editMode}]
        });
        this.http.get(this.globals.getServicesUrl() + 'company/getCompanyDetails', { params: param1 }).subscribe({
          next: data => {
            if (data[0] && data[0].seatCount) {
              this.seatCount=data[0].seatCount

              this.addCompanyForm.controls['SeatCount'].setValue(this.seatCount);
              if(this.seatCount<=3){this.seatCountView = "Up to 3 Users"}
          if(this.seatCount>3 && this.seatCount < 25){ this.seatCountView = this.seatCount+" Users"}
          if(this.seatCount>=25){this.seatCountView = "Unlimtied Users"}
            }
          },
          error: error => { console.error('There was an error!'); }
        });
        console.log(this.addCompanyForm.value.InitialTier)

        if(this.addCompanyForm.value.InitialTier=='Professional'||this.addCompanyForm.value.InitialTier=='Professional Plus'||this.addCompanyForm.value.InitialTier=='Strict'){
          if(this.addCompanyForm.value.InitialTier=='Professional'){this.minSeat=5}
          if(this.addCompanyForm.value.InitialTier=='Professional Plus'){this.minSeat=10}
          if(this.addCompanyForm.value.InitialTier=='Strict'){this.minSeat=15}
          if(this.seatCount<this.minSeat){ this.seatCount=this.minSeat}
          console.log(this.seatCount,this.minSeat)
        }
        if (this.editMode) { this.addCompanyForm.enable(); }
        else { this.addCompanyForm.disable(); }
        this.companyIdentTitle = this.company.CompanyIdent
        this.loading = false;
      },
      error: error => { console.error('There was an error!'); this.notFound = true; this.loading = false; }
    });

  }
  add(){
    console.log(this.seatCount,this.minSeat)
    if(this.seatCount<this.minSeat){ this.seatCount=this.minSeat}
  }
  onSubmit() {
    if(!this.addCompanyForm.dirty&&this.currentSeatCount!=this.seatCount ){
      this.updateSeatCount(this.addCompanyForm.value)
    }else if (this.addCompanyForm.dirty&&this.addCompanyForm.valid) {
      this.addCompanyForm.value.DomainList = "";
      this.addCompany(this.addCompanyForm.value);
    }
  }

  async addCompany(formData) {
    this.loading = true

    let form = new FormData;
    form.append('AuthToken', await this.globals.getAuthToken());
    form.append('UserName', this.globals.getSystemUserName());
    form.append('CompanyId', this.companyID);
    form.append('CompanyName', formData.CompanyName);
    if (formData.CompanyAddress != null) { form.append('CompanyAddress', formData.CompanyAddress); }
    if (formData.CompanyTelNo != null) { form.append('CompanyTelNo', formData.CompanyTelNo); }
    if (formData.CompanyVatNo != null) { form.append('CompanyVatNo', formData.CompanyVatNo); }
    if (formData.ContactName != null) { form.append('ContactName', formData.ContactName); }
    if (formData.ContactEmail != null) { form.append('ContactEmail', formData.ContactEmail); }
    if (formData.ContactTelNo != null) { form.append('ContactTelNo', formData.ContactTelNo); }
    if (formData.InitialTier != null) { form.append('InitialTier', formData.InitialTier); }
    if (formData.AllowMsgAutoDeleteFlag == true) {
      form.append('AllowMsgAutoDeleteFlag', "Y");
      form.append('MaxTimeToDeleteInd', formData.MaxTimeToDeleteIndFormat + ":" + formData.MaxTimeToDeleteInd);
      form.append('TimeToDeleteAfterReadInd', formData.TimeToDeleteAfterReadIndFormat + ":" + formData.TimeToDeleteAfterReadInd);
      form.append('MsgAutoDeleteTag', this.keyword + "-expire");
    } else { form.append('AllowMsgAutoDeleteFlag', "N"); }

    this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', form).subscribe({
      next: async data => {
        if(formData.SeatCount!=this.seatCount){
          let form2 = new FormData;
          form2.append('AuthToken', await this.globals.getAuthToken());
          form2.append('UserName', this.globals.getSystemUserName());
          form2.append('CompanyId', this.companyID);
          form2.append('SeatCount', formData.SeatCount);
          this.http.post<any>(this.globals.getServicesUrl() + 'company/updateUserCount', form2).subscribe({
            next: data => {
              this.update();
              this.onCancel()
            },
            error: error => {
              console.error('There was an error!', error);
            }
          });
        }else{
          this.update();
          this.onCancel()
        }
        

      },
      error: error => {
        console.error('There was an error!');
      }
    });
  }

  async updateSeatCount(formData){
    this.loading = true
          let form2 = new FormData;
          form2.append('AuthToken', await this.globals.getAuthToken());
          form2.append('UserName', this.globals.getSystemUserName());
          form2.append('CompanyId', this.companyID);
          form2.append('SeatCount', this.seatCount.toString());
          this.http.post<any>(this.globals.getServicesUrl() + 'company/updateUserCount', form2).subscribe({
            next: data => {
              this.update();
              this.onCancel()
            },
            error: error => {
              console.error('There was an error!', error);
            }
          });
        
  }

  onCancel() {
    //this.addCompanyForm.reset();
    this.update();
    this.loading = true

    this.addCompanyForm.disable();
    this.editMode = false;
  }

  
  showConfigEdit = false;
  configDirty = false;
  editConfigMode = false;
  newConfig: any
  getConfig(form:FormGroup){
    this.newConfig = form;
  }
  showConfig(bool:boolean){ this.showConfigEdit = bool; this.editConfigMode = false; }
  onConfigDirty(bool:boolean) { this.configDirty = bool; }
  formatArray(val:any){
    // var temp = this.configForm.controls['domains'].value.replaceAll(' ', ';')
    var temp = val.replaceAll(' ', ';')
    temp = temp.replaceAll('	', ';')
    temp = temp.replaceAll(',', ';')
    temp = temp.replaceAll('\n', ';')
    temp = temp.split(';')
    temp = temp.filter(function (el) {
      if(el != null && el !=''){
        return el;
      }
    });
    return temp
  }
  editConfigClicked() {
    this.editConfigMode = !this.editConfigMode;
  }
  onConfigCancel() {
    this.editConfigMode = false;
  }
  async onConfigSubmit(){
    /*
    this.configForm.patchValue({
      CompanyKeyword: this.company.CompanyKeyword,
      IgnoreKeyword: this.company.IgnoreKeyword,
      domains: this.company.IgnoreKeyword,
      RelaxedList: this.company.EmailProcessRulesRelaxedList.join('\n'),
      SmartList: this.company.EmailProcessRulesSmartList.join('\n'),
      StandardList: this.company.EmailProcessRulesStandardList.join('\n'),
      EnforcementType: this.company.EmailProcessRulesDomainList[0].RulesInd,
      EnforcementTypeFull: this.getTypeName(this.company.EmailProcessRulesDomainList[0].RulesInd),
      Statement: this.company.DefProtectSensitiveInfoText,
    })
    */
    var tempRelaxedList = this.formatArray(this.newConfig.RelaxedList.value);
    var tempSmartList = this.formatArray(this.newConfig.SmartList.value);
    var tempStandardList = this.formatArray(this.newConfig.StandardList.value);

    let form = new FormData;
    form.append('AuthToken', await this.globals.getAuthToken());
    form.append('UserName', this.globals.getSystemUserName());
    form.append('CompanyId', this.companyID);

    if (this.company.CompanyKeyword != this.newConfig.CompanyKeyword.value || this.newConfig.CompanyKeyword.value =='') { 
      if(this.newConfig.CompanyKeyword.value!=''){form.append('CompanyKeyword', this.newConfig.CompanyKeyword.value);}else{form.append('CompanyKeyword', '#stayprivate');}
    }
    if (this.company.IgnoreKeyword != this.newConfig.IgnoreKeyword.value || this.newConfig.IgnoreKeyword.value =='') { 
      if(this.newConfig.IgnoreKeyword.value!=''){form.append('IgnoreKeyword', this.newConfig.IgnoreKeyword.value);}else{form.append('IgnoreKeyword', '#ignore');}
    }
    if (this.company.EmailProcessRulesRelaxedList != tempRelaxedList) { form.append('EmailProcessRulesRelaxedList', tempRelaxedList); }
    if (this.company.EmailProcessRulesSmartList != tempSmartList) { form.append('EmailProcessRulesSmartList', tempSmartList); }
    if (this.company.EmailProcessRulesStandardList != tempStandardList) { form.append('EmailProcessRulesStandardList', tempStandardList); }

    if (this.company.EmailProcessRulesDomainList[0].RulesInd != this.newConfig.EnforcementType.value) { form.append('EmailProcessRulesDomainList', this.company.EmailProcessRulesDomainList[0].Domain+':'+this.newConfig.EnforcementType.value); }
    if (this.company.DefProtectSensitiveInfoText != this.newConfig.Statement.value) { form.append('DefProtectSensitiveInfoText', this.newConfig.Statement.value); }

    this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', form).subscribe({
       next: async data => { this.update(); this.editConfigMode=false},
       error: error => {
        console.error('There was an error!');
      }
    });
    
  }
}
