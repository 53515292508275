<mat-toolbar class="sp-background">
  <!-- <button mat-button class="nav-menu-button" style="color:#fff" (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button> -->
  <!-- <img style="max-height: 30px;"src="../../assets/images/SP_Logo_Icon+Text_White.svg" alt="" (click)="sidenav.toggle()"> -->

  <!-- <span id="dashboard-title" class="top-title"  style="color:#fff">{{appName}}</span>  -->
  
  <img style="max-height: 19px; cursor: pointer;"src="../../assets/images/SP_Logo_Icon_White.svg" alt="" (click)="sidenav.toggle()">
  <img style="max-height: 16px; padding-left: 10px;"src="../../assets/images/SP_Text_White.svg" alt="">

  <div class="top-right-button">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="" style="color:#fff">
    <mat-icon class="">account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/account']">
        <mat-icon>account_circle</mat-icon>
        <span>My Account</span>
      </button>
      <button mat-menu-item [routerLink]="['/notifications']" >
        <mat-icon><span class="material-icons-outlined">
          notifications_active
          </span></mat-icon>
        <span>Notifications</span>
      </button>
      <button mat-menu-item (click)="update()" *ngIf="myCompanyType=='Super'">
        <mat-icon><span class="material-icons-outlined">
          request_quote
          </span></mat-icon>
        <span>Generate Invoices</span>
      </button>
      <button mat-menu-item (click)="downloadCompanies()" >
        
          <mat-icon><span class="material-icons-outlined">
            apartment
            </span></mat-icon>
        <span>Download Company List</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span>Log Out</span>
      </button>
    </mat-menu>
  </div>

</mat-toolbar>  
<mat-sidenav-container class="main-container">
  <mat-sidenav class="sidenav-container mat-elevation-z2" [opened]="start" #sidenav [mode]="mode.value" >
    <div *ngIf="myCompanyType!='Customer'">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/home']">
        <mat-icon>home</mat-icon> Home
      </button>
    </div>
    <div *ngIf="myCompanyType=='Wholesaler'||myCompanyType=='Super'||myCompanyType=='Partner'">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/add-company']">
        <mat-icon class="icon-color">add_business</mat-icon> Add New Company
      </button>
    </div>
    <div>
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/mycompany']">
        <mat-icon class="icon-color">business</mat-icon> My Company
      </button>
    </div>
    <div *ngIf="myCompanyType=='Customer'">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/setup']">
        <mat-icon class="icon-color">business</mat-icon> Setup
      </button>
    </div>
    <div *ngIf="myCompanyType=='Super'">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/edit-theme']">
        <mat-icon class="icon-color">color_lens</mat-icon> My Theme
      </button>
    </div>    
    <div *ngIf="isDeveloper">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/developer']">
        <mat-icon class="icon-color">code</mat-icon> Developer
      </button>
    </div> 
    <div *ngIf="useStripeBilling">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/billing']">
        <mat-icon class="icon-color">payment</mat-icon> Billing
      </button>
    </div>   
    <div *ngIf="myCompanyType=='Wholesaler'||myCompanyType=='Super'||myCompanyType=='Partner'">
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/resources']">
        <mat-icon class="icon-color">library_books</mat-icon> Resources
      </button>
    </div> 
    <div>
      <button mat-button class="nav-menu-link sp-title" routerLinkActive="nav-menu-link-active" [routerLink]="['/contact-us']">
        <mat-icon class="icon-color">contact_support</mat-icon> Contact Us
      </button>
    </div>     
    <!-- <div>
      <button mat-button class="nav-menu-link sp-title">
        Contracts &amp; Terms
      </button>
    </div>
    <div>
      <button mat-button class="nav-menu-link sp-title">
        User Guides
      </button>
    </div> -->
    
    <div>
      
      <hr class="nav-menu-sp"style="width: 200px; bottom: 40px">
      <span class="nav-menu-link nav-menu-sp sp-title" style="width: 200px; bottom: 6px">
        Auto logout in: {{globals.timeoutTrackerObj.time.minute}}:{{globals.timeoutTrackerObj.time.second}}
      </span>
    </div>
  </mat-sidenav>  

  <mat-sidenav-content class="main-stage" style="margin-right: 3px;">
    <div *ngIf="myCompanyState=='I'" class="suspended-banner">Your account is currently suspended. Please contact support for further information.</div>
    <router-outlet></router-outlet>
    <!-- <app-compose class="compose-size"></app-compose> -->
  </mat-sidenav-content>
</mat-sidenav-container>


