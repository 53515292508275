<mat-card style="width: 100%;">
  <!-- <iframe src="https://stayprivate.helpjuice.com/" frameborder="0" style="width: 100%; height: 100%;"></iframe> -->

    <mat-card-title class="title-padding sp-title">
        Resources
        <mat-button-toggle-group #group="matButtonToggleGroup" value="list" style="float: right;">
            <mat-button-toggle value="list" aria-label="Text align left">
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="grid" aria-label="Text align center">
              <mat-icon>view_comfy</mat-icon>
            </mat-button-toggle>            
          </mat-button-toggle-group>
    </mat-card-title> 

    <!-- Grid View -->
    <mat-card-content class="center-card" *ngIf="group.value=='grid'">
        <br>
        <mat-card class="resource-card" *ngFor="let resource of resources">
            <img mat-card-image  [src]="resource.imageUrl">
            <div style="min-height: 40px;align-content: center;display: grid;">
              <mat-card-title class="card-title sp-title"><a class="sp-title" target="_blank" rows="3" [href]="resource.docLink">{{resource.docName}}</a></mat-card-title>
            </div>
        </mat-card>
    </mat-card-content>
    <!-- List View -->
    <mat-card-content class="center-card" *ngIf="group.value=='list'">
        <table mat-table #table [dataSource]="resources"*ngIf="resources.length>0" style="width:100%;">
            <ng-container matColumnDef="Thumbnail" >
              <th mat-header-cell *matHeaderCellDef >  </th>
              <td mat-cell *matCellDef="let resource"style="width: 80px;">  
                <span><img class="list-thumbnail" [src]="resource.imageUrl" alt=""></span> 
              </td>
            </ng-container>
            <ng-container matColumnDef="DocName">
              <th mat-header-cell *matHeaderCellDef> File Name </th>
              <td mat-cell *matCellDef="let resource"> 
                <span><a class="sp-title" target="_blank" [href]="resource.docLink" rows="3">{{resource.docName}}</a></span> 
              </td>
            </ng-container>
           
            <ng-container matColumnDef="Options" stickyEnd style="width: 64px;">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell *matCellDef="let user"> <button mat-icon-button (click)="moreOpts(user)"><mat-icon>more_vert</mat-icon></button> </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> 

    </mat-card-content>   
  </mat-card>
