<mat-card *ngIf="isDeveloper" class="company-form">
    <mat-card-title class="title-padding sp-title">
      Developer Settings<br>
    </mat-card-title>
    <mat-card-subtitle class="sp-title dev-subtitle">API Settings</mat-card-subtitle>
    <div style="max-width: 300px; padding-left: 10px;">
      <mat-label class="company-detail-tab company-detail-tab-active">API Access ID</mat-label><br>
      <mat-form-field appearance="outline" style="width: calc(100% - 40px);">
        <input class="text-padding" matInput [(ngModel)]="apiAccessId" placeholder="Please generate an ID" disabled>
        <button matSuffix mat-icon-button aria-label="Regenerate API Access ID" (click)="copy()" class="field-icon-adj">
          <mat-icon>content_copy</mat-icon>
      </button>  
      </mat-form-field>
      <button mat-icon-button aria-label="Regenerate API Access ID" (click)="regenId()"class="field-icon-adj"
    #tooltip="matTooltip" matTooltip="Regenerate your API Access ID" matTooltipPosition="right">
        <mat-icon style="margin-bottom: 5px;">refresh</mat-icon>
    </button> 
    </div>
     
    <mat-card-subtitle class="dev-subtitle">{{keyMessage}}</mat-card-subtitle>
    <button *ngIf="hasKey" mat-flat-button class="sp-background dev-edit-button" color="primary" (click)="getPublicKey()">Download Public Key</button>
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
    <button input type="file"mat-flat-button class="sp-background dev-edit-button" color="primary" (click)="fileUpload.click()">Upload Public Key</button>
    
  </mat-card> 
  <mat-card *ngIf="isDeveloper==null" class="company-form">
    <mat-spinner diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>
  </mat-card>
  <mat-card *ngIf="isDeveloper==false" class="company-form">
    <mat-card-title class="add-company-title sp-title">
      Developer Settings<br>
    </mat-card-title>
    <mat-card-subtitle style="padding-top: 15px;color: #333;">Sorry. You are not a developer.</mat-card-subtitle>
   
  </mat-card> 