import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Company } from 'src/app/company.model';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-company-setup-page',
  templateUrl: './company-setup-page.component.html',
  styleUrls: ['./company-setup-page.component.css']
})
export class CompanySetupPageComponent implements OnInit {

  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private http: HttpClient, 
    private fb: FormBuilder, 
    private titleService: Title
    ) { }

  
  companyID = this.globals.getCompanyId();
  company: Company;

  addCompanyForm = this.fb.group({
    CompanyIdent: [null, Validators.required],
    CompanyName: [null, Validators.required],
    CompanyType: [null, Validators.required],
    CompanyAddress: [null],
    CompanyTelNo: [null],
    CompanyVatNo: [null],
    InitialTier: [null],
    ContactName: [null],
    ContactEmail: [null],
    StatusInd: [null],
    ContactTelNo: [null],
    DataCountryCode: [null],
    AllowMsgAutoDeleteFlag:[null],
    DomainList: new FormArray([]),
    SetUpUserEmail: [null],
    SeatCount: [null]
  });

  async ngOnInit(): Promise<void> {
    this.globals.refreshAuth();
    this.company = { Name: "", Id: "", CompanyId: "", CompanyIdent: "", CompanyName: "", ParentCompanyId: "", IsDealerFlag: "",MsgAutoDeleteTag:"", SPMCompanyType: "",AllowMsgAutoDeleteFlag:"",MaxTimeToDeleteInd:"",TimeToDeleteAfterReadInd:"",DataCountryCode:"", StatusInd:"" }

    let param = new HttpParams();
    param = param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('CompanyId', this.companyID);
    this.http.get<Company>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: data => {
        this.company = data

        try {
        this.company.InitialTier = this.globals.getTier(this.company.InitialTier).name;
          
        } catch (error) {
          this.company.InitialTier = "Unknown"
          
        }
        let deleteTime=this.company.MaxTimeToDeleteInd.split(":")
        let deleteRead=this.company.TimeToDeleteAfterReadInd.split(":")
        this.titleService.setTitle("Company Setup - STAY PRIVATE");
        this.addCompanyForm = this.fb.group({
          CompanyIdent: [{ value: this.company.CompanyIdent }, Validators.required],
          CompanyName: [{ value: this.company.CompanyName }, Validators.required],
          CompanyType: [{ value: 'Customer'}, Validators.required],
          CompanyAddress: [{ value: this.company.CompanyAddress }],
          CompanyTelNo: [{ value: this.company.CompanyTelNo }],
          CompanyVatNo: [{ value: this.company.CompanyVatNo }],
          InitialTier: [{ value: this.company.InitialTier }],
          DataCountryCode: [{ value: this.company.DataCountryCode }],
          StatusInd:[{ value: this.company.StatusInd }],
          ContactName: [{ value: this.company.ContactName }],
          ContactEmail: [{ value: this.company.ContactEmail }],
          ContactTelNo: [{ value: this.company.ContactTelNo }],
          AllowMsgAutoDeleteFlag: [{ value: this.company.AllowMsgAutoDeleteFlag.includes("Y") }],
          MaxTimeToDeleteInd: [{ value: deleteTime[1] }],
          MaxTimeToDeleteIndFormat: [{ value: deleteTime[0] }],
          TimeToDeleteAfterReadInd: [{ value: deleteRead[1] }],
          TimeToDeleteAfterReadIndFormat: [{ value: deleteRead[0] }],
          DomainList: [{ value: this.company.DomainList }],
          SeatCount:[{value: 0}]
        });
      },
      error: error => { console.error('There was an error!'); }
    });
  }

}
