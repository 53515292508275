import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { AddCompanyComponent } from '../add-company/add-company.component';

class Record { name: string; 
  spfBool: boolean;  spfError: string; spfLoading: boolean
  dkimBool: boolean; dkimError: string; dkimLoading: boolean; 
  googleBool: boolean;  googleError: string; googleLoading: boolean};

@Component({
  selector: 'app-company-checker',
  templateUrl: './company-checker.component.html',
  styleUrls: ['./company-checker.component.css']
})
export class CompanyCheckerComponent implements OnInit {
  records: Record[]
  @Input() public domains: FormGroup;
  @Input() public stepper: MatStepper;
  @Input() public id: string;
  @Input() public state: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private globals: Globals,
  ) {

  }
  valid: boolean;
  message: string;
  back() {
    if(this.state=='google'){
    this.stepper.selectedIndex = 9;
      
    }else if(this.state=='roundTrip'){
      this.stepper.selectedIndex = 11;
        
      }else{
    this.stepper.selectedIndex = 7;
  }
  }
  ngOnInit(): void {
    if(this.domains.value.Domain!=null){
      let el = this.domains.value.Domain
      if (el != null && el != "") {
        let temp: Record = { name: el, 
          spfBool: null, spfError: null, spfLoading: true,
          dkimBool: null, dkimError: null, dkimLoading: true,
          googleBool: null, googleError: null, googleLoading: true,
         }
        if (this.records == null) {
          this.records = [temp];
        } else {
          this.records.push(temp)
        }
      }
    } else{
      for (let el of this.domains.value.DomainList) {
        if (el != null && el != "") {
          let temp: Record = { name: el, 
            spfBool: null, spfError: null, spfLoading: true,
            dkimBool: null, dkimError: null, dkimLoading: true,
            googleBool: null, googleError: null, googleLoading: true,
           }
          if (this.records == null) {
            this.records = [temp];
          } else {
            this.records.push(temp)
          }
        }
      }
    }
    
    if (this.records != null) {
      if (this.state=='google') {
        this.googleChecker();
        this.loadChecker();
      } else if (this.state=='spf') {
        this.spfChecker();
        this.dkimChecker();
        this.loadChecker();
      }

    }
    else {
      this.message = "At least one domain is required to use the StayPrivate platform."
      this.valid = false;
    }
  }
  // onNoClick(){this.dialogRef.close();}
  async onSubmit() {
    let form = new FormData;


    form.append('AuthToken', await this.globals.getAuthToken());
    form.append('UserName', this.globals.getSystemUserName());
    form.append('CompanyId', this.id);
    form.append('DomainList', this.domains.value.DomainList.join(';'));
    form.append('ApiSendDomainList', this.domains.value.DomainList.join(';'));


    this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', form).subscribe({
      next: data => {
        this.router.navigate(['/detail/' + this.id]);

      },
      error: error => {
        console.error('There was an error!');
      }
    });
  }
  loadChecker() {
    for (let record of this.records) {
      if (this.state=='google') {
        if (record.googleLoading == false) {
          if (record.googleBool == false) {
            this.message = "Something went wrong with the domains supplied."
            this.valid = false;
          } else {
            if(this.valid == false){

            }else{
            this.valid = true;

            }
          }
        }
      } else if (this.state=='spf') {
        if (record.dkimLoading == false && record.spfLoading == false) {
          if (record.spfBool == false || record.dkimBool == false) {
            this.message = "Something went wrong with the domains supplied."
            this.valid = false;
          } else {
            if(this.valid == false){

            }else{
            this.valid = true;

            }
          }
        }
      }      
    }
  }
  googleChecker() {
    if(this.domains.value.Domain!=null){
      let el = this.domains.value.Domain
      let domain = new FormData;
        domain.append('domain', el);
        this.http.post<any>(this.globals.getServicesUrl() + "dns/googleCheck", domain).subscribe({
          next: data => {
            for (let record of this.records) {
              if (record.name == el) {
                record.googleBool = true;
                record.googleLoading = false;
                this.loadChecker();
              }
            }
          },
          error: error => {
            for (let record of this.records) {
              if (record.name == el) {
                record.googleBool = false;
                record.googleLoading = false;
                record.googleError = error.error.ErrorMsg
  
                this.loadChecker();
              }
            }
          }
        });
    } else{
      for (let el of this.domains.value.DomainList) {
        let domain = new FormData;
        domain.append('domain', el);
        this.http.post<any>(this.globals.getServicesUrl() + "dns/googleCheck", domain).subscribe({
          next: data => {
            for (let record of this.records) {
              if (record.name == el) {
                record.googleBool = true;
                record.googleLoading = false;
                this.loadChecker();
              }
            }
          },
          error: error => {
            for (let record of this.records) {
              if (record.name == el) {
                record.googleBool = false;
                record.googleLoading = false;
                record.googleError = error.error.ErrorMsg
  
                this.loadChecker();
              }
            }
          }
        });
  
      }
    }
    
  }
  spfChecker() {
    for (let el of this.domains.value.DomainList) {
      let domain = new FormData;
      domain.append('domain', el);
      this.http.post<any>(this.globals.getServicesUrl() + "dns/spfCheck", domain).subscribe({
        next: data => {
          for (let record of this.records) {
            if (record.name == el) {
              record.spfBool = true;
              record.spfLoading = false;
              this.loadChecker();
            }
          }
        },
        error: error => {
          for (let record of this.records) {
            if (record.name == el) {
              record.spfBool = false;
              record.spfLoading = false;
              this.loadChecker();
            }
          }
        }
      });

    }
  }
  dkimChecker() {
    for (let el of this.domains.value.DomainList) {
      let domain = new FormData;
      domain.append('domain', el);
      this.http.post<any>(this.globals.getServicesUrl() + "dns/dkimCheck", domain).subscribe({
        next: data => {
          for (let record of this.records) {
            if (record.name == el) {
              record.dkimBool = true;
              record.dkimLoading = false;
              this.loadChecker();
            }
          }
        },
        error: error => {
          for (let record of this.records) {
            if (record.name == el) {
              record.dkimBool = false;
              record.dkimError = error.error.ErrorMsg
              record.dkimLoading = false;
              this.loadChecker();
            }
          }
        }
      });

    }
  }
}
