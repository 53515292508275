import { Component } from '@angular/core';
import { Config } from './config';
import { Globals } from './globals';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private globals: Globals, private config:Config){this.globals.start(this.config.getProdMode());}
  title = 'StayPrivate Dashboard';
  
}
