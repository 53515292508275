<form [formGroup]="companyFlagsForm" autocomplete="off" *ngIf="!loading1">
    <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Email Settings
    </mat-card-subtitle>
    <!-- Email Logo Flag -->
    <div class="field">
        <mat-checkbox color="primary" formControlName="OnlyEmailLogoIfCustomFlag">Email Logo Flag</mat-checkbox>
    </div>
    <!-- Email Footer Flag -->
    <div class="field">
        <mat-checkbox color="primary" formControlName="IdentResellerInEmailFooterFlag">Email Footer Flag</mat-checkbox>
    </div>
    <div class="field">
        <mat-checkbox color="primary" formControlName="SendFromPersEmailIfRecFlag">Send From Personal Email</mat-checkbox>
    </div>
    <form [formGroup]="companyBillingForm" autocomplete="off" *ngIf="!loading1" class="field">
        <div  *ngIf="!loading2">
            <mat-checkbox color="primary" formControlName="canAutoDeleteFlag">Auto Delete Flag</mat-checkbox>
        </div>
    </form>
    <form [formGroup]="companyBillingForm" autocomplete="off" *ngIf="!loading1" class="field">
        <div  *ngIf="!loading2">
            <mat-checkbox color="primary" formControlName="isWebSignup">Is Web Signup</mat-checkbox>
        </div>
    </form>

    <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Company Settings
    </mat-card-subtitle>
    <form [formGroup]="companyBillingForm" autocomplete="off" *ngIf="!loading1 && flagData.SPMCompanyType!='C'">
        <div formArrayName="tierFlags">
            <div class="field" *ngFor="let tierControl of getControls(), let i=index">
                <mat-checkbox color="primary" [checked]="tierControl.value.checked" [formControlName]="i">
                    {{selectedTierArray[i].name}} </mat-checkbox>
                <!-- Information button start -->
                <button mat-icon-button [matMenuTriggerFor]="TierDescription" aria-label="Info button"
                    class="info-icon-container">
                    <mat-icon class="info-icon">info</mat-icon>
                </button>
                <mat-menu #TierDescription="matMenu">
                    <div style="padding: 10px;">
                        <span style="margin-bottom: 10px;">{{selectedTierArray[i].description}}</span>
                    </div>
                </mat-menu>
                <!-- Information button finish -->
            </div>
        </div>

    </form>
    <div class="field">

        <mat-label>Active Tier</mat-label><br>
        <mat-form-field appearance="outline">
            <mat-select [formControl]="companyFlagsForm.controls['InitialTier']">
                <mat-option *ngFor="let tier of activeTierList" [value]="tier.value">
                    {{tier.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="field">

        <mat-label>Data location</mat-label><br>
        <mat-form-field appearance="outline">
            <mat-select [formControl]="companyFlagsForm.controls['DataCountryCode']">
                <mat-option *ngFor="let code of countryCodes" [value]="code.value">
                    {{code.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">API Settings</mat-card-subtitle>
    <!-- API Access Flag -->
    <div class="field">
        <mat-checkbox color="primary" formControlName="CanUseApiFlag">API Access</mat-checkbox>
    </div>
    <!-- Contact Name Field -->
    <div class="field">
        <button mat-flat-button color="primary" class="sp-background add-company-button">Refresh API
            Domains</button>
        <!-- Information button start -->
        <button mat-icon-button [matMenuTriggerFor]="CompanyTierHelp" aria-label="Info button"
            class="info-icon-container">
            <mat-icon class="info-icon">info</mat-icon>
        </button>
        <mat-menu #CompanyTierHelp="matMenu">
            <div style="padding: 10px;" *ngIf="companyFlagsForm.value.ApiSendDomainList.length===0">
                <span>There are no domains registered to send via the API</span>
            </div>
            <div style="padding: 10px;" *ngIf="companyFlagsForm.value.ApiSendDomainList.length!==0">
                <span style="margin-bottom: 10px;">Domains allowed to send via the API:</span><br><br>
                <!-- <ol><li *ngFor="let domain of companyFlagsForm.value.ApiSendDomainList"> {{ domain }}</li></ol> -->
                <span *ngFor="let domain of companyFlagsForm.value.ApiSendDomainList; let i = index"> {{i+1}}) {{ domain
                    }}</span>
            </div>
        </mat-menu>
        <!-- Information button finish -->

    </div>
</form>
<form [formGroup]="companyBillingForm" autocomplete="off" *ngIf="!loading2">
    <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Billing Settings
    </mat-card-subtitle>
    
    <!-- Billing Address Field -->
    <div class="field">
        <mat-label>Billing Address</mat-label>
        <mat-form-field appearance="outline" class="max-width">
            <input class="text-padding" matInput placeholder="example@stayprivate.com" formControlName="billingAddress"
                multiple>
            <mat-error *ngIf="companyBillingForm.controls['billingAddress'].hasError('pattern')">
                Please provide a <strong>valid</strong> email address
            </mat-error>
        </mat-form-field>
    </div>
    <!-- Billing Address Field -->
    <div class="field">
        <mat-label>Discount (%)</mat-label>
        <mat-form-field appearance="outline" class="max-width">
            <input class="text-padding" type="number" min="0" max="100" matInput placeholder="example@stayprivate.com"
                formControlName="discount" multiple>
        </mat-form-field>
    </div>
    <!-- Company Type Selector -->
    <div class="field">
        <mat-label>Billing Currency</mat-label><br>
        <mat-form-field appearance="outline">
            <mat-select [formControl]="companyBillingForm.controls['billingCurrency']">
                <mat-option *ngFor="let currencyType of currencyTypes" [value]="currencyType.value">
                    {{currencyType.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="field">
        <mat-label>Provision Date</mat-label>
        <mat-form-field appearance="outline" class="max-width">
            <input matInput [matDatepicker]="picker" [formControl]="companyBillingForm.controls['firstProvisionDate']"
                disabled>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>
    <div  class="field">
        <mat-checkbox color="primary" formControlName="showStripeFlag">Show Stripe Billing</mat-checkbox>
    </div>
    

</form>

<form *ngIf="!loading1&&!loading2" style="display: flex;flex-direction: column; margin: 30px 0px 20px 0px;">
    <!-- <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Company Settings
    </mat-card-subtitle> -->
    <div style="display: flex;">
        <button *ngIf="flagData.StatusInd=='A'" mat-flat-button class="sp-background advanced-button" color="primary"
            (click)="suspend()">Suspend Company</button>

        <button *ngIf="flagData.StatusInd=='I'" mat-flat-button class="clear-changes advanced-button" color="primary"
            (click)="unsuspend()">Unsuspend Company</button>

        <button *ngIf="flagData.StatusInd!='D'" mat-flat-button class="sp-background advanced-button" color="primary"
            (click)="delete()">Delete Company</button>

        <button *ngIf="flagData.StatusInd=='D'" mat-flat-button class="clear-changes advanced-button" color="primary"
            (click)="restore()">Restore Company</button>
    </div>
</form>
<form *ngIf="companyBillingForm.dirty||companyFlagsForm.dirty" style="display: flex;flex-direction: column; margin: 30px 0px 20px 0px;">
    <!-- <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Company Settings
    </mat-card-subtitle> -->
    <div style="display: flex;">

            <button *ngIf="companyBillingForm.dirty||companyFlagsForm.dirty" mat-flat-button
            class="sp-background advanced-button" color="primary" (click)="update()">Update Settings</button>
        <button *ngIf="companyBillingForm.dirty||companyFlagsForm.dirty" mat-flat-button
            class="clear-changes advanced-button" color="primary" (click)="resetForms()">Clear changes</button>
    </div>
</form>
<mat-spinner *ngIf="loading1&&loading2" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>