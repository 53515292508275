<mat-card class="form-padding">

  <mat-card-content>
    <mat-card-title class="title-padding sp-title">
      Add a new company
    </mat-card-title>
    <!-- 0 -->
    <!-- 0 -->
    <form [formGroup]="addCompanyForm" *ngIf="this.myCompanyType!='Customer'" autocomplete="off">
      <div class="row">
        <!-- Company Type Selector -->
        <div class="full-width" *ngIf="myCompanyType!='Partner'">
          <div class="sp-row">
            <mat-label class="company-detail-tab company-detail-tab-active">Parent company</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select class="text-padding" [formControl]="addCompanyForm.controls['ParentId']"
                placeholder="Select parent company">
                <mat-option *ngFor="let childPartner of childPartners" [value]="childPartner.value">
                  {{childPartner.viewValue}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="addCompanyForm.controls['ParentId'].hasError('required')">
                Parent company is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Company Name Field -->
        <div class="full-width">
          <div class="sp-row">
            <mat-label class="company-detail-tab company-detail-tab-active">Company name</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <input class="text-padding" matInput placeholder="Example Company Ltd" formControlName="CompanyName">
              <mat-error *ngIf="addCompanyForm.controls['CompanyName'].hasError('required')">
                Company name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Company Type Selector -->
        <div class="full-width" *ngIf="companyTypes.length>=2">
          <div class="sp-row">
            <mat-label class="company-detail-tab company-detail-tab-active">Company type</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select class="text-padding" [formControl]="addCompanyForm.controls['CompanyType']"
                placeholder="Customer">
                <mat-option *ngFor="let companyType of companyTypes" [value]="companyType.viewValue">
                  {{companyType.viewValue}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="addCompanyForm.controls['CompanyType'].hasError('required')">
                Company type is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        
        <!-- Company Tier Selector -->
        <div class="full-width" *ngIf="!loadingTiers && addCompanyForm.controls['CompanyType'].value!=null && displayTiers.length>1">
          <div class="sp-row">
            <mat-label class="company-detail-tab company-detail-tab-active">Pricing tier</mat-label>
            <div *ngIf="loadingTiers" style="border: 1px solid #828282; border-radius: 4px; height: 56px;" class="full-width">
              <mat-spinner  diameter="30" style="margin: 13px auto 13px auto;"></mat-spinner>
            </div>
            <div *ngIf="!loadingTiers">
              <mat-form-field appearance="outline" class="full-width">
                <mat-select class="text-padding" [formControl]="addCompanyForm.controls['InitialTier']"
                  placeholder="Standard Pricing">
                  <mat-option *ngFor="let initialTier of displayTiers" [value]="initialTier.id" >
                    {{initialTier.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addCompanyForm.controls['InitialTier'].hasError('required')">
                  Company tier is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <!-- Information button start -->
              <button mat-icon-button [matMenuTriggerFor]="CompanyTierHelp" aria-label="Info button" type="button"
                class="info-icon-container"
                *ngIf="addCompanyForm.controls['CompanyType'].value!='Customer'&&addCompanyForm.controls['CompanyType'].value!=null">
                <mat-icon class="info-icon">info</mat-icon>
              </button>
              <mat-menu #CompanyTierHelp="matMenu">
                <div style="padding: 10px;">Please choose a pricing tier. If the company does not intend to use the
                  StayPrivate platform, then select 'Not applicable'.</div>
              </mat-menu>
              <!-- Information button finish -->
            </div>
            
          </div>
        </div>
        <div class="field" >
          
        </div>
        <div *ngIf="addCompanyForm.controls['InitialTier'].value==2||addCompanyForm.controls['InitialTier'].value==3||addCompanyForm.controls['InitialTier'].value==5" class="full-width">
        <!-- <div class="full-width"> -->
          <div class="sp-row">
            <mat-label class="company-detail-tab company-detail-tab-active">User count</mat-label>
            <div >
              <mat-form-field appearance="outline" class="full-width">
                  <input class="text-padding" matInput type="number"
                  [formControl]="addCompanyForm.controls['SeatCount']"  (change)="add()">
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Per Seat Selector -->
        <div *ngIf="addCompanyForm.controls['InitialTier'].value==113" class="full-width">
          <div class="sp-row">
            <mat-label class="company-detail-tab company-detail-tab-active">Licenses</mat-label>
            <div >
              <mat-form-field appearance="outline" class="full-width">
                <mat-select class="text-padding" [formControl]="addCompanyForm.controls['SeatCount']"
                  placeholder="Up to 3 Users">
                  <mat-option [value]="3">Up to 3 Users</mat-option>
                  <mat-option [value]="4">4 Users</mat-option>
                  <mat-option [value]="5">5 Users</mat-option>
                  <mat-option [value]="6">6 Users</mat-option>
                  <mat-option [value]="7">7 Users</mat-option>
                  <mat-option [value]="8">8 Users</mat-option>
                  <mat-option [value]="9">9 Users</mat-option>
                  <mat-option [value]="10">10 Users</mat-option>
                  <mat-option [value]="11">11 Users</mat-option>
                  <mat-option [value]="12">12 Users</mat-option>
                  <mat-option [value]="13">13 Users</mat-option>
                  <mat-option [value]="14">14 Users</mat-option>
                  <mat-option [value]="15">15 Users</mat-option>
                  <mat-option [value]="16">16 Users</mat-option>
                  <mat-option [value]="17">17 Users</mat-option>
                  <mat-option [value]="18">18 Users</mat-option>
                  <mat-option [value]="19">19 Users</mat-option>
                  <mat-option [value]="20">20 Users</mat-option>
                  <mat-option [value]="21">21 Users</mat-option>
                  <mat-option [value]="22">22 Users</mat-option>
                  <mat-option [value]="23">23 Users</mat-option>
                  <mat-option [value]="24">24 Users</mat-option>
                  <mat-option [value]="25">Unlimited Users</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>
      <button mat-flat-button color="primary" class="add-company-button sp-disabled" *ngIf="adding||!addCompanyForm.valid" 
        (click)="addCompany(this.addCompanyForm)" disabled>Add Company</button>
        <!-- <button mat-flat-button color="primary" class="add-company-button sp-disabled" *ngIf="adding||!addCompanyForm.valid" 
        (click)="print(this.addCompanyForm)" >Add Company dis</button> -->
        <button mat-flat-button color="primary" class="sp-background add-company-button " 
        (click)="addCompany(this.addCompanyForm)" *ngIf="!adding&&addCompanyForm.valid" >Add Company</button>
      <!-- <button mat-flat-button color="primary" class="sp-background add-company-button center" style="float: right;"
            (click)="this.myStepper.next();">Next</button> -->
    </form>
  </mat-card-content>
</mat-card>