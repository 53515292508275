<div class="success-animation">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
</div>
<span class="subtitle">Setup Complete</span>
<span class="paragraph" style="margin-top:10px;">You have completed the StayPrivate setup.</span>
<span class="paragraph" style="margin-bottom:20px;"><span *ngIf="data.domain">All email accounts on {{data.domain}}</span> <span *ngIf="!data.domain">You</span> can now send encrypted emails, simply by including the keyword: <span class="sp-title sp-bold">{{data.keyword}}</span></span> 
<div mat-dialog-actions>
    <!-- <button mat-stroked-button class="cancel-button" (click)="dialogRef.close()">Cancel</button> -->
    <button mat-flat-button class="sp-background ok-button" color="primary"
        (click)="dialogRef.close(true)">Done</button>

</div>