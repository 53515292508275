import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanySetupProcessComponent } from '../company-setup-process/company-setup-process.component';

@Component({
  selector: 'app-setup-complete',
  templateUrl: './setup-complete.component.html',
  styleUrls: ['./setup-complete.component.css']
})
export class SetupCompleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CompanySetupProcessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {keyword: string,domain: string}
  ) { }

  ngOnInit(): void {
  }

}
