import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private http: HttpClient
  ) { }
  @Input() public notificationTab: MatTabGroup;
  loading = true;
  preferenceForm = this.fb.group({
    AddBrandCompanyTypeCFlag: [null],
    AddBrandCompanyTypeRFlag: [null],
    AddBrandCompanyTypeWFlag: [null],
    NewCompanyTypeCFlag: [null],
    NewCompanyTypeRFlag: [null],
    NewCompanyTypeWFlag: [null],
    ProvCompanyTypeCFlag: [null],
    ProvCompanyTypeRFlag: [null],
    ProvCompanyTypeWFlag: [null],
    RmvBrandCompanyTypeCFlag: [null],
    RmvBrandCompanyTypeRFlag: [null],
    RmvBrandCompanyTypeWFlag: [null],
    UsageOverHrchyCompanyTypeCFlag: [null],
    UsageOverHrchyCompanyTypeRFlag: [null],
    UsageOverHrchyCompanyTypeWFlag: [null],
    UsageOverOwnCompanyFlag: [null],
    ProvOwnCompanyFlag: [null],
    AddBrandOwnCompanyFlag : [null],
    RmvBrandOwnCompanyFlag: [null],
  });
  async ngOnInit(): Promise<void> {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    this.loading = true;
    this.http.get<any>(this.globals.getApiUrl() + "api/getSPMailCompanyUserNotifyFlags.php", { params: param }).subscribe({
      next: data => {
        this.preferenceForm.setValue({
          AddBrandCompanyTypeCFlag: data.AddBrandCompanyTypeCFlag.includes("Y"),
          AddBrandCompanyTypeRFlag: data.AddBrandCompanyTypeRFlag.includes("Y"),
          AddBrandCompanyTypeWFlag: data.AddBrandCompanyTypeWFlag.includes("Y"),
          NewCompanyTypeCFlag: data.NewCompanyTypeCFlag.includes("Y"),
          NewCompanyTypeRFlag: data.NewCompanyTypeRFlag.includes("Y"),
          NewCompanyTypeWFlag: data.NewCompanyTypeWFlag.includes("Y"),
          ProvCompanyTypeCFlag: data.ProvCompanyTypeCFlag.includes("Y"),
          ProvCompanyTypeRFlag: data.ProvCompanyTypeRFlag.includes("Y"),
          ProvCompanyTypeWFlag: data.ProvCompanyTypeWFlag.includes("Y"),
          RmvBrandCompanyTypeCFlag: data.RmvBrandCompanyTypeCFlag.includes("Y"),
          RmvBrandCompanyTypeRFlag: data.RmvBrandCompanyTypeRFlag.includes("Y"),
          RmvBrandCompanyTypeWFlag: data.RmvBrandCompanyTypeWFlag.includes("Y"),
          UsageOverHrchyCompanyTypeCFlag: data.UsageOverHrchyCompanyTypeCFlag.includes("Y"),
          UsageOverHrchyCompanyTypeRFlag: data.UsageOverHrchyCompanyTypeRFlag.includes("Y"),
          UsageOverHrchyCompanyTypeWFlag: data.UsageOverHrchyCompanyTypeWFlag.includes("Y"),
          UsageOverOwnCompanyFlag: data.UsageOverOwnCompanyFlag.includes("Y"),
          ProvOwnCompanyFlag: data.ProvOwnCompanyFlag.includes("Y"),
          AddBrandOwnCompanyFlag : data.AddBrandOwnCompanyFlag .includes("Y"),
          RmvBrandOwnCompanyFlag: data.RmvBrandOwnCompanyFlag.includes("Y"),
        })
        this.preferenceForm.get("AddBrandCompanyTypeCFlag").valueChanges.subscribe(x => { this.update('AddBrandCompanyTypeCFlag',x) })
        this.preferenceForm.get("AddBrandCompanyTypeRFlag").valueChanges.subscribe(x => { this.update('AddBrandCompanyTypeRFlag',x) })
        this.preferenceForm.get("AddBrandCompanyTypeWFlag").valueChanges.subscribe(x => { this.update('AddBrandCompanyTypeWFlag',x) })
        this.preferenceForm.get("NewCompanyTypeCFlag").valueChanges.subscribe(x => { this.update('NewCompanyTypeCFlag',x) })
        this.preferenceForm.get("NewCompanyTypeRFlag").valueChanges.subscribe(x => { this.update('NewCompanyTypeRFlag',x) })
        this.preferenceForm.get("NewCompanyTypeWFlag").valueChanges.subscribe(x => { this.update('NewCompanyTypeWFlag',x) })
        this.preferenceForm.get("ProvCompanyTypeCFlag").valueChanges.subscribe(x => { this.update('ProvCompanyTypeCFlag',x) })
        this.preferenceForm.get("ProvCompanyTypeRFlag").valueChanges.subscribe(x => { this.update('ProvCompanyTypeRFlag',x) })
        this.preferenceForm.get("ProvCompanyTypeWFlag").valueChanges.subscribe(x => { this.update('ProvCompanyTypeWFlag',x) })
        this.preferenceForm.get("RmvBrandCompanyTypeCFlag").valueChanges.subscribe(x => { this.update('RmvBrandCompanyTypeCFlag',x) })
        this.preferenceForm.get("RmvBrandCompanyTypeRFlag").valueChanges.subscribe(x => { this.update('RmvBrandCompanyTypeRFlag',x) })
        this.preferenceForm.get("RmvBrandCompanyTypeWFlag").valueChanges.subscribe(x => { this.update('RmvBrandCompanyTypeWFlag',x) })
        this.preferenceForm.get("UsageOverHrchyCompanyTypeCFlag").valueChanges.subscribe(x => { this.update('UsageOverHrchyCompanyTypeCFlag',x) })
        this.preferenceForm.get("UsageOverHrchyCompanyTypeRFlag").valueChanges.subscribe(x => { this.update('UsageOverHrchyCompanyTypeRFlag',x) })
        this.preferenceForm.get("UsageOverHrchyCompanyTypeWFlag").valueChanges.subscribe(x => { this.update('UsageOverHrchyCompanyTypeWFlag',x) })
        this.preferenceForm.get("UsageOverOwnCompanyFlag").valueChanges.subscribe(x => { this.update('UsageOverOwnCompanyFlag',x) })
        this.preferenceForm.get("ProvOwnCompanyFlag").valueChanges.subscribe(x => { this.update('ProvOwnCompanyFlag',x) })
        this.preferenceForm.get("AddBrandOwnCompanyFlag").valueChanges.subscribe(x => { this.update('AddBrandOwnCompanyFlag',x) })
        this.preferenceForm.get("RmvBrandOwnCompanyFlag").valueChanges.subscribe(x => { this.update('RmvBrandOwnCompanyFlag',x) })
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        console.error('There was an error!');
      }
    });
    
  }
  async update(flag,value){
    let form = new FormData;
    form.append('AuthToken', await this.globals.getAuthToken());
    form.append('UserName', this.globals.getSystemUserName());
    if(value){form.append(flag, "Y");}
    if(!value){form.append(flag, "N");}

    this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompanyUserNotifyFlags.php', form).subscribe({
      next: data => {

      },
      error: error => {
        console.error('There was an error!');
      }
    });
  }

  goToNotif() {
    this.notificationTab.selectedIndex = 0
  }
}
