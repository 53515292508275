<mat-card-content *ngIf="noStats==true">
  <p style="margin: 20px 0px 5px 0px;">
    Usage statistics not yet available. <br><br>
    Please check again tomorrow.
  </p>
</mat-card-content>
<mat-card-content class="stats-area" *ngIf="noStats==false&&!setup">
  <div class="row">
    <div class="col-md">
      <div class="stat-display-block sp-background mat-elevation-z2 sp-border" style="color:#fff">
        <div class="stat-display-title">
          Company users:
        </div>
        <div class="stat-display-stat">
          {{userTotal}}
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-display-block sp-background mat-elevation-z2 sp-border" style="color:#fff">
        <div class="stat-display-title">
          Recipients:
        </div>
        <div class="stat-display-stat">
          {{recipientTotal}}
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-display-block sp-background mat-elevation-z2 sp-border" style="color:#fff">
        <div class="stat-display-title">
          Storage:
        </div>
        <div class="stat-display-stat">
          {{storageTotal | number:'1.1-1'}}GB
        </div>
      </div>
    </div>
    <!-- <div class="col-md">
        <div class="stat-display-block sp-background">
          <div class="stat-display-title">
            Monthly retail price:
          </div>
          <div class="stat-display-stat">
            {{monthlyRetailPrice | currency : 'GBP' : true : '1.2-2'}}
          </div>
        </div>
      </div>  -->
  </div>

  <div class="row">
    <div class="col-md">
      <div class="stat-display-block mat-elevation-z2">
        <div class="stat-display-title stat-title-2 stat-bold">
          Emails sent
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in last 24 hours:
          </div>
          <div class="stat-display-substat">
            {{emailSent24h}}
          </div>
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in last 7 days:
          </div>
          <div class="stat-display-substat">
            {{emailSent7d}}
          </div>
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in last 28 days:
          </div>
          <div class="stat-display-substat">
            {{emailSent28d}}
          </div>
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in total:
          </div>
          <div class="stat-display-substat">
            {{emailSentTotal}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-display-block mat-elevation-z2">
        <div class="stat-display-title stat-title-2 stat-bold">
          Emails received
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in last 24 hours:
          </div>
          <div class="stat-display-substat">
            {{emailReceived24h}}
          </div>
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in last 7 days:
          </div>
          <div class="stat-display-substat">
            {{emailReceived7d}}
          </div>
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in last 28 days:
          </div>
          <div class="stat-display-substat">
            {{emailReceived28d}}
          </div>
        </div>
        <div class="stat-display-subsection">
          <div class="stat-display-subtitle">
            in total:
          </div>
          <div class="stat-display-substat">
            {{emailReceivedTotal}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="date-time-text">
        <aside></aside> as at {{dateAccessed}}
      </div>
    </div>
  </div>
  <app-line-graph></app-line-graph>
</mat-card-content>




<mat-card-content class="stats-area" *ngIf="noStats==false&&setup">
  <div class="row" style="padding-top: 15px;">
    <div class="col-md">
      <div class="stat-icon-base">
        <div class="stat-icon sp-background">
          <span class="material-icons-outlined" style="color:#fff; font-size: 36px;">person</span>
        </div>
      </div>
      <div class="stat-display-block-new mat-elevation-z2 sp-border stat-block-icon">
        <div class="stat-display-title-new" style="color:#000000DE">
          Company users <br>
          <div class="stat-display-subtitle-new">Active in last 30 days</div>
        </div>
        <div class="stat-display-stat-new stat-bold">
          {{userTotal}}
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-icon-base">
        <div class="stat-icon sp-background">
          <span class="material-icons-outlined" style="color:#fff; font-size: 36px;">storage</span>
        </div>
      </div>
      <div class="stat-display-block-new mat-elevation-z2 sp-border stat-block-icon">
        <div class="stat-display-title-new" style="color:#000000DE">
          Storage <br>
          <div class="stat-display-subtitle-new">Total storage used</div>
        </div>
        <div class="stat-display-stat-new stat-bold">
          {{storageTotal | number:'1.1-1'}}GB
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-icon-base">
        <div class="stat-icon sp-background">
          <span class="material-icons-outlined" style="color:#fff; font-size: 36px;">groups</span>
        </div>
      </div>
      <div class="stat-display-block-new mat-elevation-z2 sp-border stat-block-icon">

        <div class="stat-display-title-new" style="color:#000000DE">
          Recipients<br>
          <div class="stat-display-subtitle-new">Total recipients contacted</div>
        </div>
        <div class="stat-display-stat-new stat-bold">
          {{recipientTotal}}
        </div>
      </div>
    </div>

    <!-- <div class="col-md">
        <div class="stat-display-block-new sp-background">
          <div class="stat-display-title-new">
            Monthly retail price:
          </div>
          <div class="stat-display-stat-new">
            {{monthlyRetailPrice | currency : 'GBP' : true : '1.2-2'}}
          </div>
        </div>
      </div>  -->
  </div>

  <div class="row">
    <div class="col-md" style="align-self: center;">
      <div class="stat-icon-base">
      </div>
      <div class="row">
        <div class="col-md">
          <div class="stat-display-block-new stat-display-overwrite sp-background mat-elevation-z2 sp-border "
            style="color:#fff">
            <div class="stat-icon-holder">
              <span class="material-symbols-outlined" style="font-size: 36px;">manage_search</span><br>
            </div>
            <div class="solid-stat-block">
              <div class="stat-display-title-new">
                Emails checked<br>
                <div class="stat-display-subtitle-new">in the last 30 days</div>
              </div>
              <div class="stat-display-stat-new stat-bold">
                {{emailsChecked}}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <div class="stat-display-block-new stat-display-overwrite sp-background mat-elevation-z2 sp-border "
            style="color:#fff">
            <div class="stat-icon-holder">
              <span class="material-symbols-outlined" style="font-size: 30px;">search_off</span><br>
            </div>
            <div class="solid-stat-block">
              <div class="stat-display-title-new">
                #IGNORE emails<br>
                <div class="stat-display-subtitle-new">sent in the last 30 days</div>
              </div>
              <div class="stat-display-stat-new stat-bold">
                {{emailsWithIgnore}}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-md" style="flex-grow: 2.07;">
      <!-- <div class="stat-icon-base">
        <mat-icon class="stat-icon sp-color sp-border" style="background-color: #fff;">send</mat-icon>
      </div> -->
      <div class="stat-icon-base">
        <div class="stat-icon sp-border" style="background-color: #fff; border: solid 1px;">
          <span class="material-icons-outlined sp-color" style="font-size: 36px;">send</span>
        </div>
      </div>
      <div
        class="stat-display-block-new stat-display-overwrite sp-background mat-elevation-z2 sp-border stat-block-large"
        style="color:#fff;">
        <div class="stat-display-title-new " style="font-size: 1.65em; font-weight: 300;">
          Secure emails sent
        </div>
        <div class="solid-stat-block">
          <div class="sent-stat-block stat-seperator">
            <div class="sent-stat-number sp-bold">{{emailSent24h}}</div><br>
            in last 24 hours
          </div>
          <div class="sent-stat-block stat-seperator">
            <div class="sent-stat-number sp-bold">{{emailSent7d}}</div><br>
            in last 7 days
          </div>
          <div class="sent-stat-block stat-seperator">
            <div class="sent-stat-number sp-bold">{{emailSent30d}}</div><br>
            in last 30 days
          </div>
          <div class="sent-stat-block">
            <div class="sent-stat-number sp-bold">{{emailSentTotal}}</div><br>
            in total
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <div class="stat-icon-base">
      </div>
      <div class="stat-display-block-new stat-display-overwrite mat-elevation-z2"
        style="align-items: center; color:#000000DE">
        <div class="current-setup sp-background" *ngIf="setup=='RX'">Current</div>
        <div class="stat-display-title-new text-center">
          <div class="stat-display-subtitle-new">statistics if using</div>
          Keyword Only
        </div>
        <div class="solid-stat-block text-center" style="justify-content: space-around; padding: 20px 0px 15px 0px;">
          <div style="font-weight: 400;">
            <div class="sp-color stat-display-stat-new stat-bold">{{keywordUsers}}</div>
            <div class="stat-display-title-new" style="padding-top: 10px;">Users</div>
          </div>
          <div style="font-weight: 400;">
            <div class="sp-color stat-display-stat-new stat-bold">{{keywordEmails}}</div>
            <div class="stat-display-title-new" style="padding-top: 10px;">Emails</div>
          </div>
        </div>
        <div class="stat-display-title-new text-center">
          <div class="stat-display-subtitle-new">in last 30 days</div>
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-icon-base">
      </div>
      <div class="stat-display-block-new stat-display-overwrite mat-elevation-z2"
        style="align-items: center; color:#000000DE">
        <div class="current-setup sp-background" *ngIf="setup=='SM'">Current</div>
        <div class="stat-display-title-new text-center">
          <div class="stat-display-subtitle-new">statistics if using</div>
          Content Based
        </div>
        <div class="solid-stat-block text-center" style="justify-content: space-around; padding: 20px 0px 15px 0px;">
          <div style="font-weight: 400;">
            <div class="sp-color stat-display-stat-new stat-bold">{{smartUsers}}</div>
            <div class="stat-display-title-new" style="padding-top: 10px;">Users</div>
          </div>
          <div style="font-weight: 400;">
            <div class="sp-color stat-display-stat-new stat-bold">{{smartEmails}}</div>
            <div class="stat-display-title-new" style="padding-top: 10px;">Emails</div>
          </div>
        </div>
        <div class="stat-display-title-new text-center">
          <div class="stat-display-subtitle-new">in last 30 days</div>
        </div>
      </div>
    </div>
    <div class="col-md">
      <div class="stat-icon-base">
      </div>
      <div class="stat-display-block-new stat-display-overwrite mat-elevation-z2"
        style="align-items: center; color:#000000DE">
        <div class="current-setup sp-background" *ngIf="setup=='ST'">Current</div>
        <div class="stat-display-title-new text-center">
          <div class="stat-display-subtitle-new">statistics if using</div>
          Enforced
        </div>
        <div class="solid-stat-block text-center" style="justify-content: space-around; padding: 20px 0px 15px 0px;">
          <div style="font-weight: 400;">
            <div class="sp-color stat-display-stat-new stat-bold">{{standardUsers}}</div>
            <div class="stat-display-title-new" style="padding-top: 10px;">Users</div>
          </div>
          <div style="font-weight: 400;">
            <div class="sp-color stat-display-stat-new stat-bold">{{standardEmails}}</div>
            <div class="stat-display-title-new" style="padding-top: 10px;">Emails</div>
          </div>
        </div>
        <div class="stat-display-title-new text-center">
          <div class="stat-display-subtitle-new">in last 30 days</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="date-time-text">
        <aside></aside> as at {{dateAccessed}}
      </div>
    </div>
  </div>
  <app-line-graph2></app-line-graph2>
</mat-card-content>