import { Component, Input, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

export interface tCompany {
  Name: string
  Id: string
  Ident: string

}

/** File node data with possible child nodes. */
export interface FileNode {
  Name: string;
  Id: string;
  DomainList?: string[];
  SPMCompanyType: string;
  InitialTier: string;
  StatusInd:String;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  Name: string;
  //type: string;
  level: number;
  expandable: boolean;
}
@Component({
  selector: 'app-company-hierarchy',
  templateUrl: './company-hierarchy.component.html',
  styleUrls: ['./company-hierarchy.component.css']
})
export class CompanyHierarchyComponent implements OnInit {

  @Input() public parents: any[];
  @Input() public children: any[];
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  companyList: any[]
  fullCompanyList: any[]

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    //this.ngOnInit();
    // 

  }

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      Name: node.Name,
      Id: node.Id,
      DomainList: node.DomainList,
      SPMCompanyType: node.SPMCompanyType,
      StatusInd:node.StatusInd,
      InitialTier: node.InitialTier,
      level: level,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }
  currentId;
  processed = false;
  ngOnInit(): void {
    let tempParents = this.parents;

    if (!tempParents[0].processed) {

      this.currentId = this.parents[this.parents.length - 1].Id;
      tempParents[tempParents.length - 1].children = this.children;

      let i;
      for (i = (tempParents.length - 1); i >= 0; i--) {
        if (i > 0) {
          tempParents[i - 1].children = [tempParents[i]]
          tempParents.splice(i, 1)
        }
        if (i == 0) {
          try {
            tempParents[0].processed = this.currentId;
            this.dataSource.data = tempParents;
            this.treeControl.expandAll();
          } catch (error) {
            console.log(error)
          }

        }
      }
    } else {
      this.currentId = tempParents[0].processed;

      this.dataSource.data = tempParents; 
      this.treeControl.expandAll();
    }
  }

}
