import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private fb: FormBuilder, 
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {route: ActivatedRoute}
  ) {
    data.route.params.subscribe(params => {this.id = params['id']});
    dialogRef.disableClose = true;
  }
  hidePassword = true;
  hidePasswordConfirm = true;
  private id;
  addUserForm = this.fb.group({
    emailAddress: [null,[Validators.required,Validators.pattern("^[a-z0-9._%+-]{2,}@[a-z0-9.-]+\\.[a-z]{2,5}$")]],
    name: [null, Validators.required],
    password: [null,[Validators.required,Validators.pattern("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}")]],
    passwordConfirm: [null,Validators.required],
    
  });
  
  onNoClick(): void {
    this.dialogRef.close();
  }
  get primEmail(){
    return this.addUserForm.get('emailAddress')
    }
  getEmailPrefix(str:string){
    return str.substring(0, str.indexOf("@")); 
  }
  async onSubmit(){
    let form = new FormData;
    
    
    form.append('AuthToken',await this.globals.getAuthToken());
    form.append('UserName',this.globals.getSystemUserName());
    form.append('PersonalName',this.addUserForm.value.name);
    form.append('EmailAddress',this.addUserForm.value.emailAddress);
    form.append('NewUserName',this.getEmailPrefix(this.addUserForm.value.emailAddress));
    if(this.id){form.append('CompanyId',this.id);}
    form.append('UserPwd1',this.addUserForm.value.password);
    form.append('UserPwd2',this.addUserForm.value.passwordConfirm);
    form.append('ResetUrlSubdomain','dashboard');
    
    
    this.http.post<any>(this.globals.getApiUrl()+'api/addSPMailCompanyUser.php', form).subscribe({
      next: data => {
        this.dialogRef.close();
      },
      error: error => {
        let msg="An unknown error has occurred.";
        let durationT=5000;
        if(error.error.ErrorMsg=="The user email address does not belong to a company domain"){
          msg='This email domain does not exist on this company.\nPlease add it on the details page and try again.';
          durationT=10000; 
        };
        if(error.error.ErrorMsg=="Cannot add user due to validation errors."){
          if(error.error.FieldErrorList[0].FieldError=="The second password entry must match the first."){
            msg='Passwords do not match. Please try again.'
          }
          if(error.error.FieldErrorList[0].FieldError=="The email address already exists for the company."){
            msg='This email is already in use. Please try a different one.'
          }
          if(error.error.FieldErrorList[0].FieldError=="A user with this name is already registered for the company. Please enter a different user name."){
            this.retry(form);
          }
        };
        this._snackBar.open(msg,"OK",{duration:durationT} );
      }
    });
  }
  attemptNo=0;
  retry(form:FormData){
    this.attemptNo++
    let tempIdent = this.getEmailPrefix(form.get('EmailAddress').toString())
    tempIdent = tempIdent.toLowerCase();
    tempIdent = tempIdent.replace(/[^a-zA-Z0-9]/g, "")
    tempIdent = tempIdent+this.attemptNo;
    form.append('NewUserName',tempIdent);
    this.http.post<any>(this.globals.getApiUrl()+'api/addSPMailCompanyUser.php', form).subscribe({
      next: data => {
        this.dialogRef.close();
      },
      error: error => {
        if(error.error.ErrorMsg=="The user email address does not belong to a company domain"){
          this._snackBar.open('Email address domain does not match a on added to this company. Please see details page.',"OK",{duration:5000} );
        };
        if(error.error.ErrorMsg=="Cannot add user due to validation errors."){
          if(error.error.FieldErrorList[0].FieldError=="The second password entry must match the first."){
            this._snackBar.open('Passwords do not match. Please try again.',"OK",{duration:5000} );
          }
          if(error.error.FieldErrorList[0].FieldError=="The email address already exists for the company."){
            this._snackBar.open('This email is already in use. Please try a different one.',"OK",{duration:5000} );
          }
          if(error.error.FieldErrorList[0].FieldError=="A user with this name is already registered for the company. Please enter a different user name."){
            this.retry(form);
          }
        };
      }
    });
  }
  ngOnInit(){
  }
}
