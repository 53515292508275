<mat-form-field appearance="fill" class="chart-top">
    <mat-label>Chart display</mat-label>
    <mat-select [(value)]="selectedChart" (selectionChange)="onSwitch()">
        <mat-option *ngFor="let chart of charts" [value]="chart.value">
            {{chart.viewValue}}
        </mat-option>
    </mat-select>
</mat-form-field>

<div #containerRef class="chart-container mat-elevation-z2">
    <mat-spinner *ngIf="multi==null" diameter="70" style="margin: 95px auto "></mat-spinner>
    <div *ngIf="sentView" class="graph-button-box">
        <button class="graph-button" mat-stroked-button (click)="onSwitch('24h')" [disabled]="sentViewValue=='24h'"
            [ngClass]="(sentViewValue=='24h')? 'graph-button-active sp-background':''">
            <span class="graph-button-text">24h</span> </button>
        <button class="graph-button" mat-stroked-button (click)="onSwitch('7d')" [disabled]="sentViewValue=='7d'"
            [ngClass]="(sentViewValue=='7d')? 'graph-button-active sp-background':''">
            <span class="graph-button-text">7d</span> </button>
        <button class="graph-button" mat-stroked-button (click)="onSwitch('30d')" [disabled]="sentViewValue=='30d'"
            [ngClass]="(sentViewValue=='30d')? 'graph-button-active sp-background':''">
            <span class="graph-button-text">30d</span> </button>
    </div>
    <div *ngIf="!sentView" style="height: 30px;"></div>

    <ngx-charts-line-chart *ngIf="multi!=null" [view]="[containerRef.offsetWidth - 40, containerRef.offsetHeight -60]"
        [scheme]="colorScheme" [legend]="false" [roundDomains]="true" [legendTitle]="" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [results]="multi" [yScaleMax]="scaleMax" [xAxisTickFormatting]='axisFormat'>
    </ngx-charts-line-chart>

</div>