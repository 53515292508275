import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { files } from './example-data';
import { Globals } from 'src/app/globals';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Company } from 'src/app/company.model';
import { Subject } from 'rxjs';

export interface tCompany {
  Name: string
  Id: string
  Ident: string

}

/** File node data with possible child nodes. */
export interface FileNode {
  Name: string;
  Id: string;
  DomainList?: string[];
  SPMCompanyType: string;
  InitialTier: string;
  StatusInd: string;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  Name: string;
  //type: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  companyList: any[]
  fullCompanyList: any[]

  constructor(private globals: Globals, private http: HttpClient) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    //this.ngOnInit();
    // 

  }

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      Name: node.Name,
      Id: node.Id,
      DomainList: node.DomainList,
      SPMCompanyType: node.SPMCompanyType,
      InitialTier: node.InitialTier,
      StatusInd: node.StatusInd,
      level: level,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }
  clients = 0;
  partners = 0;
  tClients = 0;
  tPartners = 0;
  pClients = 0;
  pPartners = 0;
  onlyActiveView;
  allView;
  loading = true;
  async ngOnInit(): Promise<void> {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('DataIncludeInd', 'F');
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyHierarchy.php', { params: param }).subscribe({
      next: data => {

        var filteredCompan = Array.from([...data.CompanyList]);
        this.onlyActiveView = this.buildTree(Array.from([...filteredCompan]).filter(function (value, index, arr) { return value.StatusInd != 'D' }), true)
        this.dataSource.data = Array.from(this.onlyActiveView);

        this.loading = false;
      },
      error: error => { console.error('There was an error!'); }
    })
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyHierarchy.php', { params: param }).subscribe({
      next: data => {

        var filteredCompan = Array.from([...data.CompanyList]);
        this.allView = this.buildTree(Array.from([...filteredCompan]), false)

        this.loading = false;
      },
      error: error => { console.error('There was an error!'); }
    })

  }
  viewDeleted = false;
  changeView() {
    this.loading = true;
    if (this.viewDeleted == false) {
      this.dataSource.data = this.allView;
      this.viewDeleted = true;
      this.loading = false;

    } else {

      this.dataSource.data = this.onlyActiveView;
      this.viewDeleted = false;
      this.loading = false;

    }
  }

  buildTree(companyLists, count: boolean) {
    var filteredCompanies = companyLists;
    var i, ii
    for (i = (filteredCompanies.length - 1); i >= 0; i--) {
      if (count) {
        if (filteredCompanies[i].SPMCompanyType == 'C') {
          this.tClients++
        } else {
          this.tPartners++
        }
      }

      if (filteredCompanies[i].ParentCompanyId != this.globals.getCompanyId()) {
        if (count) {
          if (filteredCompanies[i].SPMCompanyType == 'C') {
            this.pClients++
          } else {
            this.pPartners++
          }
        }
        for (ii = (filteredCompanies.length - 1); ii >= 0; ii--) {
          if (filteredCompanies[ii].Id == filteredCompanies[i].ParentCompanyId) {

            if (filteredCompanies[ii].children != null) {
              filteredCompanies[ii].children.push(filteredCompanies[i]);
              filteredCompanies[ii].children.sort(function (x, y) {
                let a = x.Name.toUpperCase(),
                  b = y.Name.toUpperCase();
                return a == b ? 0 : a > b ? 1 : -1;
              });

              filteredCompanies.splice(i, 1)
              break;
            }
            if (filteredCompanies[ii].children == null) {
              filteredCompanies[ii].children = [filteredCompanies[i]];
              filteredCompanies.splice(i, 1)
              break;
            }
          }
        }
      } else {
        if (count) {
          if (filteredCompanies[i].SPMCompanyType == 'C') {
            this.clients++
          } else {
            this.partners++
          }
        }
      }
    }

    filteredCompanies.sort(function (x, y) {
      let a = x.Name.toUpperCase(),
        b = y.Name.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    });
    return filteredCompanies;
  }
  async delete(id) {


    let form = new FormData;
    form.append('AuthToken', await this.globals.getAuthToken());
    form.append('UserName', this.globals.getSystemUserName());
    form.append('CompanyId', id);

    this.http.post<any>(this.globals.getApiUrl() + 'api/deleteSPMailCompany.php', form).subscribe({
      next: data => {
        // location.reload();

      },
      error: error => {
        console.error('There was an error!', error);
      }
    });

  }

}

