<div class="full-screen">
    <mat-card class="center">
        <mat-card-title class="title-padding sp-title">
            StayPrivate Configuration Instructions <span *ngIf="companyName">for {{companyName}}</span> 
          </mat-card-title>
    <app-setup-microsoft *ngIf="this.route.snapshot.routeConfig.path=='microsoftsetup'"[keyword]="keyword" [isSignUp]="standalon" [domain]="domain" [companyName]="companyName" (setupFinished)="finish($event)"></app-setup-microsoft>
    <app-setup-google *ngIf="this.route.snapshot.routeConfig.path=='googlesetup'"[keyword]="keyword" [isSignUp]="standalon" [domain]="domain" [companyName]="companyName" (setupFinished)="finish($event)"></app-setup-google>
    <app-setup-roundtrip *ngIf="this.route.snapshot.routeConfig.path=='standardsetup'"[keyword]="keyword" [isSignUp]="standalon" [domain]="domain" [companyName]="companyName" (setupFinished)="finish($event)"></app-setup-roundtrip>
    <app-setup-spf *ngIf="this.route.snapshot.routeConfig.path=='directsetup'"[keyword]="keyword" [isSignUp]="standalon" [domain]="domain" [companyName]="companyName" (setupFinished)="finish($event)"></app-setup-spf>

    </mat-card>
</div>