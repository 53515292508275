import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class SetupSteps {
    getMicrosoftSteps(keyword: string) {
        return {
            access: [
                "From your Office 365 home page, in the left-hand menu, select 'Admin'.",
                "In the left-hand menu, select 'Show all'.",
                "Scroll down and under 'Admin centers' select 'Exchange'.",
            ],
            outbound: [
                "In the left-hand menu, select 'Mail flow'. Then select ‘Connectors’.",
                "Click on '+ Add a connector'.",
                "Under 'Connection from' select 'Office 365'. Under 'Connection to' select 'Partner organization'. Then click 'Next'.",
                "Enter the name: 'Send to StayPrivate'. Ensure 'Turn it on' is selected. Then click 'Next'.",
                "Select 'Only when I have a transport rule set up that redirects messages to this connector'. Then click 'Next'.",
                "Select 'Route email through these smart hosts'.",
                "Enter the domain name ‘sendsecure.stayprivate.com'. Then click ‘+’. Then Click 'Next'.",
                "Ensure that 'Always use Transport Layer Security...' and 'Issued by a trusted certificate authority (CA)' are selected. Click 'Next'.",
                "To validate the connector, enter the test email address 'support@stayprivate.com' then click '+'.",
                "Click 'Validate'. Office 365 will validate the new connector. This may take a minute or so. When it has finished, click 'Next'.",
                "Click ‘Create connector’.",
                "Click ‘Done’.",
            ],
            inbound: [
                "In the left-hand menu, select 'Mail flow'. Then select ‘Connectors’.",
                "Click on '+ Add a connector'.",
                "Under 'From:' select ‘Your organization’s email server'. Then click 'Next'.",
                "Enter the name: 'Receive from StayPrivate'. Ensure 'Turn it on' is selected. Then click 'Next'.",
                "Select ‘By verifying that the subject name on the certificate….’. Then enter the text ‘*.stayprivate.com’. Click ‘Next’.",
                "Click ‘Create connector’.",
                "Click ‘Done’.",
            ],
            rules: [
                "In 'Mail flow', select 'Rules'.",
                "Select '+ Add a rule' and 'Create a new rule'.",
                "Enter the name 'Identify messages to send via StayPrivate'.",
                "Under 'Apply this rule if' select 'The subject or body'.",
                "Then, to the right, select 'subject or body matches these text patterns' and enter '" + keyword + "'. Then click 'Add'.",
                "Tick the box next to " + keyword + ". Then click 'Save'.",
                "Now click on the blue '+' just to the right of the box with the text 'subject or body matches these...'.",
                "Under 'And' select 'The sender'. Then, to the right, select 'domain is'. Enter your email domain (for example, mycompany.com) in the box.",
                "Click 'Add'.",
                "Tick the box next to your domain. Then click 'Save'.",
                "Under 'Do the following' select 'Redirect the message to'. Then, to the right, select 'the following connector'. Select 'Send to StayPrivate' and click 'Save'.",
                "Choose the connector 'Send to StayPrivate'. Click 'OK'.",
                "Under 'Except if' select 'The sender'. Then, to the right, select 'IP address is in any of these ranges or exactly matches'. Enter 18.130.40.2 in the box.",
                "Click 'Add'.",
                "Tick the box next to 18.130.40.2. Click 'Save'.",
                "Click 'Next'.",
                "Ensure that 'Enforce' is selected, then click 'Next'.",
                "Click 'Finish'. It may take a few seconds for the new rule to save.",
                "Click 'Done'.",
            ],
            replies: [
                "In the left-hand menu, select 'Recipients' then select 'Contacts'.",
                "Select 'Add a mail contact'.",
                "Under Contact type select ‘Mail contact’.",
                "Under ‘Display name’ enter: 'StayPrivateServer'",
                "Under 'Alias' enter: 'StayPrivateServer'",
                "Under 'External email address' enter: 'server@stayprivatemail.com'",
                "Click 'Next'.",
                "Click 'Next' again, then click 'Create'.",
                "Click 'Done'.",
                "Now, back in the left-hand menu, select 'Mail flow' and select 'Rules'.",
                "Select '+ Add a rule' and 'Create a new rule'.",
                "Enter the name 'Send secure replies to StayPrivate'.",
                "Under 'Apply this rule if' select 'The subject or body'.",
                "Then select 'subject or body matches these text patterns' and enter the text '" + keyword + "'. Then click 'Add'.",
                "Tick the box next to " + keyword + ". Then click 'Save'.",
                "Under 'Do the following' select 'Add Recipients' then, to the right, select 'to the Bcc box'.",
                "Find 'StayPrivateServer' in the list and tick the box next to it. Click 'Save'.",
                "Under 'Except if' select 'The sender' then, to the right, select 'domain is'. Enter your email domain (for example, mycompany.com) in the box.",
                "Tick the box next to your domain. Then click 'Save'.",
                "Click the blue '+' next to the box with the text 'domain is'.",
                "Under 'And' select 'The sender'. Then, to the right, select 'IP address is in any of these ranges or exactly matches'.",
                "Enter 18.130.40.2 in the box. Then click 'Add'.",
                "Click 'Next'.",
                "Check that 'Enforce' is selected, then click 'Next'.",
                "Click 'Finish'. It may take a few seconds for the rule to be saved.",
                "Click 'Done'.",
            ],
            enable: [
                "In the left-hand menu, select 'Rules'.",
                "Find the rule 'Identify messages to send via StayPrivate'. Click on the word 'Disabled' next to the rule.",
                "Click on the switch under 'Enable or disable rule' to enable the rule.",
                "Click on the close button top right. It will take a few seconds for the page to update.",
                "Find the rule 'StayPrivate Allow'. Click on the word 'Disabled' next to the rule.",
                "Click on the switch under 'Enable or disable rule' to enable the rule.",
                "Click on the close button top right. It will take a few seconds for the page to update.",
                "Find the rule 'Send secure replies to StayPrivate'. Click on the word 'Disabled' next to the rule.",
                "Click on the switch under 'Enable or disable rule' to enable the rule.",
                "Click on the close button top right. It will take a few seconds for the page to update.",
            ]
        }
    }
    
}