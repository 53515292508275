import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { CompanyCheckerComponent } from '../company-checker/company-checker.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { io } from 'socket.io-client';
import { Title } from '@angular/platform-browser';
interface SelectorType {
  value: string;
  viewValue: string;
  flag?: string;
  code?: string;
}


@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})


export class AddCompanyComponent implements OnInit {
  @ViewChild('stepper') public myStepper: MatStepper;

  constructor(
    private fb: FormBuilder,
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private clipboard: Clipboard,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private titleService: Title
  ) { }

  attemptNo = 1;
  myCompanyType = this.globals.getCompanyType();
  setUpType = ""
  adding = false;
  private createdId;
  childPartners: SelectorType[] = [
  ];
  companyTypes: SelectorType[] = [
    //{value: 'S', viewValue: 'Super', flag: 'N'},
    // { value: 'W', viewValue: 'Wholesaler', flag: 'Y' },
    //{value: 'W', viewValue: 'Dealer', flag: 'Y'},
    // { value: 'R', viewValue: 'Partner', flag: 'Y' },
    // { value: 'C', viewValue: 'Customer', flag: 'N' },
  ];
  initialTiers = this.globals.getTiers();
  displayTiers = [];
  loadingTiers = false;
  addCompanyForm = this.fb.group({
    CompanyIdent: [null],
    ParentId: [null, Validators.required],
    CompanyName: [null, Validators.required],
    CompanyType: [null, Validators.required],
    InitialTier: [null, Validators.required],
    SeatCount: [null],
    DataCountryCode: [null, Validators.required],
  });




  async ngOnInit() {

    this.titleService.setTitle("Add Company - STAY PRIVATE");
    this.globals.refreshAuth();
    let param = new HttpParams();
    let authT = await this.globals.getAuthToken()
    this.myCompanyType = this.globals.getCompanyType();
    param = param.append('AuthToken', authT);
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('DataIncludeInd', 'F');
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyHierarchy.php', { params: param }).subscribe({
      next: data => {
        let i = 0;
        if (data.CompanyList[0] != null && (this.myCompanyType == "Wholesaler" || this.myCompanyType == "Super")) {
          for (let company of data.CompanyList) {
            i++
            if (company.SPMCompanyType != "C" && company.StatusInd == "A") {
              if (company.SPMCompanyType == "R") {
                this.childPartners.push({ value: company.Id, viewValue: company.Name, flag: "Y", code: company.DataCountryCode })
              } else {
                this.childPartners.push({ value: company.Id, viewValue: company.Name, code: company.DataCountryCode })

              }
              if (i == data.CompanyList.length) {
                this.childPartners.sort(function (x, y) {
                  let a = x.viewValue.toUpperCase(),
                    b = y.viewValue.toUpperCase();
                  return a == b ? 0 : a > b ? 1 : -1;
                });
                let param = new HttpParams();
                param = param = param.append('AuthToken', authT);
                param = param.append('UserName', this.globals.getSystemUserName());
                this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
                  next: data => {
                    this.childPartners.unshift({ value: this.globals.getCompanyId(), viewValue: this.globals.getCompanyName(), code: data.DataCountryCode })
                    if (this.childPartners.length == 1) {
                      this.childPartners.unshift({ value: this.globals.getCompanyId(), viewValue: this.globals.getCompanyName(), code: data.DataCountryCode })
                      this.addCompanyForm.controls['ParentId'].setValue(this.globals.getCompanyId());
                      this.addCompanyForm.get('ParentId').disable();
                    }
                  },
                  error: error => { console.error('There was an error!'); }
                });

              }

            } else {
              if (i == data.CompanyList.length) {
                this.childPartners.sort(function (x, y) {
                  let a = x.viewValue.toUpperCase(),
                    b = y.viewValue.toUpperCase();
                  return a == b ? 0 : a > b ? 1 : -1;
                });
                let param = new HttpParams();
                param = param = param.append('AuthToken', authT);
                param = param.append('UserName', this.globals.getSystemUserName());
                this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
                  next: data => {
                    this.childPartners.unshift({ value: this.globals.getCompanyId(), viewValue: this.globals.getCompanyName(), code: data.DataCountryCode })
                    if (this.childPartners.length == 1) {
                      this.childPartners.unshift({ value: this.globals.getCompanyId(), viewValue: this.globals.getCompanyName(), code: data.DataCountryCode })
                      this.addCompanyForm.controls['ParentId'].setValue(this.globals.getCompanyId());
                      this.addCompanyForm.get('ParentId').disable();

                    }
                  },
                  error: error => { console.error('There was an error!'); }
                });
              }
            }
          }
        } else {
          let param = new HttpParams();
          param = param = param.append('AuthToken', authT);
          param = param.append('UserName', this.globals.getSystemUserName());
          this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
            next: data => {
              this.childPartners.unshift({ value: this.globals.getCompanyId(), viewValue: this.globals.getCompanyName(), code: data.DataCountryCode })
              this.addCompanyForm.controls['ParentId'].setValue(this.globals.getCompanyId());
              this.addCompanyForm.get('ParentId').disable();

            },
            error: error => { console.error('There was an error!'); }
          });

        }
      },
      error: error => { console.error('There was an error!'); }
    })
    this.addCompanyForm.get('InitialTier').valueChanges.subscribe(value => {
      if (value == 113 || value == 2 || value == 3) {
        this.addCompanyForm.get('SeatCount').setValidators(Validators.required)
        if (value == '2' && this.addCompanyForm.controls['SeatCount'].value <= 5) {
          this.addCompanyForm.patchValue({
            SeatCount: 5
          })
        }
        if (value == '3' && this.addCompanyForm.controls['SeatCount'].value <= 10) {
          this.addCompanyForm.patchValue({
            SeatCount: 10
          })
        }
      } else {
        this.addCompanyForm.get('SeatCount').clearValidators();
        this.addCompanyForm.get('SeatCount').reset()
        this.addCompanyForm.updateValueAndValidity();
      }
    }
    );
    this.addCompanyForm.get('ParentId').valueChanges.subscribe(value => {
      this.displayTiers = [];
      this.initialTiers = [];
      this.loadingTiers = true;

      this.addCompanyForm.get('InitialTier').setValue(null);
      let paramGet = new HttpParams();
      paramGet = paramGet = paramGet.append('AuthToken', authT);
      paramGet = paramGet.append('UserName', this.globals.getSystemUserName());
      paramGet = paramGet.append('companyId', value);
      this.http.get<any>(this.globals.getServicesUrl() + 'company/getUsableTiers', { params: paramGet }).subscribe({
        next: data => {

          this.initialTiers = data.avaliableTiers.sort((a, b) => {
            return a.id - b.id;
          });
          this.displayTiers = this.initialTiers;
          this.loadingTiers = false;
          console.log(this.displayTiers)
          if (this.addCompanyForm.get('CompanyType').value == 'Customer') {
            //hide tiers based on type
            this.displayTiers = this.initialTiers.filter(function (value, index, arr) { return value.id != 0 })
            if (this.displayTiers.length == 1) { this.addCompanyForm.get('InitialTier').setValue(this.displayTiers[0].id); }
          }
          else {
            this.displayTiers = this.initialTiers
            if (this.displayTiers.length == 1) { this.addCompanyForm.get('InitialTier').setValue(this.displayTiers[0].id); }

          }

        },
        error: error => { console.error('There was an error!'); }
      });
      let parent = this.childPartners.find(partner => {
        return partner.value == value
      })
      this.addCompanyForm.controls['DataCountryCode'].setValue(parent.code);

      if (parent.flag == 'Y' || this.myCompanyType == "Partner") {
        this.companyTypes = [
          { value: 'C', viewValue: 'Customer', flag: 'N' },
        ];
        this.addCompanyForm.controls['CompanyType'].setValue("Customer");

      } else {

        this.companyTypes = [
          //{value: 'S', viewValue: 'Super', flag: 'N'},
          { value: 'W', viewValue: 'Wholesaler', flag: 'Y' },
          //{value: 'W', viewValue: 'Dealer', flag: 'Y'},
          { value: 'R', viewValue: 'Partner', flag: 'Y' },
          { value: 'C', viewValue: 'Customer', flag: 'N' },
        ];

      }
    });

    //UPDATE TEIRS BASED ON COMPANY TYPE
    this.addCompanyForm.get('CompanyType').valueChanges.subscribe(value => {
      if (value == 'Customer') {
        //hide tiers based on type
        // this.initialTiers = this.globals.getTiers();

        this.displayTiers = this.initialTiers.filter(function (value, index, arr) { return value.id != 0 })
        if (this.displayTiers.length == 1) { this.addCompanyForm.get('InitialTier').setValue(this.displayTiers[0].id); }


        if (this.addCompanyForm.get('InitialTier').value == 0) { this.addCompanyForm.get('InitialTier').setValue(null); }
      }
      else {
        this.displayTiers = this.initialTiers
        if (this.displayTiers.length == 1) { this.addCompanyForm.get('InitialTier').setValue(this.displayTiers[0].id); }

      }
    });
    //AUTO SET TO CLIENT IF A PARTNER IS ADDING
    if (this.myCompanyType == "Partner") {

      this.addCompanyForm.controls['CompanyType'].setValue("Customer");
    }
  }
  print(val) {
    console.log(val)
  }
  add() {
    console.log('add', this.addCompanyForm.controls['InitialTier'].value, this.addCompanyForm.controls['SeatCount'].value)
    if (this.addCompanyForm.controls['InitialTier'].value == '2' && this.addCompanyForm.controls['SeatCount'].value <= 5) {
      this.addCompanyForm.patchValue({
        SeatCount: 5
      })
    }
    if (this.addCompanyForm.controls['InitialTier'].value == '3' && this.addCompanyForm.controls['SeatCount'].value <= 10) {
      this.addCompanyForm.patchValue({
        SeatCount: 10
      })
    }
  }
  async addCompany(formSub: FormGroup) {
    this.adding = true;
    if (formSub.valid) {
      let formData = formSub.value
      formData.ParentId = formSub.get('ParentId').value
      let form = new FormData;
      let tempIdent = formData.CompanyName
      tempIdent = tempIdent.toLowerCase();
      tempIdent = tempIdent.replace(/[^a-zA-Z0-9]/g, "")
      tempIdent = tempIdent.substring(0, 11) + Math.floor(1000000 + Math.random() * 9000000).toString();
      form.append('AuthToken', await this.globals.getAuthToken());
      form.append('UserName', this.globals.getSystemUserName());
      form.append('CompanyIdent', tempIdent);
      form.append('CompanyName', formData.CompanyName);
      form.append('ParentCompanyId', formData.ParentId);
      form.append('InitialTier', formData.InitialTier);
      if (formData.SeatCount != null) { form.append('SeatCount', formData.SeatCount); }
      form.append('DataCountryCode', formData.DataCountryCode);
      if (!this.globals.isSpUser() && formData.CompanyType === 'Customer') {
        form.append('IdentResellerInEmailFooterFlag', "Y");
      } else { form.append('IdentResellerInEmailFooterFlag', "N"); }
      if (formData.InitialTier === 'Unlimited') {
        form.append('CanUseApiFlag', 'Y');
      }
      if (this.myCompanyType == "Partner") {
        form.append('SPMCompanyType', 'C');
        form.append('IsDealerFlag', 'N');
      } else {
        if (formData.CompanyType === 'Super') {
          form.append('SPMCompanyType', 'S');
          form.append('IsDealerFlag', 'N');
        } else if (formData.CompanyType === 'Wholesaler') {
          form.append('SPMCompanyType', 'W');
          form.append('IsDealerFlag', 'Y');
        } else if (formData.CompanyType === 'Partner') {
          form.append('SPMCompanyType', 'R');
          form.append('IsDealerFlag', 'Y');
        } else if (formData.CompanyType === 'Customer') {
          form.append('SPMCompanyType', 'C');
          form.append('IsDealerFlag', 'N');
        }
      }
      this._snackBar.open("Adding Company...", "OK", { duration: 5000 });
      this.http.post<any>(this.globals.getServicesUrl() + 'company/addCompanyNew', form).subscribe({
        next: data => {
          this._snackBar.open("Company added!", "OK", { duration: 5000 });
          this.router.navigate(["/detail", data.CompanyId], { fragment: 'setup' })
        },
        error: error => {
          this.adding = false;
          this._snackBar.open("Error adding company!", "OK", { duration: 5000 });

          // this.retryAddingCompany(form);
        }
      });
    } else { this.adding = false; }
  }
  retryAddingCompany(form: FormData) {
    let tempIdent = form.get('CompanyName').toString();
    tempIdent = tempIdent.toLowerCase();
    tempIdent = tempIdent.replace(/[^a-zA-Z0-9]/g, "")
    tempIdent = tempIdent + this.attemptNo;
    form.append('CompanyIdent', tempIdent);
    this.http.post<any>(this.globals.getApiUrl() + 'api/addSPMailCompany.php', form).subscribe({
      next: data => {
        this._snackBar.open("Company added!", "OK", { duration: 5000 });
        let formB = new FormData;
        formB.append('UserName', this.globals.getSystemUserName());
        formB.append('companyName', form.get('CompanyName'));
        formB.append('companyIdent', tempIdent);
        formB.append('companyId', data.CompanyId);
        this.createdId = data.CompanyId;

        this.http.post<any>(this.globals.getServicesUrl() + 'company/addCompany', formB).subscribe({
          next: data => {
          },
          error: error => {
            console.error(error)
          }
        });
        this.router.navigate(["/detail", data.CompanyId], { fragment: 'setup' })


      },
      error: error => {
        if (this.attemptNo < 8) {
          this.attemptNo++;
          this.retryAddingCompany(form);
        } else {
          this.attemptNo = 0;
          this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
        }
      }
    });
  }


}
