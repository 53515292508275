<mat-card class="form-padding">
    <mat-card-title class="sp-title title-padding">
        Contact Us        
    </mat-card-title>
    <p style="margin-bottom: 30px; padding-left: 10px;">
        <mat-card-subtitle style="margin: 20px 0px 10px 0px;"class="sp-title">Support Requests</mat-card-subtitle>
        For any support requests please email us at <a href="mailto:support@stayprivate.com">support@stayprivate.com</a> or call us on +44 (0)20 7101 5000.<br><br>
        <mat-card-subtitle style="margin: 20px 0px 10px 0px;" class="sp-title" *ngIf="spChild">Sales Queries</mat-card-subtitle>
        <span *ngIf="spChild">For any sales queries please email us at 
            <a *ngIf="myCompanyType!='Customer'" href="mailto:partners@stayprivate.com">partners@stayprivate.com</a>
            <a *ngIf="myCompanyType=='Customer'" href="mailto:sales@stayprivate.com">sales@stayprivate.com</a>
             or call us on +44 (0)20 7101 5000.</span>
    </p>
</mat-card>

