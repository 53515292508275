<mat-form-field appearance="fill" class="chart-top">
  <mat-label>Chart display</mat-label>
  <mat-select [(value)]="selectedChart" (selectionChange)="onSwitch()">
    <mat-option *ngFor="let chart of charts" [value]="chart.value">
      {{chart.viewValue}}
    </mat-option>
  </mat-select>
</mat-form-field>

<div #containerRef class="chart-container mat-elevation-z2">
  <mat-spinner *ngIf="multi==null" diameter="70" style="margin: 95px auto "></mat-spinner>
 
  <ngx-charts-line-chart *ngIf="multi!=null"
  [view]="[containerRef.offsetWidth - 40, containerRef.offsetHeight -20]"
  [scheme]="colorScheme"
  [legend]="false"
  [roundDomains]="true"
  [legendTitle] = ""
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [results]="multi"
  [yScaleMax]="scaleMax"
  >
</ngx-charts-line-chart>

</div>