import { formatCurrency } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-csv-update-dialog',
  templateUrl: './csv-update-dialog.component.html',
  styleUrls: ['./csv-update-dialog.component.css']
})
export class CsvUpdateDialogComponent implements OnInit {

  constructor(    
    private http:HttpClient,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CsvUpdateDialogComponent>,
    private _snackBar: MatSnackBar,
    private globals:Globals
  ) { }

  isLoading=false;
  lastAccessed:string;
  
  ngOnInit(): void {
    this.isLoading=true;
    let param = new HttpParams();
    this.http.get<any>(this.globals.getServicesUrl()+"billing/getLastAcc",{params:param}).subscribe({
      next: data => {
        this.isLoading=false;
         this.lastAccessed=data.lastAccessed.lastAccessedString
      },
      error: error => {
        this.isLoading=false;
        this._snackBar.open(error.error.ErrorMsg,"OK",{duration:5000} );       
      }
    });
  }

  async updateDB(){
    this.isLoading=true;
    let domain = new FormData;
    domain.append('UserName',this.globals.getSystemUserName());
    domain.append('AuthToken',await this.globals.getAuthToken());
    this.http.post<any>(this.globals.getServicesUrl()+"billing/generateBilling",domain).subscribe({
      next: data => {
        this.isLoading=false;        
        this._snackBar.open(data.data,"OK",{duration:5000} );  
        this.dialogRef.close();
      },
      error: error => {
        this.isLoading=false;
        this._snackBar.open(error.error.ErrorMsg,"OK",{duration:5000} );       
      }
    });
  }
}
