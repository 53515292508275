<form [formGroup]="themeForm" autocomplete="off">
  <mat-card >
    <mat-card-title class="title-padding sp-title">Your theme</mat-card-title>
    <mat-card-content>
      <div class="row">
        <div class="col-md shorter-col v-short-col">              
          <div class="row">
            <div class="col color-text">
              <p style="margin: 0px;display: contents;">Button background:</p><br>
              <mat-form-field class="half-width">
                <mat-label>Hex</mat-label>
                <input matInput [value]="themeForm.value.background" maxlength="7" formControlName="background"style>                    
            </mat-form-field>                 
            </div>
            <div class="col"><input type="color" [value]="themeForm.value.background"  formControlName="background" class='bar' id='colour' style="height: 50px;width: 50px;" ></div>   
          </div>
          <div class="row">
            <div class="col color-text">
              <p style="margin: 0px;display: contents;">Title:</p><br>
              <mat-form-field class="half-width">
                <mat-label>Hex</mat-label>
                <input matInput [value]="themeForm.value.foreground" maxlength="7" formControlName="foreground"style>                    
            </mat-form-field>                 
            </div>
            
            <div class="col"><input type="color" [value]="themeForm.value.foreground" formControlName="foreground" class='bar' id='colour' style="height: 50px;width: 50px;" ></div>   
          </div>
        </div>
        <div class="col-md shorter-col v-short-col">              
          <div class="row">
            <div class="col color-text">
              <p style="margin: 0px;display: contents;">Side bar:</p><br>
              <mat-form-field class="half-width">
                <mat-label>Hex</mat-label>
                <input matInput [value]="themeForm.value.highlight" maxlength="7" formControlName="highlight"style>                    
            </mat-form-field>                 
            </div>
            <div class="col"><input type="color" [value]="themeForm.value.highlight" formControlName="highlight" class='bar' id='colour' style="height: 50px;width: 50px;" ></div>   
          </div>
          <div class="row">
            <div class="col color-text">
              <p style="margin: 0px;display: contents;">Top bar:</p><br>
              <mat-form-field class="half-width">
                <mat-label>Hex</mat-label>
                <input matInput [value]="themeForm.value.border" maxlength="7" formControlName="border"style>                    
            </mat-form-field>                 
            </div>
            <div class="col"><input type="color" [value]="themeForm.value.border" formControlName="border" class='bar' id='colour' style="height: 50px;width: 50px;" ></div>   
          </div>
        </div>
        <div class="col-md shorter-col v-short-col"> 
          <div class="row">
            <div class="col color-text">
              <p style="margin: 0px;display: contents;position: relative;top:10px;">Dashboard name:</p>
              <mat-form-field class="half-width">
                <mat-label></mat-label>
                <input matInput placeholder="StayPrivate Admin Console" [value]="themeForm.value.name" formControlName="name" autocomplete="off" >
              </mat-form-field>
            </div>
          </div>
          </div>
      </div>
      <div class="row button-container">
        <button mat-flat-button color="primary"  class="sp-background theme-button" (click)="onSubmit()">Update theme</button>
        <button *ngIf="themeForm.dirty" mat-flat-button color="primary" ngif class="theme-button clear-button" (click)="onClear()">Clear changes</button>
      </div>
    </mat-card-content>
    <hr/><br/>
      <mat-card-title class="title-spacer sp-title">Your logo</mat-card-title>
      <mat-card  class="logo-box">
        
        <img class="loginImage image-adj" [src]="img"/><br>
        
      </mat-card>
      <div class="row button-container">
        <label class="custom-file-upload sp-background logo-button">
          Change logo
          <input type='file' (change)="onFileChange($event)" >
        </label>
      </div>
  </mat-card>
</form>