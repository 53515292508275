<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" style="margin: 40px 0px 20px 0px;">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding isExpanded>
    <div class="tree-div" [ngClass]="[(node.StatusInd=='I')? 'tree-suspended':'',(node.StatusInd=='D')? 'tree-deleted':'']">
      <!-- <button mat-icon-button disabled="true"></button> -->

      <span *ngIf='node.SPMCompanyType=="C"' [routerLink]="['/detail',node.Id]" class="tree-text">{{node.Name}}</span>
      <span *ngIf='node.SPMCompanyType!="C"' [routerLink]="['/detail',node.Id]"
        class="sp-title tree-text">{{node.Name}}</span>

      <button mat-stroked-button class="current-pill" *ngIf="node.Id == currentId">Current Company</button>
      <button mat-stroked-button class="setup-pill" *ngIf='!node.DomainList[0]&&node.InitialTier!="0"&&node.StatusInd=="A"'
          [routerLink]="['/detail',node.Id]" fragment="setup">Setup Incomplete</button>
          <button mat-stroked-button class="suspended-pill" *ngIf='node.StatusInd=="I"'
          >Company Suspended</button>
          <button mat-stroked-button class="deleted-pill" *ngIf='node.StatusInd=="D"'
          >Company Deleted</button>
    </div>


    <!-- <mat-icon *ngIf='node.DomainList[0]' style="color: #04AA6D;">link</mat-icon> -->

  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding isExpanded>
    <div class="tree-div" [ngClass]="[(node.StatusInd=='I')? 'tree-suspended':'',(node.StatusInd=='D')? 'tree-deleted':'']">
      <span *ngIf='node.SPMCompanyType=="C"' [routerLink]="['/detail',node.Id]" class="tree-text">{{node.Name}}</span>
      <span *ngIf='node.SPMCompanyType!="C"' [routerLink]="['/detail',node.Id]"
        class="sp-title tree-text">{{node.Name}}</span>
      <button mat-stroked-button disabled class="current-pill" *ngIf="node.Id == currentId">Current Company</button>
      <!-- <mat-icon *ngIf='node.DomainList[0]' style="color: #04AA6D;">link</mat-icon> -->
      <button mat-stroked-button class="setup-pill" *ngIf='!node.DomainList[0]&&node.InitialTier!="0"&&node.StatusInd=="A"'
          [routerLink]="['/detail',node.Id]" fragment="setup">Setup Incomplete</button>
          <button mat-stroked-button class="suspended-pill" *ngIf='node.StatusInd=="I"'
          >Company Suspended</button>
          <button mat-stroked-button class="deleted-pill" *ngIf='node.StatusInd=="D"'
          >Company Deleted</button>
    </div>
  </mat-tree-node>
</mat-tree>