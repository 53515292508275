import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { AddUserComponent } from '../add-user/add-user.component';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

export interface DialogData {
  emailAddress: string;
  name: string;
}


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(private http: HttpClient, private globals: Globals, private route: ActivatedRoute, public dialog: MatDialog) { }
  @Input() public owner: any;
  @Input() public companyState: string;

  companyDomain: boolean = false
  users = null;
  emailAddress: string;
  name: string;
  displayedColumns: string[] = ['LoginUserName', 'PersonalName', 'EmailAddress', 'Delete'];
  dataSource
  async ngOnInit(): Promise<void> {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    if (this.route.snapshot.params['id']) {
      param = param.append('CompanyId', this.route.snapshot.params['id']);
    } else {
    }

    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyUsers.php', { params: param }).subscribe({
      next: data => {
        this.users = data.UserList
        this.dataSource = this.users;
      },
      error: error => { console.error('There was an error!'); }
    })
    /*this.http.get<any>(this.globals.getApiUrl()+'api/getSPMailCompanyInfo.php', {params:param}).subscribe({next: data => 
    {
      if(data.DomainList==""){this.companyDomain=false}
      else{this.companyDomain=true}
    },
    error: error => 
      {console.error('There was an error!');}
    });*/
  }
  openAddUser() {
    if(this.companyState=="A"){
      const dialogRef = this.dialog.open(AddUserComponent, {
        width: '350px',
        data: { route: this.route }
      });
  
      dialogRef.afterClosed().subscribe(async result => {
        let param = new HttpParams();
        param = param.append('AuthToken', await this.globals.getAuthToken());
        param = param.append('UserName', this.globals.getSystemUserName());
        if (this.route.snapshot.params['id']) {
          param = param.append('CompanyId', this.route.snapshot.params['id']);
        } else {
        }
        this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyUsers.php', { params: param }).subscribe({
          next: data => {
            this.users = data.UserList
  
          },
          error: error => { console.error('There was an error!'); }
        })
      });
    }
  }
  async deleteUser(userDel: string,userName: string) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: {
        text: [
          'You are about to delete '+userName+"'s access to the StayPrivate dashboard.",
          'This action is permanent and cannot be undone.'
        ]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        let postForm = new FormData;
        postForm.append('AuthToken', await this.globals.getAuthToken());
        postForm.append('UserName', this.globals.getSystemUserName());
        postForm.append('DeleteUserName', userDel);
        this.http.post<any>(this.globals.getApiUrl() + 'api/deleteSPMailCompanyUser.php', postForm).subscribe({
          next: data => {
            let tempList = [...this.users]
            for (var i = 0; i < tempList.length; i++) {
              if (tempList[i].UserName === userDel) {
                tempList.splice(i, 1);
                this.users = [...tempList];
              }
            }
          },
          error: error => {
          }
        });
      }

    });



  }
}

