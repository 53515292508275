import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private globals: Globals, private http: HttpClient,private titleService: Title) { }
  myCompanyType:string;
  spChild=false;
  parentIds = ['543','9584','9585','9580']
  async ngOnInit(): Promise<void> {
    this.myCompanyType = this.globals.getCompanyType();
    this.titleService.setTitle("Contact Us - STAY PRIVATE");
    let param = new HttpParams();
        param = param =param.append('AuthToken',await this.globals.getAuthToken());
        param = param.append('UserName', this.globals.getSystemUserName());
        this.http.get<any>(this.globals.getApiUrl()+'api/getSPMailCompanyInfo.php', {params:param}).subscribe({next: data => 
      {
        if(this.parentIds.find(id => id == data.ParentCompanyId)){
          this.spChild = true;
        }else{ this.spChild = false; }    
    },
      error: error => 
      {console.error('There was an error!');}
    });
    
  }

}
