<mat-card-title class="title-padding sp-title">
  Companies <br>
</mat-card-title>
<mat-spinner *ngIf="loading" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>

<div *ngIf="!loading" style="padding:0px 10px">
  <p *ngIf="!dataSource.data[0]" style="margin: 30px 0px 10px 0px;">
    You have not added any companies yet. <br><br>
    Please use the menu on the left to add a new company.
  </p>
  <!-- <mat-form-field>
    <input matInput placeholder="Search" (input)="filterChanged($event.target.value)">
 </mat-form-field> -->

  <div *ngIf="dataSource.data[0]">
    <span *ngIf="tClients>0||tPartners>0">You have</span>
    <span *ngIf="tClients>0"> {{tClients}} client compan</span><span *ngIf="tClients>1">ies</span><span
      *ngIf="tClients==1">y</span>
    <span *ngIf="tClients>0&&tPartners>0"> and</span>
    <span *ngIf="tPartners>0" class="sp-title partner-font"> {{tPartners}} partner</span><span class="sp-title partner-font"
      *ngIf="tPartners>1">s</span>
    <span *ngIf="tClients>0||tPartners>0"> registered.</span>
    <!-- Information button start -->
    <button mat-icon-button [matMenuTriggerFor]="CompanyTierHelp" *ngIf="pClients>0||pPartners>0"
      aria-label="Info button" class="info-icon-container" style="position: relative; bottom: 3px;">
      <mat-icon class="info-icon">info</mat-icon>
    </button>
    <!-- <button (click)="clickB()"></button> -->
    <mat-menu #CompanyTierHelp="matMenu">
      <div style="padding: 10px;">
        <span *ngIf="clients>0||partners>0">You have added:</span><br>
        <span *ngIf="clients>0">{{clients}} client</span><span *ngIf="clients>1">s</span>
        <span *ngIf="clients>0&&partners>0"> and </span>
        <span *ngIf="partners>0">{{partners}} partner</span><span *ngIf="partners>1">s</span><br><br>
        <span *ngIf="pClients>0||pPartners>0">Your partners have added:</span><br>
        <span *ngIf="pClients>0">{{pClients}} client</span><span *ngIf="pClients>1">s</span>
        <span *ngIf="pClients>0&&pPartners>0"> and </span>
        <span *ngIf="pPartners>0">{{pPartners}} partner</span><span *ngIf="pPartners>1">s</span>
      </div>

    </mat-menu>
    <!-- Information button finish -->
<div *ngIf="allView" style="display: inline-block; position: relative; bottom: 4px;">
  <button mat-stroked-button class="setup-pill" *ngIf="onlyActiveView.length<allView.length"(click)="changeView()">
    <span *ngIf="viewDeleted==true">Hide Deleted</span>
    <span *ngIf="viewDeleted==false">Show Deleted</span>
  </button>
</div>
    

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="list-border ">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [ngClass]="[(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">
        <button mat-icon-button disabled="true" class="list-item"></button>
        <span class="list-item "
          [ngClass]="[(node.SPMCompanyType!='C')? 'sp-title partner-font':'',(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">{{node.Name}}
        </span>
        <button mat-icon-button class="list-item" [routerLink]="['/detail',node.Id]" [ngClass]="[(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <!-- <button mat-stroked-button class="setup-pill"*ngIf='!node.DomainList[0]&&node.SPMCompanyType=="C"' [routerLink]="['/detail',node.Id]"
      fragment="setup">Setup Incomplete</button> -->
        <button mat-stroked-button class="setup-pill" *ngIf='!node.DomainList[0]&&node.InitialTier!="0"&&node.StatusInd=="A"'
          [routerLink]="['/detail',node.Id]" fragment="setup">Setup Incomplete</button>
          <button mat-stroked-button class="suspended-pill" *ngIf='node.StatusInd=="I"'
          >Company Suspended</button>
          <button mat-stroked-button class="deleted-pill" *ngIf='node.StatusInd=="D"'
          >Company Deleted</button>
          <!-- <button mat-stroked-button class="setup-pill" (click)="delete(node.Id)"> Delete </button> -->

        <!-- <mat-icon *ngIf='node.DomainList[0]' style="color: #04AA6D;">link</mat-icon> -->

      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding 
      [ngClass]="[(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">
        <button mat-icon-button matTreeNodeToggle class="list-item" style="bottom: 1px;" [attr.aria-label]="'Toggle ' + node.name" [ngClass]="[(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <span class="list-item "
          [ngClass]="[(node.SPMCompanyType!='C')? 'sp-title partner-font':'',(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">{{node.Name}}
        </span>
        <button mat-icon-button class="list-item" [routerLink]="['/detail',node.Id]" [ngClass]="[(node.StatusInd=='I')? 'list-suspend':'',(node.StatusInd=='D')? 'list-delete':'']">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-icon *ngIf='!node.DomainList[0]&&node.SPMCompanyType=="C"' style="color: #f44336;">build</mat-icon>
        <button mat-stroked-button class="setup-pill" *ngIf='!node.DomainList[0]&&node.InitialTier!="0"&&node.StatusInd=="A"'
          [routerLink]="['/detail',node.Id]" fragment="setup">Setup Incomplete</button>
          <button mat-stroked-button class="suspended-pill" *ngIf='node.StatusInd=="I"'
          >Company Suspended</button>
          <button mat-stroked-button class="deleted-pill" *ngIf='node.StatusInd=="D"'
          >Company Deleted</button>
        <!-- <mat-icon *ngIf='node.DomainList[0]' style="color: #04AA6D;">link</mat-icon> -->
        <!-- <button mat-stroked-button class="setup-pill" (click)="delete(node.Id)"> Delete </button> -->

      </mat-tree-node>
    </mat-tree>
  </div>
</div>