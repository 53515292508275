import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-edit-theme',
  templateUrl: './edit-theme.component.html',
  styleUrls: ['./edit-theme.component.css']
})
export class EditThemeComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private globals:Globals, 
    private http:HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router
    ) 
    { }

  
  img: string;
  themeForm = this.fb.group({
    background: [localStorage.getItem('background-color'),[Validators.required,Validators.pattern("#[A-Fa-f0-9]{6}")]],
    foreground: [localStorage.getItem('foreground-color'),[Validators.required,Validators.pattern("#[A-Fa-f0-9]{6}")]],
    highlight: [localStorage.getItem('highlight-color'),[Validators.required,Validators.pattern("#[A-Fa-f0-9]{6}")]],
    border: [localStorage.getItem('border-color'),[Validators.required,Validators.pattern("#[A-Fa-f0-9]{6}")]],
    name : [localStorage.getItem('app-name')],
    
  });

  ngOnInit(): void {
    this.globals.refreshAuth();
    
     if(localStorage.getItem("img-url")!=null){
      this.img=(this.globals.getImgUrl()+localStorage.getItem("img-url")+"logo-small.png?t="+new Date().getTime())
    }else{this.img="https://stayprivate.com/SP_Logo_Icon+Text_Blue.svg"}
   
  }
  async onFileChange(evt): Promise<void> {
    let imageForm = new FormData;    
    imageForm.append('AuthToken',await this.globals.getAuthToken());
    imageForm.append('UserName',this.globals.getSystemUserName());
    imageForm.append('LogoType',"S");
    imageForm.append('UploadFileInd',evt.target.files[0]);
    this.http.post<any>(this.globals.getApiUrl()+'api/uploadSPMailCompanyLogo.php', imageForm).subscribe({
      next: data => { 
        
        this.getLogo()    
      },
      error: error => {
        this._snackBar.open(error.error.ErrorMsg,"OK",{duration:5000} );
      }
    });
  }
  async getLogo(){
    let param = new HttpParams();
    param = param.append('AuthToken',await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    
    this.http.get<any>(this.globals.getApiUrl()+'api/getSPMailCompanyTheme.php', {params:param}).subscribe({
      next: themedata => {        
      localStorage.setItem('img-url', themedata.logo);
      this.img=(this.globals.getImgUrl()+localStorage.getItem("img-url")+"logo-small.png?t="+new Date().getTime())       
      },
      error: error => {
         console.error('There was an error!');
      }
    });
  }
  async onSubmit(){
    localStorage.setItem('background-color', this.themeForm.value.background);
    localStorage.setItem('foreground-color', this.themeForm.value.foreground);
    localStorage.setItem('highlight-color', this.themeForm.value.highlight);
    localStorage.setItem('border-color', this.themeForm.value.border);
    localStorage.setItem('app-name', this.themeForm.value.name);
    let form = new FormData;    
    form.append('AuthToken',await this.globals.getAuthToken());
    form.append('UserName',this.globals.getSystemUserName());
    form.append('background-color',this.themeForm.value.background);
    form.append('foreground-color',this.themeForm.value.foreground);
    form.append('highlight-color',this.themeForm.value.highlight);
    form.append('border-color',this.themeForm.value.border);       
    form.append('app-name',this.themeForm.value.name)
    this.themeForm.markAsPristine();

    this.http.post<any>(this.globals.getApiUrl()+'api/editSPMailCompanyTheme.php', form).subscribe({
      next: data => {
        this.globals.checkTheme();
        document.getElementById("dashboard-title").innerHTML = localStorage.getItem("app-name");
      },
      error: error => {
         console.error('There was an error!');
      }
    });
  }
  onClear(){
    this.themeForm=this.fb.group({
      background: [localStorage.getItem('background-color')],
      foreground: [localStorage.getItem('foreground-color')],
      highlight: [localStorage.getItem('highlight-color')],
      border: [localStorage.getItem('border-color')],
      name: [localStorage.getItem('app-name')],
    });
  }
}
