<div class="full-screen">
  <div class="centered">
    <form [formGroup]="forgotPasswordForm" novalidate (ngSubmit)="onSubmit()">
      <mat-card class="form-padding">
        <div class="loginImageContainer">
          <img class="loginImage" src="https://stayprivate.com/SP_Logo_Icon+Text_Blue.svg"/>
        </div>
        <mat-card-title>
          Forgotten Password
        </mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Enter your email</mat-label>
                <input matInput placeholder="name@yourcompany.com" formControlName="email">
                <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <button mat-flat-button color="primary" [disabled]="!forgotPasswordForm.valid"class="sp-background" >Request Password Reset</button>
          </div>
          <div class="row">
            <a mat-flat-button routerLink="/login">
              Back to log in
            </a>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>


