import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Globals } from 'src/app/globals';
import { CsvDownloadDialogComponent } from '../csv-download-dialog/csv-download-dialog.component';
import { CsvUpdateDialogComponent } from '../csv-update-dialog/csv-update-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(public globals:Globals,private http:HttpClient,private dialog:MatDialog,private titleService: Title
    ) {}  
  myCompanyType = this.globals.getCompanyType();
  ngOnInit(): void {
    this.titleService.setTitle("Home - STAY PRIVATE");
    this.globals.refreshAuth();    
  }
  


  
}
