<mat-card class="card-padding">
  <mat-card-content>
    <button mat-button (click)="openAddUser()" class="sp-title add-button">
      <mat-icon>add</mat-icon><span style="position: relative;top: 1px;">Add User</span>
    </button>

    <mat-spinner *ngIf="users==null" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>
    <div *ngIf="users!=null">
      <div *ngIf="users[0]==null" style="margin: 50px auto 50px auto; display: table;">
        <span>No users found</span>
      </div>

      <div *ngIf="users[0]!=null">
        <table mat-table [dataSource]="users" *ngIf="users.length>0" class="mat-elevation-z2">>
          <!-- Position Column -->
          <ng-container matColumnDef="LoginUserName">
            <th mat-header-cell *matHeaderCellDef> User Name </th>
            <td mat-cell *matCellDef="let user"> {{user.LoginUserName}} <mat-icon class="owner" *ngIf="user.LoginUserName==owner.LoginUserName">military_tech</mat-icon></td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="PersonalName">
            <th mat-header-cell *matHeaderCellDef> Personal Name </th>
            <td mat-cell *matCellDef="let user"> {{user.PersonalName}} </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="EmailAddress">
            <th mat-header-cell *matHeaderCellDef> Email Address </th>
            <td mat-cell *matCellDef="let user"> {{user.EmailAddress}} </td>
          </ng-container>
          <ng-container matColumnDef="Delete" >
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let user">   
              <button mat-icon-button (click)="deleteUser(user.UserName,user.PersonalName)" *ngIf="globals.isOwner()" style="float: right; color: #f44336;"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>

  </mat-card-content>
</mat-card>