import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
interface SelectorType {
  value: string;
  viewValue?: string;
}
export class TierCheck {
  public id: Number;
  public name: string;
  public description: string;
  public checked: boolean;
}

@Component({
  selector: 'app-company-advanced',
  templateUrl: './company-advanced.component.html',
  styleUrls: ['./company-advanced.component.css']
})

export class CompanyAdvancedComponent implements OnInit {

  constructor(
    public globals: Globals,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }
  currencyTypes: SelectorType[] = [
    { value: 'GBP' },
    { value: 'EUR' },
    { value: 'USD' },
    { value: 'AUD' },
  ];
  countryCodes: SelectorType[] = [
    { value: 'GB', viewValue: 'United Kingdom' },
    { value: 'DE', viewValue: 'European Union' },
    { value: 'US', viewValue: 'United States' },
  ];
  flagData;
  companyFlagsForm = this.fb.group({
    OnlyEmailLogoIfCustomFlag: [null],
    IdentResellerInEmailFooterFlag: [null],
    CanUseApiFlag: [null],
    ApiSendDomainList: [null],
    DataCountryCode: [null],
    InitialTier: [null],
    SendFromPersEmailIfRecFlag:[null]
  });
  billingData;
  companyBillingForm = this.fb.group({
    billingAddress: [null, [Validators.pattern("^[a-z0-9._%+-]{2,}@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    billingCurrency: [null],
    discount: [null],
    firstProvisionDate: [null],
    canAutoDeleteFlag: [null],
    isWebSignup: [null],
    showStripeFlag:[null],
    tierFlags: this.fb.array([])

  });
  selectedTierArray: TierCheck[];
  activeTierList:SelectorType[];
  companyId = this.route.snapshot.params['id'];
  loading1 = true;
  loading2 = true;
  formTiers=null;
  async ngOnInit(): Promise<void> {
    for(let tier of this.globals.getTiers()){
      if (!this.activeTierList){this.activeTierList=[{value:''+tier.id,viewValue:tier.name}]}
      else{this.activeTierList.push({value:''+tier.id,viewValue:tier.name})}      
    }

    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('CompanyId', this.companyId);
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: data => {
        this.flagData = data;
        this.companyFlagsForm.setValue({
          OnlyEmailLogoIfCustomFlag: data.OnlyEmailLogoIfCustomFlag.includes("Y"),
          IdentResellerInEmailFooterFlag: data.IdentResellerInEmailFooterFlag.includes("Y"),
          CanUseApiFlag: data.CanUseApiFlag.includes("Y"),
          ApiSendDomainList: data.ApiSendDomainList,
          DataCountryCode: data.DataCountryCode,
          InitialTier: data.InitialTier,
          SendFromPersEmailIfRecFlag: data.SendFromPersEmailIfRecFlag.includes("Y")

        })
        this.loading1 = false;
      },
      error: error => { console.error('There was an error!'); this.loading1 = false; }
    });;
    
    param = param.append('companyId', this.companyId);
    param = param.delete('CompanyId')
    this.http.get(this.globals.getServicesUrl() + 'company/getCompanyDetails', { params: param }).subscribe({
      next: data => {
        if (!data[0].autoDeleteFlag) { data[0].autoDeleteFlag = "N" }
        if (!data[0].showStripeFlag) { data[0].showStripeFlag = "N" }
        if (!data[0].webSignup) { data[0].webSignup = "N" }
        this.billingData = data
        this.companyBillingForm.setValue({
          discount: data[0].discount,
          billingCurrency: data[0].billingCurrency,
          billingAddress: data[0].billingAddress,
          firstProvisionDate: new Date(data[0].firstProvisionDate * 1000),
          canAutoDeleteFlag: data[0].autoDeleteFlag.includes("Y"),
          isWebSignup: data[0].webSignup.includes("Y"),
          showStripeFlag:data[0].showStripeFlag.includes("Y"),
          tierFlags: []
        })

        if(!data[0].firstProvisionDate){
          this.companyBillingForm.get('firstProvisionDate').setValue(null)
        }
        
        this.formTiers=data[0].tiers;
        for (let tier of this.globals.getTiers()) {
          var foundTier = null
          if( data[0].tiers){
          foundTier = data[0].tiers.find((element) => element == tier.id);

          }
          if((!tier.name.includes("+ Branding"))){
            if (this.selectedTierArray == null) {
              if(foundTier!=null){
              (<FormArray>this.companyBillingForm.get('tierFlags')).push(this.fb.control(true));
              }else{
              (<FormArray>this.companyBillingForm.get('tierFlags')).push(this.fb.control(false));
              }
              this.selectedTierArray = [{ id: tier.id, name: tier.name,description:tier.description, checked: false }]
            } else {
              if(foundTier!=null){
                (<FormArray>this.companyBillingForm.get('tierFlags')).push(this.fb.control(true));
                }else{
                (<FormArray>this.companyBillingForm.get('tierFlags')).push(this.fb.control(false));
                }
              this.selectedTierArray.push({ id: tier.id, name: tier.name,description:tier.description, checked: false });
            }
          }
        }
        this.loading2 = false;
      },
      error: error => { console.error('There was an error!'); this.loading2 = false; }
    });

  }

  getControls() {
    return (<FormArray>this.companyBillingForm.get('tierFlags')).controls;
  }

  resetForms() {
    this.companyFlagsForm.reset();
    this.companyFlagsForm.setValue({
      OnlyEmailLogoIfCustomFlag: this.flagData.OnlyEmailLogoIfCustomFlag.includes("Y"),
      IdentResellerInEmailFooterFlag: this.flagData.IdentResellerInEmailFooterFlag.includes("Y"),
      CanUseApiFlag: this.flagData.CanUseApiFlag.includes("Y"),
      ApiSendDomainList: this.flagData.ApiSendDomainList,
      DataCountryCode: this.flagData.DataCountryCode,
      InitialTier: this.flagData.InitialTier,
      SendFromPersEmailIfRecFlag: this.flagData.SendFromPersEmailIfRecFlag.includes("Y")

    })
    this.loading1=true
    this.companyBillingForm.reset();
 var resetTeirs = new FormArray([])
    for (let tier of this.globals.getTiers()) {
      var foundTier = this.formTiers.find((element) => element == tier.id);
      if((!tier.name.includes("+ Branding"))){
      if (resetTeirs == null) {
        if(foundTier!=null){
          resetTeirs.push(this.fb.control(true));
        }else{
          resetTeirs.push(this.fb.control(false));
        }
      } else {
        if(foundTier!=null){
          resetTeirs.push(this.fb.control(true));
          }else{
            resetTeirs.push(this.fb.control(false));
          }
      }
      }
    }
    this.companyBillingForm.setValue({
      discount: this.billingData[0].discount,
      billingCurrency: this.billingData[0].billingCurrency,
      billingAddress: this.billingData[0].billingAddress,
      firstProvisionDate: new Date(this.billingData[0].firstProvisionDate * 1000),
      canAutoDeleteFlag: this.billingData[0].autoDeleteFlag.includes("Y"),
      isWebSignup: this.billingData[0].webSignup.includes("Y"),
      showStripeFlag:this.billingData[0].showStripeFlag.includes("Y"),
      tierFlags:resetTeirs.value
    })
    this.loading1=false

  }

  async update() {
    let i = 0;
    if (this.companyFlagsForm.dirty) {
      let form = new FormData;
      form.append('AuthToken', await this.globals.getAuthToken());
      form.append('UserName', this.globals.getSystemUserName());
      form.append('CompanyId', this.companyId);
      if (this.companyFlagsForm.value.OnlyEmailLogoIfCustomFlag == true) { form.append('OnlyEmailLogoIfCustomFlag', "Y"); } else { form.append('OnlyEmailLogoIfCustomFlag', "N"); }
      if (this.companyFlagsForm.value.IdentResellerInEmailFooterFlag == true) { form.append('IdentResellerInEmailFooterFlag', "Y"); } else { form.append('IdentResellerInEmailFooterFlag', "N"); }
      if (this.companyFlagsForm.value.CanUseApiFlag == true) { form.append('CanUseApiFlag', "Y"); } else { form.append('CanUseApiFlag', "N"); }
      if (this.companyFlagsForm.value.SendFromPersEmailIfRecFlag == true) { form.append('SendFromPersEmailIfRecFlag', "Y"); } else { form.append('SendFromPersEmailIfRecFlag', "N"); }
      form.append('DataCountryCode', this.companyFlagsForm.value.DataCountryCode);
      form.append('InitialTier', this.companyFlagsForm.value.InitialTier);
      this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', form).subscribe({
        next: data => {
          i++;
          if (i == 2) {
            location.reload();
          }
        },
        error: error => {
          console.error('There was an error!');
        }
      });
    } else {
      i++; if (i == 2) {
        location.reload();
      }
    }
    if (this.companyBillingForm.dirty) {
      if (this.companyBillingForm.valid) {
        let form = new FormData;
        form.append('AuthToken', await this.globals.getAuthToken());
        form.append('UserName', this.globals.getSystemUserName());
        form.append('companyId', this.companyId);
        for (let i = 0; i < this.companyBillingForm.value.tierFlags.length; i++) {
          if (this.companyBillingForm.value.tierFlags[i] == true) {
            form.append('tiers', this.selectedTierArray[i].id.toString());
          }
        }
        
        if (this.companyBillingForm.value.canAutoDeleteFlag == true) { form.append('autoDeleteFlag', "Y"); } else { form.append('autoDeleteFlag', "N"); }
        if (this.companyBillingForm.value.isWebSignup == true) { form.append('webSignup', "Y"); } else { form.append('webSignup', "N"); }
        if (this.companyBillingForm.value.showStripeFlag == true) { form.append('showStripeFlag', "Y"); } else { form.append('showStripeFlag', "N"); }
        if (this.companyBillingForm.value.billingAddress != null) { form.append('billingAddress', this.companyBillingForm.value.billingAddress); }
        if (this.companyBillingForm.value.billingCurrency != null) { form.append('billingCurrency', this.companyBillingForm.value.billingCurrency); }
        if (this.companyBillingForm.value.discount != null) { form.append('discount', this.companyBillingForm.value.discount); }
        if (this.companyBillingForm.value.firstProvisionDate != null) { form.append('firstProvisionDate', Math.round(this.companyBillingForm.value.firstProvisionDate.getTime() / 1000) + 14400 + ""); }

        this.http.post<any>(this.globals.getServicesUrl() + 'company/updateCompanyDetails', form).subscribe({
          next: data => {
            i++;
            if (i == 2) {
              location.reload();
            }
          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
      }
    } else {
      i++; if (i == 2) {
        location.reload();
      }
    }
  }
  async suspend() {
    // 
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: {
        text: [
          'You are about to suspend the StayPrivate service for ' + this.flagData.CompanyName + '.',
          'This will remove their ability to send secure emails via StayPrivate.'
        ]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        //WHAT TO DO
        let form = new FormData;
        form.append('AuthToken', await this.globals.getAuthToken());
        form.append('UserName', this.globals.getSystemUserName());
        form.append('CompanyId', this.companyId);

        this.http.post<any>(this.globals.getApiUrl() + 'api/deactivateSPMailCompany.php', form).subscribe({
          next: data => {
            location.reload();

          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
        //WHAT TO DO
      }
    });
    // 

  }
  async unsuspend() {
    // 
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: {
        text: [
          'You are about to unsuspend the StayPrivate service for ' + this.flagData.CompanyName + '.',
          'Once unsuspended ' + this.flagData.CompanyName + ' can resume sending secure emails via StayPrivate.'
        ]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        //WHAT TO DO
        let form = new FormData;
        form.append('AuthToken', await this.globals.getAuthToken());
        form.append('UserName', this.globals.getSystemUserName());
        form.append('CompanyId', this.companyId);

        this.http.post<any>(this.globals.getApiUrl() + 'api/activateSPMailCompany.php', form).subscribe({
          next: data => {
            location.reload();

          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
        //WHAT TO DO
      }
    });
    // 

  }
  async delete() {
    // 
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: {
        text: [
          'You are about to delete ' + this.flagData.CompanyName + "'s StayPrivate system.",
          'This will cancel their StayPrivate service.'
        ]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        //WHAT TO DO
        let form = new FormData;
        form.append('AuthToken', await this.globals.getAuthToken());
        form.append('UserName', this.globals.getSystemUserName());
        form.append('CompanyId', this.companyId);

        this.http.post<any>(this.globals.getApiUrl() + 'api/deleteSPMailCompany.php', form).subscribe({
          next: data => {
            location.reload();

          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
        //WHAT TO DO
      }
    });
    // 

  }
  async restore() {
    // 
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: {
        text: [
          'You are about to restore ' + this.flagData.CompanyName + "'s StayPrivate system.",
          'This will reactivate their StayPrivate service.'
        ]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        //WHAT TO DO
        let form = new FormData;
        form.append('AuthToken', await this.globals.getAuthToken());
        form.append('UserName', this.globals.getSystemUserName());
        form.append('CompanyId', this.companyId);

        this.http.post<any>(this.globals.getApiUrl() + 'api/undoSPMailCompanyDeletion.php', form).subscribe({
          next: data => {
            location.reload();

          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
        //WHAT TO DO
      }
    });
    // 

  }
}
