import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { RemoveLogoComponent } from './remove-logo/remove-logo.component';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.css']
})
export class CompanyLogoComponent implements OnInit {

  constructor(
    public globals: Globals,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }
  @Input() public thisCompanyName: String;
  @Input() public teir: any;
  @Input() public type: String;
  @Input() public companyState: string;

  loading = true
  logo = "";
  custlogo = "";
  logoupload;
  custlogoupload;
  custId = ""
  async ngOnInit(): Promise<void> {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    if (this.route.snapshot.params['id']) {
      param = param.append('CompanyId', this.route.snapshot.params['id']);
    }
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyTheme.php', { params: param }).subscribe({
      next: themedata => {
        if (themedata.logo) {
          this.logo = (this.globals.getImgUrl() + themedata.logo + "logo-small.png?t=" + new Date().getTime())
        }
        if (themedata["custom-logo-path"]) {
          this.custlogo = (themedata["custom-logo-path"] + "?t=" + new Date().getTime())
        }
        this.loading = false;
      },
      error: error => {
        console.error('There was an error!');
        this.loading = false;
      }
    });

    //Check stripe for subscription
    let param1 = new HttpParams();
    param1 = param1.append('AuthToken', await this.globals.getAuthToken());
    param1 = param1.append('UserName', this.globals.getSystemUserName());

    if (this.route.snapshot.params['id']) {
      param1 = param1.append('CompanyId', this.route.snapshot.params['id']);
    } else {
      param1 = param1.append('CompanyId', this.globals.getCompanyId());
    }
    this.http.get<any>(this.globals.getServicesUrl() + 'stripe/getSub', { params: param1 }).subscribe({
      next: data => {
        if (data.subStatus == "active") {
          this.custId = data.customer
        } else {

        }


      },
      error: error => {
        if (error.error.text == "No subscription data found") {

        } if (error.error.error == "Could not find valid subscription") {
        } else {
          console.error('There was an error!');
          console.error(error);
        }
      }
    });
  }

  async uploadLogo(evt): Promise<void> {
    if (this.companyState == "A") {
      this._snackBar.open("Uploading...", "OK", { duration: 5000 });
      let imageForm = new FormData;
      imageForm.append('AuthToken', await this.globals.getAuthToken());
      imageForm.append('UserName', this.globals.getSystemUserName());
      imageForm.append('LogoType', "S");
      imageForm.append('UploadFileInd', evt.target.files[0]);
      if (this.route.snapshot.params['id']) {
        imageForm.append('CompanyId', this.route.snapshot.params['id']);
      }
      this.http.post<any>(this.globals.getApiUrl() + 'api/uploadSPMailCompanyLogo.php', imageForm).subscribe({
        next: data => {
          this.logoupload = ""
          this.getLogo()
          let notificationForm = new FormData;
          notificationForm.append('type', "Logo Uploaded");
          notificationForm.append('message', "Logo uploaded for " + this.thisCompanyName);
          if (this.route.snapshot.params['id']) {
            notificationForm.append('companyId', this.route.snapshot.params['id']);
          } else { notificationForm.append('companyId', this.globals.getCompanyId()); }
          this.http.post<any>(this.globals.getServicesUrl() + 'company/addNotification', notificationForm).subscribe({
            next: data => {
            },
            error: error => {
              this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
            }
          });
        },
        error: error => {
          this.logoupload = ""
          this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
        }
      });
    }

  }
  async uploadCustLogo(evt): Promise<void> {
    if (this.companyState == "A") {
      this._snackBar.open("Uploading...", "OK", { duration: 5000 });
      let imageForm = new FormData;
      let tempToken = await this.globals.getAuthToken()
      imageForm.append('AuthToken', tempToken);
      imageForm.append('UserName', this.globals.getSystemUserName());
      imageForm.append('LogoType', "S");
      imageForm.append('UploadFileInd', evt.target.files[0]);
      if (this.route.snapshot.params['id']) {
        imageForm.append('CompanyId', this.route.snapshot.params['id']);
      } else { imageForm.append('CompanyId', this.globals.getCompanyId()); }
      this.http.post<any>(this.globals.getApiUrl() + 'api/uploadSPMailCompanyCustomLogo.php', imageForm).subscribe({
        next: data => {
          let form = new FormData;
          form.append('AuthToken', tempToken);
          form.append('UserName', this.globals.getSystemUserName());
          if (this.route.snapshot.params['id']) {
            form.append('CompanyId', this.route.snapshot.params['id']);
          }
          if (this.teir.linkedBrandingTier != null && this.teir.branding == false && this.type == "C") {
            if (this.custId != "") {
              this.addToStripe()
            }
            form.append('InitialTier', this.teir.linkedBrandingTier);
            this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', form).subscribe({
              next: data => {
                this.custlogoupload = ""
                this.getLogo()
                let notificationForm = new FormData;
                notificationForm.append('type', "Branding Added");
                notificationForm.append('message', "Co-branded logo added to " + this.thisCompanyName);
                if (this.route.snapshot.params['id']) {
                  notificationForm.append('companyId', this.route.snapshot.params['id']);
                } else { notificationForm.append('companyId', this.globals.getCompanyId()); }
                this.http.post<any>(this.globals.getServicesUrl() + 'company/addNotification', notificationForm).subscribe({
                  next: data => {
                  },
                  error: error => {
                    this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
                  }
                });
              },
              error: error => {
                console.error('There was an error!');
              }
            });
          } else {
            this.custlogoupload = ""
            this.getLogo()
            let notificationForm = new FormData;
            notificationForm.append('type', "Branding Added");
            notificationForm.append('message', "Co-branded logo added to " + this.thisCompanyName);
            if (this.route.snapshot.params['id']) {
              notificationForm.append('companyId', this.route.snapshot.params['id']);
            } else { notificationForm.append('companyId', this.globals.getCompanyId()); }
            this.http.post<any>(this.globals.getServicesUrl() + 'company/addNotification', notificationForm).subscribe({
              next: data => {
              },
              error: error => {
                this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
              }
            });
          }
        },
        error: error => {
          this.custlogoupload = ""
          this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
        }
      });
    }

  }
  async getLogo() {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    if (this.route.snapshot.params['id']) {
      param = param.append('CompanyId', this.route.snapshot.params['id']);
    }
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyTheme.php', { params: param }).subscribe({
      next: themedata => {
        if (themedata.logo) {
          this.logo = (this.globals.getImgUrl() + themedata.logo + "logo-small.png?t=" + new Date().getTime())
        }
        else { this.logo = "" }
        if (themedata["custom-logo-path"]) {
          this.custlogo = (themedata["custom-logo-path"] + "?t=" + new Date().getTime())
        }
        else { this.custlogo = "" }
      },
      error: error => {
        console.error('There was an error!');
      }
    });
  }
  removeLogo() {
    if (this.companyState == "A") {
      const dialogRef = this.dialog.open(RemoveLogoComponent, {
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result == true) {
          let resetForm = new FormData;
          let tempToken = await this.globals.getAuthToken()

          resetForm.append('AuthToken', tempToken);
          resetForm.append('UserName', this.globals.getSystemUserName());
          if (this.route.snapshot.params['id']) {
            resetForm.append('CompanyId', this.route.snapshot.params['id']);
          } else { resetForm.append('CompanyId', this.globals.getCompanyId()); }
          this.http.post<any>(this.globals.getApiUrl() + 'api/resetSPMailCompanyThemeToDefault.php', resetForm).subscribe({
            next: data => {
              this.http.post<any>(this.globals.getApiUrl() + 'api/removeSPMailCompanyCustomLogo.php', resetForm).subscribe({
                next: data => {
                  let form = new FormData;
                  form.append('AuthToken', tempToken);
                  form.append('UserName', this.globals.getSystemUserName());
                  if (this.route.snapshot.params['id']) {
                    form.append('CompanyId', this.route.snapshot.params['id']);
                  }
                  if (this.teir.linkedBrandingTier != null && this.teir.branding == true && this.type == "C") {
                    form.append('InitialTier', this.teir.linkedBrandingTier);
                    this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', form).subscribe({
                      next: data => {

                        this.getLogo()
                        this._snackBar.open("Branding removed.", "OK", { duration: 5000 });
                        let notificationForm = new FormData;
                        notificationForm.append('type', "Branding Removed");
                        notificationForm.append('message', "Branding removed from " + this.thisCompanyName);
                        if (this.route.snapshot.params['id']) {
                          notificationForm.append('companyId', this.route.snapshot.params['id']);
                        } else { notificationForm.append('companyId', this.globals.getCompanyId()); }
                        this.http.post<any>(this.globals.getServicesUrl() + 'company/addNotification', notificationForm).subscribe({
                          next: data => {
                          },
                          error: error => {
                            this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
                          }
                        });
                      },
                      error: error => {
                        console.error('There was an error!');
                      }
                    });
                  } else {
                    this.getLogo()
                    this._snackBar.open("Branding removed.", "OK", { duration: 5000 });
                    let notificationForm = new FormData;
                    notificationForm.append('type', "Branding Removed");
                    notificationForm.append('message', "Branding removed from " + this.thisCompanyName);
                    if (this.route.snapshot.params['id']) {
                      notificationForm.append('companyId', this.route.snapshot.params['id']);
                    } else { notificationForm.append('companyId', this.globals.getCompanyId()); }
                    this.http.post<any>(this.globals.getServicesUrl() + 'company/addNotification', notificationForm).subscribe({
                      next: data => {
                      },
                      error: error => {
                        this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
                      }
                    });
                  }





                },
                error: error => {
                  this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
                }
              });
            },
            error: error => {
              this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
            }
          });
        }

      });
    }


  }
  addToStripe() {

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      data: {
        text: [
          'Co-branded logo has been added.',
          'Do you want this to be added to ' + this.thisCompanyName + "'s Stripe subscription."
        ]
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        //WHAT TO DO
        let form = new FormData();
        let tempToken = await this.globals.getAuthToken()
        form.append('systemUsername', this.globals.getSystemUserName());
        form.append('authToken', tempToken);
        form.append('compID', this.globals.getCompanyId());
        this.http.post<any>(this.globals.getServicesUrl() + 'stripe/addBranding', form).subscribe({
          next: data => {
    
          },
          error: error => {
            console.error('There was an error!');
          }
        });
        //WHAT TO DO
      }
    });
  }

}
