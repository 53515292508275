import { Resource } from "./resources.component";

export const RESOURCES: Resource[] = [
    { 
      docName: 'StayPrivate 2 Pager', 
      docLink: 'https://stayprivate.com/documentation/StayPrivate-2-Pager.pdf',
      imageUrl: 'https://stayprivate.com/resources/Resources_Thumbnail_Png.png' 
    },{ 
      docName: 'StayPrivate FAQs', 
      docLink: 'https://stayprivate.com/documentation/StayPrivate-FAQs.pdf',
      imageUrl: 'https://stayprivate.com/resources/Resources_Thumbnail_Png.png' 
    },{ 
      docName: 'StayPrivate Operational Manual', 
      docLink: 'https://stayprivate.com/documentation/StayPrivate-Operational-Manual.pdf',
      imageUrl: 'https://stayprivate.com/resources/Resources_Thumbnail_Png.png' 
    },{ 
      docName: 'Template Letter to Customers', 
      docLink: 'https://stayprivate.com/documentation/Template-Letter-to-Customers.docx',
      imageUrl: 'https://stayprivate.com/resources/Resources_Thumbnail_Doc.png' 
    },{ 
      docName: 'Template Sales Email', 
      docLink: 'https://stayprivate.com/documentation/Template-Sales-Email.docx',
      imageUrl: 'https://stayprivate.com/resources/Resources_Thumbnail_Doc.png' 
    },{ 
      docName: 'Template Telephone Sales Pitch', 
      docLink: 'https://stayprivate.com/documentation/Template-Telephone-Sales-Pitch.docx',
      imageUrl: 'https://stayprivate.com/resources/Resources_Thumbnail_Doc.png' 
    },{ 
      docName: 'StayPrivate Internal Sales Team Presentation', 
      docLink: 'https://stayprivate.com/documentation/StayPrivate-Internal-Sales-Team-Presentation.pptx',
      imageUrl: '../../../../assets/images/Resources_Thumbnail_PPT.png' 
    },
  ];