<mat-card>
    <mat-tab-group id="tabGroup" #tabGroup class="invis-header">
        <mat-tab label="Notifications">
            <mat-card-title class="title-padding sp-title">
                Notification History
                <!-- <button mat-flat-button class="sp-background edit-button" color="primary"
                >Preferences</button> -->
                <button mat-icon-button style="float: right;" color="primary" (click)="goToPref()">
                    <span class="material-icons-outlined sp-title">edit_notifications</span>
                </button>
            </mat-card-title>
            <p style="padding-left: 10px;">Your notification history is listed as individual items, starting with the most recent</p>
            <div class="notification-header">
                <!-- <input type="text" placeholder="Search here..."  [(ngModel)]="searchTerm"(keyup)="filter()" class="search"> -->
                <div class="input-box">
                    <span class="prefix material-icons-outlined">search</span>
                    <input type="tel" placeholder="Search Notifications..." [(ngModel)]="searchTerm"
                        (keyup)="filter()" />
                </div>
                <div class="filter-select">
                    <mat-select [formControl]="types" multiple disableOptionCentering panelClass="myPanelClass"
                        (selectionChange)="filter()">
                        <mat-select-trigger>
                            <span style="color:#999"> <span class="filter-icon">filter_list</span> Filter </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let sortType of sortTypes" [value]="sortType">
                            {{sortType}}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
            <mat-spinner *ngIf="loading" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>
            <div *ngIf="notificationsList[0]!=null && !loading">
                <div *ngFor="let day of notificationsList">

                    <!-- <mat-accordion>
                        <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                            <mat-expansion-panel-header style="height: 40px;     display: inline-flex;">
                                <mat-panel-title style="display: block;">
                                    <mat-card-subtitle style="padding-left: 10px; padding-top: 20px; display: flex;"
                                        class="sp-title">
                                        {{day.date}}
                                    </mat-card-subtitle>
                                </mat-panel-title>
                            </mat-expansion-panel-header> -->
                            <button type="button" class="date-collapsible" (click)="collapseDate('d'+day.date+i)">
                                <mat-card-subtitle style="margin: 0px; display: flex;align-items: center;" class="sp-title">
                                    {{day.date}}
                                    <mat-icon [id]="'id'+day.date+i"  class="notif-expand-icon notif-rotate">chevron_right
                                    </mat-icon>

                                </mat-card-subtitle>
                            </button>
                            <div class="date-content" [id]="'d'+day.date+i" (click)="consoleL(this.this)" >
                                
                                <div style="display: flex;align-items: center; margin:10px 0px;"
                                *ngFor="let notification of day.notifis;let i=index;">
                                <div class="icon-border" style="border: 2px solid #f44336;"
                                    *ngIf="notification.Type=='Usage Exceeded'">
                                    <mat-icon class="icon-image" style="color:#f44336; ">trending_up</mat-icon>
                                </div>
                                <div class="icon-border" style="border: 2px solid #FFB600;"
                                    *ngIf="notification.Type=='Company Added'">
                                    <mat-icon class="icon-image" style="color:#FFB600; ">add_business</mat-icon>
                                </div>
                                <div class="icon-border" style="border: 2px solid #8CD867;"
                                    *ngIf="notification.Type=='Company Provisioned'">
                                    <mat-icon class="icon-image" style="color:#8CD867; ">check_circle</mat-icon>
                                </div>
                                <div class="icon-border" style="border: 2px solid #407ba6;"
                                    *ngIf="notification.Type=='Logo Uploaded'">
                                    <mat-icon class="icon-image" style="color:#407ba6; ">add_photo_alternate</mat-icon>
                                </div>
                                <div class="icon-border" style="border: 2px solid #407ba6;"
                                    *ngIf="notification.Type=='Branding Added'">
                                    <mat-icon class="icon-image" style="color:#407ba6; ">image</mat-icon>
                                </div>
                                <div class="icon-border" style="border: 2px solid #407ba6;"
                                    *ngIf="notification.Type=='Branding Removed'">
                                    <mat-icon class="icon-image" style="color:#407ba6; ">hide_image</mat-icon>
                                </div>
                                <div class="icon-border" style="border: 2px solid #407ba6;"
                                    *ngIf="notification.Type=='User Count Increased'">
                                    <mat-icon class="icon-image" style="color:#407ba6; ">group_add</mat-icon> 
                                </div>
                                <div class="icon-border" style="border: 2px solid #407ba6;"
                                    *ngIf="notification.Type=='User Count Decreased'">
                                    <mat-icon class="icon-image" style="color:#407ba6; ">group_remove</mat-icon>
                                </div>
                                <div class="notif-holder">
                                    <button type="button" class="collapsible"
                                        (click)="collapse(day.date+i,notification)"
                                        [ngClass]="(notification.Opened)? 'notif-closed':''">
                                        <mat-card-subtitle style="margin: 0px; display: flex;     align-items: center;">
                                            <span class="notifcation-text"
                                                style="width: 15%;">{{notification.Type}}</span>
                                            <span class="notifcation-text"
                                                style="width: 10%;">{{timeConvert(notification.NotificationDateUTC)}}</span>
                                            <span class="notifcation-text">{{notification.Message}}</span>
                                            <mat-icon [id]="'i'+i" class="notif-expand-icon"
                                                [ngClass]="(notification.Opened)? 'notif-rotate':''">chevron_right
                                            </mat-icon>

                                        </mat-card-subtitle>

                                    </button>
                                    <div class="content" [id]="day.date+i" *ngIf="notification.Type!='Usage Exceeded'">
                                        <div style="padding: 20px 0px 20px 15px;">Company Hierarchy:
                                            <span *ngFor="let name of notification.Hierarchy; let i = index">
                                                <a [routerLink]="['/detail',name.CompanyId]">{{name.CompanyName}}</a>
                                                <span *ngIf="i!=(notification.Hierarchy.length-1)"> > </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="content" [id]="day.date+i" *ngIf="notification.Type=='Usage Exceeded'">
                                        <div style="padding: 20px 0px 20px 15px;">
                                            <table style="width: 100%;">
                                                <tr *ngIf="notification.NewLimit.RecipientLimit">
                                                    <td>Previous recipient allowance:
                                                        {{notification.NewLimit.RecipientLimitOld}}</td>
                                                    <td> New recipient allowance:
                                                        {{notification.NewLimit.RecipientLimit}}</td>
                                                </tr>
                                                <tr *ngIf="notification.NewLimit.StorageLimit">
                                                    <td>Previous storage allowance:
                                                        {{notification.NewLimit.StorageLimitOld}}GB</td>
                                                    <td>New storage allowance: {{notification.NewLimit.StorageLimit}}GB
                                                    </td>
                                                </tr>
                                                <tr *ngIf="notification.NewPrice">
                                                    <td>New billing amount: {{notification.NewPrice}}</td>
                                                </tr>
                                            </table>
                                            Company Hierarchy:
                                            <span *ngFor="let name of notification.Hierarchy; let i = index">
                                                <a [routerLink]="['/detail',name.CompanyId]">{{name.CompanyName}}</a>
                                                <span *ngIf="i!=(notification.Hierarchy.length-1)"> > </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            </div>

                </div>

            </div>




            <div *ngIf="notificationsList[0]==null && !loading" class="notification-body" style="height: 420px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;">
                <img src="../../../assets/images/noNotificationBell.svg" alt=""
                    style="display: flex; width: 150px; padding: 30px 0px;">
                <mat-card-title class="sp-title" style="display: flex; justify-content: center; align-items: center"
                    *ngIf="notifications.length>=0">No
                    notifications found!</mat-card-title>
                <mat-card-title class="sp-title" style="display: flex; justify-content: center; align-items: center"
                    *ngIf="notifications.length==0">No
                    notifications yet!</mat-card-title>
                <span style="display: flex; justify-content: center; align-items: center; color:#999"
                    *ngIf="notifications.length==0">All your
                    notifications will be listed here</span>
            </div>
        </mat-tab>
        <mat-tab label="Preferences">
            <ng-template matTabContent>
                <app-preferences [notificationTab]="notificationTab"></app-preferences>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-card>