<mat-spinner *ngIf="!dataSource" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>

<table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>
    
    <ng-container matColumnDef="more" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button class="edit-button" mat-icon-button (click)="download(element.fileKey)"><mat-icon>download</mat-icon></button>
        </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
 