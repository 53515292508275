<mat-card class="form-padding">
    <mat-card-content >            
        <!-- Company Name Field -->
        <div class="field">
          <mat-label>JWT Token Example</mat-label> 
          <mat-form-field appearance="outline" class="max-width">  
              <input class="text-padding" matInput placeholder="Enter or generate a valid JWT" [(ngModel)]="token">          
          </mat-form-field>                  
        </div>   
        <button class="edit-button" mat-icon-button (click)="authenticate()" aria-label="Download CSV button" >
            <mat-icon class="info-icon">wifi_protected_setup</mat-icon>
        </button> 
        <div class="field">
          <mat-label>Public Key Endpoint</mat-label> 
          <mat-form-field appearance="outline" class="max-width">  
              <input class="text-padding" matInput placeholder="Enter your hosted public key" [(ngModel)]="token">          
          </mat-form-field>                  
        </div> 
        <br>
        <div class="field">
          <mat-label>Verify JWT</mat-label> 
          <button class="edit-button" mat-icon-button (click)="verify()" aria-label="Download CSV button" >
            <mat-icon class="info-icon">login</mat-icon>
          </button>
          <span *ngIf="payload">
            <br>Token is verified!
            <br>Here is the data:
            <br>{{payload}}
          </span>
          <span *ngIf="error">
            <mat-error>
              Token could not be verified. Please try again.
            </mat-error>
          </span>                   
        </div>   
        <div class="field">
          
            
        </div>
          
    </mat-card-content>
</mat-card>

