<h1 mat-dialog-title>Add New User</h1>
<div mat-dialog-content>
  <form [formGroup]="addUserForm" class="company-form">

    

    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Name</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input placeholder="John Doe" formControlName="name" autocomplete="off" autocomplete="off" class="text-padding" matInput>
        <mat-error *ngIf="addUserForm.controls['name'].hasError('required')">
          A name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Email Address</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input type="email" placeholder="john@examplecompany.com" formControlName="emailAddress" autocomplete="off"
          class="text-padding" matInput>
        <mat-error *ngIf="addUserForm.controls['emailAddress'].hasError('required')">
          A email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="addUserForm.controls['emailAddress'].hasError('pattern')">
          Please provide a <strong>valid</strong> email address
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Password</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" autocomplete="off" placeholder="************"
          class="text-padding" matInput>
        <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" class="field-icon-adj"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="addUserForm.controls['password'].hasError('required')">
          A password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="addUserForm.controls['password'].hasError('pattern')">
          Requires both upper and lower case letters and at least one number
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Confirm Password</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input [type]="hidePasswordConfirm ? 'password' : 'text'" matchingValidator="password" placeholder="************"
          formControlName="passwordConfirm" autocomplete="off" class="text-padding" matInput>
        <button type="button" mat-icon-button matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm" class="field-icon-adj"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirm">
          <mat-icon>{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="addUserForm.controls['passwordConfirm'].hasError('required')">
          A password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="addUserForm.controls['passwordConfirm'].hasError('notEqual')">
          Passwords must match
        </mat-error>
      </mat-form-field>
    </div>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button class="cancel-button sp-back-btn" (click)="onNoClick()">Cancel</button>
  <button *ngIf="!addUserForm.valid" mat-flat-button class="ok-button sp-disabled" disabled (click)="onSubmit()"
    cdkFocusInitial>OK</button>
  <button *ngIf="addUserForm.valid" color="primary" mat-flat-button class="ok-button sp-background" (click)="onSubmit()"
    cdkFocusInitial>OK</button>

</div>