<mat-horizontal-stepper [linear]="false" #setupStepper class="invis-stepper">
  <mat-step>
    <span class="subtitle">
      Direct Configuration
    </span>
      <div class="wrapper">
        <div class="arrow-steps clearfix">
          <div class="step"[ngClass]="(spfStepper.selectedIndex >= 0)? 'current':''"> <span>Access</span> </div>
          <div class="step"[ngClass]="(spfStepper.selectedIndex >= 1)? 'current':''"> <span>Outbound</span> </div>
          <div class="step"[ngClass]="(spfStepper.selectedIndex >= 2)? 'current':''"> <span>Replies</span> </div>
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 3)? 'current':''"> <span>Enable</span> </div>
        </div>
      </div>
    <mat-horizontal-stepper #spfStepper class="invis-stepper" labelPosition="bottom"
      style="max-width: 750px; margin: auto;">
      <mat-step label="Access">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Access the Mail Server
        </span>

        <div class="list" style="margin-bottom: 30px;">
          <span>You will require administrator access to your email server in order to configure it to work with
            StayPrivate.</span>
        </div>
        <div class="link-box" style="margin-bottom: 20px;">
          <div class="link-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
          </div>
          <span class="link-text">
            If you do not have administrator access to the email server, please ask the administrator to complete the configuration
            using the following link:
            <a target="_blank" [routerLink]="['/directsetup']" [queryParams]="{'1': link}">
              https://dashboard.stayprivate.com/directsetup<span *ngIf="link">?1=</span>{{link}}</a>
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.originStepper.selectedIndex = 0" *ngIf="this.originStepper"
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Outbound">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Create an outbound connector to StayPrivate
        </span>
        <div class="list">
          <span>To connect with StayPrivate, outgoing secure emails need to be identified and relayed to <span
              class="sp-copy"
              (click)="copy('https://sendsecure.stayprivate.com')">https://sendsecure.stayprivate.com</span>. For most
            email servers this means:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>adding <span class="sp-copy"
                (click)="copy('https://sendsecure.stayprivate.com')">https://sendsecure.stayprivate.com</span> as a host
              or connector; and</li>
            <li>creating a conditional routing rule to route emails to this host if the email subject or body contains
              the keyword.</li>
          </ul>
          <span>
            For detailed instructions of how to do this for Microsoft email servers, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 1">here</a>.
          </span><br><br>
          <span>
            For detailed instructions of how to do this in Google Workspace, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 2">here</a>.
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Replies">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Forward secure replies to StayPrivate
        </span>
        <div class="list">
          <span>To ensure that secure replies from secure corporate domains are included in
            StayPrivate, incoming secure replies should
            be identified and blind copied to <span class="sp-copy"
              (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span>. For most email servers
            this is achieved by adding a compliance rule to add the above address to the 'bcc' field if an incoming
            email contains the text:<span class="sp-copy" (click)="copy('#stayprivate-secure-reply')">#stayprivate-secure-reply</span>.
          </span>
          <br><br>
          <span>
            For detailed instructions of how to do this for Microsoft email servers, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 1">here</a>.
          </span><br><br>
          <span>
            For detailed instructions of how to do this in Google Workspace, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 2">here</a>.
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              *ngIf="this.originStepper" (click)="finish(false)">Finish</button>
          </div>
        </div>
      </mat-step>
      <ng-template matStepperIcon="edit">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="12px" y="12px" viewBox="0 0 488 500" style="width: 14px;" xml:space="preserve">
          <g>
            <path style="fill: #FFFFFF;" d="M485.95,258.2L245.86,498.29c-0.98,0.98-2.56,0.98-3.54,0L2.23,258.2c-0.47-0.47-0.73-1.1-0.73-1.77V44.78
                  c0-2.23,2.69-3.34,4.27-1.77l236.56,236.56c0.98,0.98,2.56,0.98,3.54,0L482.41,43.01c1.57-1.57,4.27-0.46,4.27,1.77v211.65
                  C486.68,257.09,486.42,257.73,485.95,258.2z" />
            <path style="fill: #FFFFFF;" d="M443.57,1.33H44.61c-2.23,0-3.34,2.69-1.77,4.27l199.48,199.48c0.98,0.98,2.56,0.98,3.54,0L445.34,5.6
                  C446.91,4.02,445.8,1.33,443.57,1.33z" />
          </g>
        </svg>
      </ng-template>
    </mat-horizontal-stepper>
  </mat-step>
  <mat-step>
    <span class="subtitle">
      Direct Configuration for Microsoft
    </span>
    <div class="wrapper">
      <div class="arrow-steps clearfix">
        <div class="step"[ngClass]="(microsoftStepper.selectedIndex >= 0)? 'current':''"> <span>Access</span> </div>
        <div class="step"[ngClass]="(microsoftStepper.selectedIndex >= 1)? 'current':''"> <span>Outbound</span> </div>
        <div class="step"[ngClass]="(microsoftStepper.selectedIndex >= 2)? 'current':''"> <span>Rules</span> </div>
        <div class="step"[ngClass]="(microsoftStepper.selectedIndex >= 3)? 'current':''"> <span>Replies</span> </div>
      </div>
    </div>
    <mat-horizontal-stepper #microsoftStepper class="invis-stepper" labelPosition="bottom"
      style="max-width: 750px; margin: auto;">
      <mat-step label="Access">

        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Access the Exchange Admin area
        </span>

        <div class="list" style="margin-bottom: 30px;">
          <span>You will require administrator access to your Microsoft 365 account or mail server in order to configure
            it to work with StayPrivate:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>From the Office 365 home page, in the left-hand menu, select 'Admin'.</li>
            <li>In the left-hand menu, select 'Show all'.</li>
            <li>Scroll down and under 'Admin centers' select 'Exchange'.</li>
          </ul>
          <span>We recommend that you use the latest version of the Microsoft Exchange Admin Center. If you are using
            the 'Classic' Exchange Admin Center you can still follow the instructions below, but please be aware that
            there are one or two slight differences in wording and layout.</span>
        </div>
        <div class="link-box" style="margin-bottom: 20px;">
          <div class="link-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
          </div>
          <span class="link-text">
            If you do not have administrator access to the email server, please ask the email administrator to complete the configuration
            using the following link:
            <a target="_blank" [routerLink]="['/directsetup']" [queryParams]="{'1': link}">
              https://dashboard.stayprivate.com/directsetup<span *ngIf="link">?1=</span>{{link}}</a>
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px;">
            <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.setupStepper.selectedIndex = 0"
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Outbound">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Add an outbound connector to StayPrivate
        </span>
        <div class="list">
          <span>The first step is to add a secure connector to the StayPrivate relay server:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>In the left-hand menu, select 'Mail flow'. Then select 'Connectors'.</li>
            <li>Click on '+ Add a connector'.</li>
            <li>Under 'Connection from' select 'Office 365'. Under 'Connection to' select 'Partner organization'. Then click 'Next'.</li>
            <li>Enter the name: <span class="sp-copy" (click)="copy('Send to StayPrivate')">Send to StayPrivate</span>
              and ensure 'Turn it on' is selected. Then click 'Next'.</li>
            <li>Select 'Only when I have a transport rule set up that redirects messages to this connector'. Then click
              'Next'.</li>
            <li>Select 'Route email through these smart hosts'. Then enter the domain name: <span class="sp-copy"
                (click)="copy('sendsecure.stayprivate.com')">sendsecure.stayprivate.com</span> and click '+'. Then Click 'Next'.</li>
            <li>Ensure that 'Always use Transport Layer Security...' and 'Issued by a trusted certificate authority
              (CA)' are selected. Click 'Next'.</li>
            <li>To validate the connector, enter the test email address <span class="sp-copy"
                (click)="copy('support@stayprivate.com')">support@stayprivate.com</span> and click '+'.</li>
            <li>Click 'Validate'. Office 365 will validate the new connector. This may take a minute or so. When it has
              finished, click 'Next'.</li>
            <li>Click 'Create connector'.</li>
            <li>Click 'Done'.</li>
          </ul>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Rules">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Add the StayPrivate rule for outbound emails.
        </span>
        <div class="list">
          <span>The next step is to add a rule so that the mail server can identify which emails to send to
            StayPrivate:
          </span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>In 'Mail flow', select 'Rules'.</li>
            <li>Select '+ Add a rule' and 'Create a new rule'.</li>
            <li>Enter the name: <span class="sp-copy"
                (click)="copy('Identify messages to send via StayPrivate')">Identify messages to send via
                StayPrivate</span></li>
            <li>Under 'Apply this rule if' select 'The recipient'.</li>
            <li>Then, to the right, select 'is external/internal'. Then choose 'Outside the organization' and click 'Save'.</li>
            <li>Under 'Do the following' select 'Redirect the message to'. Then, to the right, select 'the following connector'. Select 'Send to StayPrivate' and click 'Save'.</li>
            <li>Under 'Except if' select 'The sender'. Then, to the right, select 'IP address is in any of these
              ranges or exactly matches'. Enter <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span>
              in the box.</li>
            <li>Click 'Add'.</li>
            <li>Tick the box next to 18.130.40.2. Click 'Save'.</li>
            <li>Click 'Next'.</li>
            <li>Ensure that 'Enforce' is selected, then click 'Next'.</li>
            <li>Click 'Finish'. It may take a few seconds for the rule to be saved.</li>
            <li>Click 'Done'.</li>
          </ul>
          <span>Next add a rule to ensure that secure emails are not incorrectly identified as spam:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>In 'Mail flow', select 'Rules'.</li>
            <li>Select '+ Add a rule' and then 'Bypass spam filtering'.</li>
            <li>Enter the name: <span class="sp-copy" (click)="copy('StayPrivate Allow')">StayPrivate Allow</span>
            </li>
            <li>Under 'Apply this rule if' select 'The sender'. Then, to the right, select 'IP address is in any of
              these ranges or exactly matches'. Enter <span class="sp-copy"
                (click)="copy('18.130.40.2')">18.130.40.2</span> in the
              box. Then click 'Add'.</li>
            <li>Tick the box next to 18.130.40.2. Then click 'Save'.</li>
            <li>Click 'Next'.</li>
            <li>Ensure that 'Enforce' is selected, then click 'Next'.</li>
            <li>Click 'Finish'. It may take a few seconds for the rule to be saved.</li>
            <li>Click 'Done'.</li>
          </ul>
        </div>

        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Replies">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Add a rule to ensure inbound secure emails are copied to StayPrivate
        </span>
        <div class="list">
          <span>To ensure that direct replies from secure corporate domains are included in StayPrivate, incoming secure
            replies are identified and blind copied to <span class="sp-copy"
              (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span>:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>In the left-hand menu, select 'Recipients' then select 'Contacts'.</li>
            <li>Select 'Add a mail contact'.</li>
            <li>Under Contact type select 'Mail contact'.</li>
            <li>Under 'Display name' enter: <span class="sp-copy"
                (click)="copy('StayPrivateServer')">StayPrivateServer</span></li>
            <li>Under 'Alias' enter: <span class="sp-copy"
                (click)="copy('StayPrivateServer')">StayPrivateServer</span></li>
            <li>Under 'External email address' enter: <span class="sp-copy"
                (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span></li>
            <li>Click 'Next'.</li>
            <li>Click 'Next' again, then click 'Create'.</li>
            <li>Click 'Done'.</li>
          </ul>
          <span>Now add a rule to ensure that secure emails are copied to server@stayprivatemail.com:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>In the left-hand menu, select 'Mail flow' and select 'Rules'.</li>
            <li>Select '+ Add a rule' and 'Create a new rule'.</li>
            <li>Enter the name: <span class="sp-copy" (click)="copy('Send secure replies to StayPrivate')">Send secure
                replies to StayPrivate</span></li>
            <li>Under 'Apply this rule if' select 'The subject or body'.</li>
            <li>Then select 'subject or body matches these text patterns' and enter <span class="sp-copy"
              (click)="copy('#stayprivate-secure-reply')">#stayprivate-secure-reply</span>.</li>
            <li>Click 'Add'.</li>
            <li>Tick the box next to #stayprivate-secure-repl. Then click 'Save'.</li>
            <li>Under 'Do the following' select 'Add Recipients' then, to the right, select 'to the Bcc box'.</li>
            <li>Find 'StayPrivateServer' in the list and tick the box next to it. Click 'Save'.</li>
            <li *ngIf="!domain">Under 'Except if' select 'The sender' then, to the right, select 'domain is'. Enter
              your email domain (for example, mycompany.com) in the box.</li>
            <li *ngIf="domain">Under 'Except if' select 'The sender' then, to the right, select 'domain is'. Enter
              <span class="sp-copy" (click)="copy(domain)">{{domain}}</span> in the box.</li>
            <li *ngIf="!domain">Tick the box next to your domain. Then click 'Save'.</li>
            <li *ngIf="domain">Tick the box next to {{domain}}. Then click 'Save'.</li>
            <li>Click the blue '+' next to the box with the text 'domain is'.</li>
            <li>Under 'And' select 'The sender'. Then, to the right, select 'IP address is in any of these ranges or
              exactly matches'.</li>
            <li>Enter <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span> in the box. Then click
              'Add'.</li>
            <li>Tick the box next to 18.130.40.2. Then click 'Save'.</li>
            <li>Click 'Next'.</li>
            <li>Check that 'Enforce' is selected, then click 'Next'.</li>
            <li>Click 'Finish'. It may take a few seconds for the rule to be saved.</li>
            <li>Click 'Done'.</li>
          </ul>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Enable">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px; ">
          Enable the rules
        </span>
        <div class="list">
          <span>The final step is simply to ensure that the rules set up above are enabled.</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>In the left-hand menu, select 'Rules'.</li>
            <li>Find the rule 'Identify messages to send via StayPrivate'. Click on the word 'Disabled' next to the rule.</li>
            <li>Click on the switch under 'Enable or disable rule' to enable the rule.</li>
            <li>Click on the close button top right. It will take a few seconds for the page to update.</li>
            <li>Find the rule 'Send secure replies to StayPrivate'. Click on the word 'Disabled' next to the rule.</li>
            <li>Click on the switch under 'Enable or disable rule' to enable the rule.</li>
            <li>Click on the close button top right. It will take a few seconds for the page to update.</li>
          </ul>
          <span>That is it! You can start using StayPrivate right away.</span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              *ngIf="this.originStepper" (click)="finish()">Finish</button>
          </div>
        </div>
      </mat-step>
      <ng-template matStepperIcon="edit">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="12px" y="12px" viewBox="0 0 488 500" style="width: 14px;" xml:space="preserve">
          <g>
            <path style="fill: #FFFFFF;" d="M485.95,258.2L245.86,498.29c-0.98,0.98-2.56,0.98-3.54,0L2.23,258.2c-0.47-0.47-0.73-1.1-0.73-1.77V44.78
                c0-2.23,2.69-3.34,4.27-1.77l236.56,236.56c0.98,0.98,2.56,0.98,3.54,0L482.41,43.01c1.57-1.57,4.27-0.46,4.27,1.77v211.65
                C486.68,257.09,486.42,257.73,485.95,258.2z" />
            <path style="fill: #FFFFFF;" d="M443.57,1.33H44.61c-2.23,0-3.34,2.69-1.77,4.27l199.48,199.48c0.98,0.98,2.56,0.98,3.54,0L445.34,5.6
                C446.91,4.02,445.8,1.33,443.57,1.33z" />
          </g>
        </svg>
      </ng-template>
    </mat-horizontal-stepper>
  </mat-step>
  <mat-step>
    <span class="subtitle">
      Direct Configuration for Google
    </span>
    <div class="wrapper">
      <div class="arrow-steps clearfix">
        <div class="step"[ngClass]="(googleStepper.selectedIndex >= 0)? 'current':''"> <span>Access</span> </div>
        <div class="step"[ngClass]="(googleStepper.selectedIndex >= 1)? 'current':''"> <span>Hosts</span> </div>
        <div class="step"[ngClass]="(googleStepper.selectedIndex >= 2)? 'current':''"> <span>Rules 1</span> </div>
        <div class="step"[ngClass]="(googleStepper.selectedIndex >= 3)? 'current':''"> <span>Rules 2</span> </div>
        <div class="step"[ngClass]="(googleStepper.selectedIndex >= 4)? 'current':''"> <span>Inbound</span> </div>
        <div class="step"[ngClass]="(googleStepper.selectedIndex >= 5)? 'current':''"> <span>Replies</span> </div>
      </div>
    </div>
    <mat-horizontal-stepper #googleStepper class="invis-stepper" labelPosition="bottom"
      style="max-width: 750px; margin: auto;">
      <mat-step label="Access">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Access the Google Admin area
        </span>
        <div class="list" style="margin-bottom: 30px;">
          <span>You will require administrator access to your Google mail server in order to configure it to work with
            StayPrivate:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>Log into the Google Admin console at: <a target="_blank"
                href="https://admin.google.com ">https://admin.google.com</a></li>
            <li>Select '<img src="//www.gstatic.com/apps/cpanel/resources/img/apps-hub-48.png">
              Apps'.</li>
            <li>Select 'Google Workspace' and from the list, click on '<img
                src="https://ssl.gstatic.com/images/branding/product/1x/hh_gmail_128dp.png"> Gmail'.</li>
          </ul>
          <div class="link-box" style="margin-bottom: 20px;">
            <div class="link-svg">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
            </div>
            <span class="link-text">
              If you do not have administrator access to the email server, please ask the email administrator to complete the configuration
              using the following link:
              <a target="_blank" [routerLink]="['/directsetup']" [queryParams]="{'1': link}">
                https://dashboard.stayprivate.com/directsetup<span *ngIf="link">?1=</span>{{link}}</a>
            </span>
          </div>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.setupStepper.selectedIndex = 0"
              style="float: left;">Back</button>

            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Hosts">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Add the StayPrivate secure server as a host
        </span>
        <div class="list">
          <span>To send secure emails, StayPrivate needs to be first added as a host:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>Click on 'Hosts'.</li>
            <li>Click on 'ADD ROUTE' to add a new mail route.</li>
            <li>Enter the name: <span class="sp-copy" (click)="copy('StayPrivate')">StayPrivate</span></li>
            <li>In the 'Enter host name or IP' field enter <span class="sp-copy"
                (click)="copy('sendsecure.stayprivate.com')">sendsecure.stayprivate.com</span> and in the numeric field
              next to it, enter the port number: <span class="sp-copy" (click)="copy('587')">587</span></li>
            <li>Click 'Save'.</li>
          </ul>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Rules 1">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Add the StayPrivate rule for outbound emails
        </span>
        <div class="list">
          <span>The next step is to add two rules so that the email server can identify which emails to send via the StayPrivate host:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>Go back to the Gmail settings page by clicking on 'Settings for Gmail' near the top.</li>
            <li>Scroll down to near the bottom and click on 'Compliance'. Then scroll down to 'Content compliance' and click 'CONFIGURE' (or if you already have a rule specified, click 'ADD ANOTHER RULE').</li>
            <li>Scroll down to 'Content compliance' and click 'CONFIGURE' (or if you already have a rule specified, click 'ADD ANOTHER RULE').</li>
            <li>Under 'Content compliance' enter the text: <span class="sp-copy" (click)="copy('Identify messages to send via StayPrivate')">Identify messages to send via StayPrivate'</span></li>
            <li>Under '1. Email messages to affect' select 'Outbound'.</li>
            <li>Under '2. Add expressions that describe...' select 'If ALL of the following match the message'. Then click on 'ADD'.</li>
            <li>Click on 'Simple content match' and select 'Advanced content match'. Then under 'Location' select 'Full Headers'.</li>
            <li>Under 'Match type' select 'Not contains text'.</li>
            <li>Under 'Content' enter  the text: <span class="sp-copy" (click)="copy('x-stayprivate-processed: true')">x-stayprivate-processed: true</span>.</li>      
            <li>Click 'SAVE'.</li>
            <li>In '3. If the above expressions match, do the following' under 'Route' select 'Change route'.</li>
            <li>Click on 'Normal routing' and select 'StayPrivate'.</li>
            <li>Then under 'Spam' select 'Bypass the spam filter for this message'.</li>
            <li>Click on 'SAVE' bottom right.</li>
          </ul>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Replies">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Add a rule to ensure secure replies are handled by StayPrivate
        </span>
        <div class="list">
          <span>To ensure that direct replies from secure corporate domains are included in StayPrivate, incoming secure
            replies are identified and blind copied to <span class="sp-copy"
              (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span>. This is achieved by adding
            a further compliance rule:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>Go to the Gmail settings page by clicking on 'Settings for Gmail' near the top.</li>
            <li>Scroll down to near the bottom and select 'Compliance'. Under 'Content compliance' click 'ADD ANOTHER RULE'.</li>
            <li>Under 'Content compliance' enter: <span class="sp-copy" (click)="copy('Send secure replies to StayPrivate')">Send secure replies to StayPrivate</span></li>
            <li>Under '1. Email messages to affect' select 'Inbound'.</li>
            <li>Under '2. Add expressions that...' select 'ADD'.</li>
            <li>Click on 'Simple content match' and select 'Advanced content match'. Then under 'Location' select 'Body'..</li>
            <li>Under 'Content' enter  <span class="sp-copy" (click)="copy('#stayprivate-secure-reply')">#stayprivate-secure-reply</span> then click 'SAVE'.</li>
            <li>In '3. If the above expressions match, do the following' under 'Route' select 'Modify message'.</li>
            <li>Under 'Also deliver to' select 'Add more recipients' then select 'ADD'.</li>
            <li>Under 'Recipient address:' enter: <span class="sp-copy" (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span></li>
            <li>Select 'SAVE'.</li>
            <li>Finally, click on 'SAVE' bottom right. This is important - otherwise your changes may be lost.</li>
          </ul>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              *ngIf="this.originStepper" (click)="finish()">Finish</button>
          </div>
        </div>
      </mat-step>
      <ng-template matStepperIcon="edit">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="12px" y="12px" viewBox="0 0 488 500" style="width: 14px;" xml:space="preserve">
          <g>
            <path style="fill: #FFFFFF;" d="M485.95,258.2L245.86,498.29c-0.98,0.98-2.56,0.98-3.54,0L2.23,258.2c-0.47-0.47-0.73-1.1-0.73-1.77V44.78
                    c0-2.23,2.69-3.34,4.27-1.77l236.56,236.56c0.98,0.98,2.56,0.98,3.54,0L482.41,43.01c1.57-1.57,4.27-0.46,4.27,1.77v211.65
                    C486.68,257.09,486.42,257.73,485.95,258.2z" />
            <path style="fill: #FFFFFF;" d="M443.57,1.33H44.61c-2.23,0-3.34,2.69-1.77,4.27l199.48,199.48c0.98,0.98,2.56,0.98,3.54,0L445.34,5.6
                    C446.91,4.02,445.8,1.33,443.57,1.33z" />
          </g>
        </svg>
      </ng-template>
    </mat-horizontal-stepper>
  </mat-step>
</mat-horizontal-stepper>