import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyDetailComponent } from './components/company-detail/company-detail.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { EditThemeComponent } from './components/edit-theme/edit-theme.component';
import { AuthGuard } from './components/login/auth.guard';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NavigationComponent } from './navigation/navigation.component';
import { CompanySetupProcessComponent } from './components/company-setup-process/company-setup-process.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DeveloperComponent } from './components/developer/developer.component';
import { CompanySetupComponent } from './components/company-setup/company-setup.component';
import { CompanySetupPageComponent } from './components/company-setup-page/company-setup-page.component';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { Error404Component } from './components/error404/error404.component';
import { BillingComponent } from './components/billing/billing.component';
import { AppResolverService } from './app-resolver.service';
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'microsoftsetup', component: CompanySetupComponent },
  { path: 'googlesetup', component: CompanySetupComponent },
  { path: 'standardsetup', component: CompanySetupComponent },
  { path: 'directsetup', component: CompanySetupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset', component: ResetPasswordComponent},
  { path: 'notfound', component: Error404Component},
  
  { path: '', component: NavigationComponent,canActivate:[AuthGuard] ,resolve:{data:AppResolverService}, children:[
    { path: 'detail/:id', component: CompanyDetailComponent,canActivate:[AuthGuard]  },
    { path: 'mycompany', component: MyCompanyComponent,canActivate:[AuthGuard]  },
    { path: 'setup', component: CompanySetupPageComponent,canActivate:[AuthGuard]  },
    { path: 'account', component: MyAccountComponent,canActivate:[AuthGuard]  },
    { path: 'home', component: HomeComponent,canActivate:[AuthGuard]  },
    { path: 'billing', component: BillingComponent,canActivate:[AuthGuard]  },
    { path: 'add-company', component: AddCompanyComponent ,canActivate:[AuthGuard] },
    { path: 'edit-theme', component: EditThemeComponent ,canActivate:[AuthGuard] },
    { path: 'resources', component: ResourcesComponent ,canActivate:[AuthGuard] },
    { path: 'contact-us', component: ContactUsComponent ,canActivate:[AuthGuard] },
    { path: 'developer', component: DeveloperComponent ,canActivate:[AuthGuard] },
    { path: 'notifications', component: NotificationsComponent ,canActivate:[AuthGuard] },
    { path: 'not-found', component: Error404Component ,canActivate:[AuthGuard] },
    { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
  ]},
  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }