<mat-card>
    <mat-card-title class="sp-title title-padding">
        My Account
        <div style="display: inline;">
            <button *ngIf="!editMode" mat-icon-button class="edit-button" (click)="editClicked()">
                <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="editMode&&detailsForm.dirty==true" mat-flat-button class="sp-background edit-button"
                color="primary" (click)="onSubmit()">Update Account</button>
            <button *ngIf="editMode&&detailsForm.dirty==false" mat-flat-button class="edit-button" [disabled]="true"
                (click)="onSubmit()">Update Account</button>
            <button *ngIf="editMode" mat-flat-button class="sp-background edit-button" color="primary"
                (click)="onCancel()">Cancel</button>
        </div>
    </mat-card-title>
    <mat-card-content style="padding-left: 10px; padding-top: 20px;">
        <form [formGroup]="detailsForm">
            <!-- Personal Name -->
            <div>
                <mat-label class="company-detail-tab company-detail-tab-active">Personal Name:
                </mat-label><br>
                <mat-form-field appearance="outline" class="field-width">
                    <input class="text-padding" matInput placeholder=""
                        formControlName="name">
                </mat-form-field>
            </div>
            <!-- User Name -->
            <div>
                <mat-label class="company-detail-tab"
                    [ngClass]="(editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">UserName:
                </mat-label><br>
                <mat-form-field appearance="outline" class="field-width">
                    <input class="text-padding" matInput placeholder=""
                        formControlName="username">
                </mat-form-field>
            </div>
            <!-- Email Address -->
            <div>
                <mat-label class="company-detail-tab"
                    [ngClass]="(editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Email address:
                </mat-label><br>
                <mat-form-field appearance="outline" class="field-width">
                    <input class="text-padding" matInput placeholder=""
                        formControlName="emailAddress">
                </mat-form-field>
            </div>
        </form>
        <button mat-flat-button color="primary" class="sp-background password-button"
            (click)="openEditPassword()">Change Password</button>
    </mat-card-content>
</mat-card>