import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-detail-developer',
  templateUrl: './detail-developer.component.html',
  styleUrls: ['./detail-developer.component.css']
})
export class DetailDeveloperComponent implements OnInit {

  constructor(private http: HttpClient, private globals:Globals) { }
  token:string;
  payload:string;
  error=false;
  ngOnInit(): void {
  }
  authenticate(){    
    this.http.get<any>(this.globals.getServicesUrl()+"jwt/getJWT").subscribe({
      next: data => {
        this.token=data.token;
      },
      error: error => {     
      }
    });
  }
  verify(){
    let param = new HttpParams();
    param = param.append('JWToken', this.token+'');    
    this.http.get<any>(this.globals.getServicesUrl()+"jwt/verifyJWT",{params:param}).subscribe({
      next: data => {
        this.error=false;
        this.payload=JSON.stringify(data.authData);
      },
      error: error => { 
        this.payload=null; 
        this.error=true;   
      }
    });
  }
}
