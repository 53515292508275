import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
interface SelectorType {
  value: string;
  viewValue: string;
  flag: string;
}
/*const currencies = [
  { type: "GBP",sign: "£", t100: { price:4, extraUsage: 5 }, t400: { price: 45, extraUsage: 11.25 }, tUnl: { price: 90, extraUsage: 22.50} },
  { type: "USD",sign: "$", t100: { price: 5, extraUsage: 6 }, t400: { price: 50, extraUsage: 12.50 }, tUnl: { price: 100, extraUsage: 25 } },
  { type: "AUD",sign: "$", t100: { price: 8, extraUsage: 10 }, t400: { price: 80, extraUsage: 20 }, tUnl: { price: 200, extraUsage: 50 } },
  { type: "EUR",sign: "€", t100: { price: 5, extraUsage: 6 }, t400: { price: 49, extraUsage: 12.25 }, tUnl: { price: 99, extraUsage: 24.75 } },
]*/
const currencies = [
  { type: "GBP", sign: "£", t100: { price: 4, extraUsage: 5 }, t200: { price: 15, extraUsage: 2, p1:2, p2:1.75, p3:1.50 , p4:1.35 , p5:1.25 }, t300: { price: 35, extraUsage: 4, p1:4, p2:3.5, p3:3 , p4:2.7 , p5:2.5} },
  { type: "USD", sign: "$", t100: { price: 5, extraUsage: 6 }, t200: { price: 17.50, extraUsage: 2.50, p1:2.5, p2:2.1, p3:1.8 , p4:1.6 , p5:1.5  }, t300: { price: 40, extraUsage: 5,p1:5, p2:4.2, p3:3.6 , p4:3.25 , p5:3 } },
  { type: "AUD", sign: "$", t100: { price: 8, extraUsage: 10 }, t200: { price: 30, extraUsage: 4,p1:4, p2:3.5, p3:3 , p4:2.7 , p5:2.5 }, t300: { price: 70, extraUsage: 8, p1:8, p2:7, p3:6 , p4:5.4 , p5:5   } },
  { type: "EUR", sign: "€", t100: { price: 5, extraUsage: 6 }, t200: { price: 18.75, extraUsage: 2.50,p1:2.25, p2:1.9, p3:1.65 , p4:1.5 , p5:1.4 }, t300: { price: 40, extraUsage: 4.50, p1:4.5, p2:3.85, p3:3.3 , p4:3 , p5:2.75 } },
]
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  constructor(private globals: Globals, private http: HttpClient, private router: Router) { }

  currencyTypes: SelectorType[] = [
    { value: 'GBP', viewValue: "£ GBP", flag: "../../../../assets/images/gb.svg" },
    { value: 'EUR', viewValue: "€ EUR", flag: "../../../../assets/images/eu.svg" },
    { value: 'USD', viewValue: "$ USD", flag: "../../../../assets/images/us.svg" },
    { value: 'AUD', viewValue: "$ AUD", flag: "../../../../assets/images/au.svg" },
  ];


  sliderval = 4
  incNumber = 5
  currentSeats = null;
  minLicense = 5;
  tierCost = 0
  loading1 = true;
  PriceA=0
  PriceA1=0
  PriceB=0
  PriceB1=0
  loading2 = true;
  subActive = false;
  branding: string;
  customerId: string;
  selectedTier = '';
  currentTier = '';
  details: any;
  currency = "GBP";
  currencyValues = currencies.find((element) => element.type === "GBP");
  async ngOnInit(): Promise<void> {

    let param = new HttpParams();
    param = param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('CompanyId', this.globals.getCompanyId());
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: async data => {
        this.branding = data.HasCustomLogoFlag
        this.loading1 = false
        let param1 = new HttpParams();
        param1 = param1.append('AuthToken', await this.globals.getAuthToken());
        param1 = param1.append('UserName', this.globals.getSystemUserName());
        param1 = param1.append('CompanyId', this.globals.getCompanyId());
        this.http.get<any>(this.globals.getServicesUrl() + 'stripe/getSub', { params: param1 }).subscribe({
          next: data2 => {
 console.log(data)

            if (data.InitialTier == "102") { this.selectedTier = '100' }
            else if (data.InitialTier == "103") { this.selectedTier = '100' }
            else if (data.InitialTier == "400") { this.selectedTier = '400' }
            else if (data.InitialTier == "401") { this.selectedTier = '400' }
            else if (data.InitialTier == "1000") { this.selectedTier = '1000' }
            else if (data.InitialTier == "1") { this.selectedTier = '' }
            else if (data.InitialTier == "2") { this.selectedTier = '' }
            else if (data.InitialTier == "3") { this.selectedTier = '' }
            else { this.selectedTier = '' }
            this.currencyValues = currencies.find((element) => element.type == data2.currency.toUpperCase())
            this.currency = data2.currency.toUpperCase();
            this.currentTier = data2.tier;
            this.customerId = data2.customer;
            this.details = data2.details;
            this.sliderval = data2.quantity;
            if (data2.quantity) {
              this.incNumber = data2.quantity;
              this.currentSeats = data2.quantity;
            }
            console.log(data2.subStatus)
            if (data2.subStatus == "active"||data2.subStatus == "trialing") {
              // this.selectedTier = null;
              this.subActive = true;
            } else {
              this.subActive = false;

              // this.currentTier=null
            }
            console.log(this.subActive)
            this.loading2 = false

          },
          error: error => {
            if (error.error.error == "No subscription data found") {
              this.loading2 = false

            } else if (error.error.error == "Could not find valid subscription") {
              this.customerId = error.error.customer;
              this.details = error.error.details;
              this.loading2 = false
            } else {
              console.error('There was an error!');
              console.error(error);

            }

          }
        });
      },
      error: error => { console.error('There was an error!'); }
    });
    console.log(this.currentTier)

    this.add(0)
    
  }
  add(val: number) {
    console.log('add')
    this.incNumber = this.incNumber + val
    if (this.selectedTier == '2' && this.incNumber < 5) {
      this.incNumber = 5
    }
    else if (this.selectedTier == '3' && this.incNumber < 10) {
      this.incNumber = 10
    }
    else if (this.currentTier == '2' && this.selectedTier == '' && this.incNumber < 5) {
      this.incNumber = 5
    }
    else if (this.currentTier == '3' && this.selectedTier == '' && this.incNumber < 10) {
      this.incNumber = 10
    }
    let tempAmount = this.incNumber
    this.PriceA = (this.incNumber*this.currencyValues.t200.p1)+this.currencyValues.t200.price
    if(tempAmount>5000){
      this.PriceA=3000*this.currencyValues.t200.p4+1500*this.currencyValues.t200.p3+400*this.currencyValues.t200.p2+100*this.currencyValues.t200.p1+((tempAmount-5000)*this.currencyValues.t200.p5)+this.currencyValues.t200.price
    }
    else if(tempAmount>2000){
      this.PriceA=1500*this.currencyValues.t200.p3+400*this.currencyValues.t200.p2+100*this.currencyValues.t200.p1+((tempAmount-2000)*this.currencyValues.t200.p4)+this.currencyValues.t200.price
    }
    else if(tempAmount>500){
      this.PriceA=400*this.currencyValues.t200.p2+100*this.currencyValues.t200.p1+((tempAmount-500)*this.currencyValues.t200.p3)+this.currencyValues.t200.price
    }
    else if(tempAmount>100){
      this.PriceA=100*this.currencyValues.t200.p1+((tempAmount-100)*this.currencyValues.t200.p2)+this.currencyValues.t200.price
    }
    else if(tempAmount>50){
      this.PriceA=50*this.currencyValues.t200.p1+((tempAmount-50)*this.currencyValues.t200.p1)+this.currencyValues.t200.price
    }

    tempAmount = this.currentSeats
    this.PriceA1 = (this.currentSeats*this.currencyValues.t200.p1)+this.currencyValues.t200.price
    if(tempAmount>5000){
      this.PriceA1=3000*this.currencyValues.t200.p4+1500*this.currencyValues.t200.p3+400*this.currencyValues.t200.p2+100*this.currencyValues.t200.p1+((tempAmount-5000)*this.currencyValues.t200.p5)+this.currencyValues.t200.price
    }
    else if(tempAmount>2000){
      this.PriceA1=1500*this.currencyValues.t200.p3+400*this.currencyValues.t200.p2+100*this.currencyValues.t200.p1+((tempAmount-2000)*this.currencyValues.t200.p4)+this.currencyValues.t200.price
    }
    else if(tempAmount>500){
      this.PriceA1=400*this.currencyValues.t200.p2+100*this.currencyValues.t200.p1+((tempAmount-500)*this.currencyValues.t200.p3)+this.currencyValues.t200.price
    }
    else if(tempAmount>100){
      this.PriceA1=100*this.currencyValues.t200.p1+((tempAmount-100)*this.currencyValues.t200.p2)+this.currencyValues.t200.price
    }
    else if(tempAmount>50){
      this.PriceA1=50*this.currencyValues.t200.p1+((tempAmount-50)*this.currencyValues.t200.p1)+this.currencyValues.t200.price
    }
    
    tempAmount = this.incNumber
    this.PriceB = (this.incNumber*this.currencyValues.t300.p1)+this.currencyValues.t300.price
    if(tempAmount>5000){
      this.PriceB=3000*this.currencyValues.t300.p4+1500*this.currencyValues.t300.p3+400*this.currencyValues.t300.p2+100*this.currencyValues.t300.p1+((tempAmount-5000)*this.currencyValues.t300.p5)+this.currencyValues.t300.price
    }
    else if(tempAmount>2000){
      this.PriceB=1500*this.currencyValues.t300.p3+400*this.currencyValues.t300.p2+100*this.currencyValues.t300.p1+((tempAmount-2000)*this.currencyValues.t300.p4)+this.currencyValues.t300.price
    }
    else if(tempAmount>500){
      this.PriceB=400*this.currencyValues.t300.p2+100*this.currencyValues.t300.p1+((tempAmount-500)*this.currencyValues.t300.p3)+this.currencyValues.t300.price
    }
    else if(tempAmount>100){
      this.PriceB=100*this.currencyValues.t300.p1+((tempAmount-100)*this.currencyValues.t300.p2)+this.currencyValues.t300.price
    }
    else if(tempAmount>50){
      this.PriceB=50*this.currencyValues.t300.p1+((tempAmount-50)*this.currencyValues.t300.p1)+this.currencyValues.t300.price
    }

    tempAmount = this.currentSeats
    this.PriceB1 = (this.currentSeats*this.currencyValues.t300.p1)+this.currencyValues.t300.price
    if(tempAmount>5000){
      this.PriceB1=3000*this.currencyValues.t300.p4+1500*this.currencyValues.t300.p3+400*this.currencyValues.t300.p2+100*this.currencyValues.t300.p1+((tempAmount-5000)*this.currencyValues.t300.p5)+this.currencyValues.t300.price
    }
    else if(tempAmount>2000){
      this.PriceB1=1500*this.currencyValues.t300.p3+400*this.currencyValues.t300.p2+100*this.currencyValues.t300.p1+((tempAmount-2000)*this.currencyValues.t300.p4)+this.currencyValues.t300.price
    }
    else if(tempAmount>500){
      this.PriceB1=400*this.currencyValues.t300.p2+100*this.currencyValues.t300.p1+((tempAmount-500)*this.currencyValues.t300.p3)+this.currencyValues.t300.price
    }
    else if(tempAmount>100){
      this.PriceB1=100*this.currencyValues.t300.p1+((tempAmount-100)*this.currencyValues.t300.p2)+this.currencyValues.t300.price
    }
    else if(tempAmount>50){
      this.PriceB1=50*this.currencyValues.t300.p1+((tempAmount-50)*this.currencyValues.t300.p1)+this.currencyValues.t300.price
    }

  }
  formatLabel(value: number) {
    this.sliderval = value
    if (value == 25) {
      return "∞";
    }
    return value;
  }
  currencyChange(e) {
    this.currencyValues = currencies.find((element) => element.type === e.value)
    this.currency = e.value
    this.add(0)
  }
  resetUserCount() {
    if (this.selectedTier == '3' && this.currentSeats < 10) {
      this.incNumber = 10
    } else if (this.selectedTier == '2' && this.currentSeats < 5) {
      this.incNumber = 5
    } else{
      this.incNumber = this.currentSeats
    }
    this.add(0)
  }
  tierSelect(tierName) {
    if (tierName == this.currentTier) {
      this.selectedTier = ''
    } else if (this.selectedTier == tierName) {
      this.selectedTier = ''
    }
    else {
      this.selectedTier = tierName;
    }


    // if (tierName == '') {
    //   this.incNumber = 5
    // }

    if (this.selectedTier == '1') {
      this.incNumber = this.currentSeats
    }
    if (this.selectedTier == '2' && this.incNumber < 5) {
      this.incNumber = 5
    }
    else if (this.selectedTier == '3' && this.incNumber < 10) {
      this.incNumber = 10
    } else{this.incNumber = 5} 
    this.add(0)

    // else if (this.currentTier == '2') {
    //   if (this.currentSeats > 5) {
    //     this.incNumber = this.currentSeats
    //   } else { this.incNumber = 5 }
    // }
    // else if (this.currentTier == '3') {
    //   if (this.currentSeats > 10) {
    //     this.incNumber = this.currentSeats
    //   } else { this.incNumber = 10 }
    // }
    console.log('|' + this.currentTier + '|', '|' + this.selectedTier + '|', '|' + tierName + '|')

  }
  checkout() {
    this.loading1 = true;
    let form = new FormData();
    form.append('product', this.selectedTier);
    form.append('licenses', this.incNumber.toString());
    form.append('currency', this.currency);
    form.append('branding', this.branding);
    if (this.customerId) { form.append('customer', this.customerId); }
    form.append('compID', this.globals.getCompanyId());
    //form.append('successUrl', `http://localhost:8000/billing`);
    //form.append('cancelUrl', `http://localhost:8000/billing`);
    form.append('successURL', `https://dashboard.stayprivate.com/billing?session_id={CHECKOUT_SESSION_ID}`);
    form.append('cancelURL', `https://dashboard.stayprivate.com/billing`);

    /*For DEV
                success_url: `http://localhost:8000/billing?session_id={CHECKOUT_SESSION_ID}`,
                cancel_url: `http://localhost:8000/billing`,
            */
            /*For LIVE
                 success_url: `https://dashboard.stayprivate.com/billing?session_id={CHECKOUT_SESSION_ID}`,
                 cancel_url: `https://dashboard.stayprivate.com/billing`,
             */
    this.http.post<any>(this.globals.getServicesUrl() + 'stripe/checkout', form).subscribe({
      next: data => {
        window.location.href = data.url;

      },
      error: error => {
        console.error('There was an error!');
        this.loading1 = false;
      }
    });
  }
  subChange() {
    this.loading1 = true;
    let form = new FormData();
    if (this.selectedTier == "standard") {
      form.append('product', "standard");
      form.append('quantity', this.sliderval.toString());

    } else if (this.currentTier == "1" || this.currentTier == "2" || this.currentTier == "3") {
      if (this.selectedTier == "1" || this.selectedTier == "2" || this.selectedTier == "3") {
        form.append('product', this.selectedTier);
      } else {
        form.append('product', this.currentTier);
      }
      form.append('quantity', this.incNumber.toString());
    } else {
      form.append('product', this.selectedTier);

    }
    form.append('currency', this.currency);
    form.append('branding', this.branding);
    form.append('compID', this.globals.getCompanyId());
    this.http.post<any>(this.globals.getServicesUrl() + 'stripe/subChange', form).subscribe({
      next: data => {
        this.loading1 = false;
        if (this.selectedTier == "standard") {
          this.currentSeats = this.sliderval
        } else if (this.currentTier == "1" || this.currentTier == "2" || this.currentTier == "3") {
          this.currentSeats = this.incNumber
          if(this.selectedTier){
            this.currentTier = this.selectedTier
          this.selectedTier = null;
        }
          // this.selectedTier=null;

        } else {
          this.currentTier = this.selectedTier;
          this.selectedTier = null;
        }
      },
      error: error => {
        console.error('There was an error!');
        this.loading1 = false;
      }
    });
  }
  infoChange() {
    this.loading1 = true;
    let form = new FormData();
    form.append('compID', this.globals.getCompanyId());
    this.http.post<any>(this.globals.getServicesUrl() + 'stripe/infoChange', form).subscribe({
      next: data => {
        window.location.href = data.url;


      },
      error: error => {
        console.error('There was an error!');
        this.loading1 = false;
      }
    });
  }
  cancelSub() {
    this.loading1 = true;
    let form = new FormData();
    form.append('compID', this.globals.getCompanyId());
    this.http.post<any>(this.globals.getServicesUrl() + 'stripe/cancelSub', form).subscribe({
      next: data => {
        this.loading1 = false;
        this.currentTier = this.selectedTier;
        this.selectedTier = null;

      },
      error: error => {
        console.error('There was an error!');
        this.loading1 = false;
      }
    });
  }

}
