<mat-horizontal-stepper [linear]="false" #setupStepper class="invis-stepper" (selectionChange)="onTabChanged($event);">
  <mat-step>
    <!--3 -->
    <!--3 -->
    <span class="paragraph">
      To configure your company email server, select 'Automatic Configuration'. Or if you prefer to do it yourself,
      select
      'Manual Configuration' and follow the instructions on the next page.
    </span>

    <div style="margin: 40px 0px;">
      <button mat-flat-button color="primary" style="width: 220px;" (click)="setupCompany()"
        class="sp-background setup-button center" *ngIf="!autoDisabled">Automatic Configuration</button>
      <div *ngIf="autoDisabled" [matTooltipDisabled]="false" matTooltip="Sorry! Our Automatic Configuration is temporarily unavailable. We hope to have it back online very soon. Please choose the Manual Configuration option, 
      and if you require any help, contact us at support@stayprivate.com.">

        <button mat-flat-button color="primary" style="width: 220px;" (click)="this.setupStepper.selectedIndex = 4"
          class="setup-button center" disabled>Automatic Configuration</button>
      </div>
      <button mat-flat-button color="primary" style="width: 220px;" (click)="this.setupStepper.selectedIndex = 1"
        class="sp-background setup-button center">Manual Configuration</button>
      <button mat-flat-button color="primary" style="width: 220px;" (click)="this.setupStepper.selectedIndex = 2"
        class="sp-background setup-button center">PowerShell Configuration</button>
      <button mat-stroked-button style="width: 220px;" class="sp-back-btn setup-button center"
        (click)="this.originStepper.selectedIndex = 0" *ngIf="this.originStepper">Back</button>
    </div>
    <div class="link-box" style="margin-bottom: 20px;">
      <div class="link-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </svg>
      </div>
      <span class="link-text">
        If you do not have administrator access to the corporate mail server, please ask the administrator to complete the
        configuration using
        the following link:
        <a target="_blank" [routerLink]="['/microsoftsetup']" [queryParams]="{'1': link}">
          https://dashboard.stayprivate.com/microsoftsetup<span *ngIf="link">?1=</span>{{link}}</a>
      </span>
    </div>
    <mat-step>
      <!-- 5 -->
      <!-- 5 -->
      <span class="subtitle">
        Microsoft Email Server Configuration
      </span>
      <div class="wrapper">
        <div class="arrow-steps clearfix">
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 0)? 'current':''"> <span>Access</span> </div>
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 1)? 'current':''"> <span>Outbound</span>
          </div>
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 2)? 'current':''"> <span>Inbound</span> </div>
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 3)? 'current':''"> <span>Rules</span> </div>
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 4)? 'current':''"> <span>Replies</span> </div>
          <div class="step" [ngClass]="(microsoftStepper.selectedIndex >= 5)? 'current':''"> <span>Enable</span> </div>
        </div>
      </div>
      <mat-horizontal-stepper #microsoftStepper class="invis-stepper" labelPosition="bottom"
        style="max-width: 750px; margin: auto;">
        <mat-step label="Access">

          <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
            Access the Exchange Admin area
          </span>

          <div class="list" style="margin-bottom: 30px;">
            <span>You will require administrator access to your Microsoft 365 account or mail server in order to
              configure it to work with StayPrivate:</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>From the Office 365 home page, in the left-hand menu, select 'Admin'.</li>
              <li>In the left-hand menu, select 'Show all'.</li>
              <li>Scroll down and under 'Admin centers' select 'Exchange'.</li>
            </ul>
            <span>We recommend that you use the latest version of the Microsoft Exchange Admin Center. If you are using
              the 'Classic' Exchange Admin Center you can still follow the instructions below, but please be aware that
              there are one or two slight differences in wording and layout.</span>
          </div>
          <div class="link-box" style="margin-bottom: 20px;">
            <div class="link-svg">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
            </div>
            <span class="link-text">
              If you do not have administrator access to the mail server, please ask the email administrator to
              complete the configuration
              using the following link:
              <a target="_blank" [routerLink]="['/microsoftsetup']" [queryParams]="{'1': link}">
                https://dashboard.stayprivate.com/microsoftsetup<span *ngIf="link">?1=</span>{{link}}</a>
            </span>
          </div>
          <div class="main-margin">
            <div class="div-margin" style="max-width: 750px;">
              <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.setupStepper.selectedIndex = 0"
                style="float: left;">Back</button>
              <button mat-flat-button color="primary" class="sp-background" style="float: right;"
                matStepperNext>Next</button>
            </div>
          </div>
        </mat-step>
        <mat-step label="Outbound">
          <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
            Add an outbound connector to StayPrivate
          </span>
          <div class="list">
            <span>The first step is to add a secure connector to the StayPrivate relay server:</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In the left-hand menu, select 'Mail flow'. Then select 'Connectors'.</li>
              <li>Click on '+ Add a connector'.</li>
              <li>Under 'Connection from' select 'Office 365'. Under 'Connection to' select 'Partner organization'. Then
                click 'Next'.</li>
              <li>Enter the name: <span class="sp-copy" (click)="copy('Send to StayPrivate')">Send to StayPrivate</span>
                and ensure 'Turn it on' is selected. Then click 'Next'.</li>
              <li>Select 'Only when I have a transport rule set up that redirects messages to this connector'. Then
                click 'Next'.</li>
              <li>Select 'Route email through these smart hosts'. Then enter the domain name: <span class="sp-copy"
                  (click)="copy('sendsecure.stayprivate.com')">sendsecure.stayprivate.com</span> and click '+'. Then
                Click 'Next'.</li>
              <li>Ensure that 'Always use Transport Layer Security...' and 'Issued by a trusted certificate authority
                (CA)' are selected. Click 'Next'.</li>
              <li>To validate the connector, enter the test email address <span class="sp-copy"
                  (click)="copy('support@stayprivate.com')">support@stayprivate.com</span> and click '+'.</li>
              <li>Click 'Validate'. Office 365 will validate the new connector. This may take a minute or so. When it
                has finished, click 'Next'.</li>
              <li>Click 'Create connector'.</li>
              <li>Click 'Done'.</li>
            </ul>
          </div>
          <div class="main-margin">
            <div class="div-margin" style="max-width: 750px; ">
              <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
                style="float: left;">Back</button>
              <button mat-flat-button color="primary" class="sp-background" style="float: right;"
                matStepperNext>Next</button>
            </div>
          </div>
        </mat-step>
        <mat-step label="Inbound">
          <span class="subtitle sp-title sp-bold" style="font-size: 15px; ">
            Add an inbound connector from StayPrivate
          </span>
          <div class="list">
            <span>Next add a secure connector to allow StayPrivate to send secure emails back to your corporate email
              server for delvering to the recipient.</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In the left-hand menu, select 'Mail flow'. Then select 'Connectors'.</li>
              <li>Click on '+ Add a connector'.</li>
              <li>Under 'From:' select ‘Your organization’s email server'. Then click 'Next'.</li>
              <li>Enter the name: <span class="sp-copy" (click)="copy('Receive from StayPrivate')">Receive from
                  StayPrivate</span></li>
              <li>Ensure that 'Turn it on' is selected and click 'Next'.</li>
              <li>Select 'By verifying that the subject name on the certificate….'. Then enter the text: <span
                  class="sp-copy" (click)="copy('*.stayprivate.com')">*.stayprivate.com</span></li>
              <li>Click 'Next'.</li>
              <li>Click 'Create connector'.</li>
              <li>Click 'Done'.</li>
            </ul>
          </div>
          <div class="main-margin">
            <div class="div-margin" style="max-width: 750px; ">
              <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
                style="float: left;">Back</button>
              <button mat-flat-button color="primary" class="sp-background" style="float: right;"
                matStepperNext>Next</button>
            </div>
          </div>
        </mat-step>
        <mat-step label="Rules">
          <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
            Add the StayPrivate rule for outbound emails.
          </span>
          <div class="list">
            <span>The next step is to add a rule so that the mail server can identify which emails to send to
              StayPrivate:</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In 'Mail flow', select 'Rules'.</li>
              <li>Select '+ Add a rule' and 'Create a new rule'.</li>
              <li>Enter the name: <span class="sp-copy"
                  (click)="copy('Identify messages to send via StayPrivate')">Identify messages to send via
                  StayPrivate</span></li>

              <li *ngIf="!isSignUp">Under 'Apply this rule if' select 'The recipient'.</li>
              <li *ngIf="!isSignUp">Then, to the right, select 'is external/internal'. Then choose 'Outside the organization' and click 'Save'.</li>
              <li *ngIf="isSignUp">Under 'Apply this rule if' select 'The subject or body'.</li>
              <li *ngIf="isSignUp">Then, to the right, select 'subject or body includes any of these words'. Then enter <span class="sp-copy" (click)="copy('#stayprivate')">#stayprivate</span> and click 'Add' then click 'Save'.</li>
              
              <li>Under 'Do the following' select 'Redirect the message to'. Then, to the right, select 'the following connector'. Select 'Send to StayPrivate' and click 'Save'.</li>
              <li>Under 'Except if' select 'The sender'. Then, to the right, select 'IP address is in any of these
                ranges or exactly matches'. Enter <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span>
                in the box.</li>
              <li>Click 'Add'.</li>
              <li>Tick the box next to 18.130.40.2. Click 'Save'.</li>
              <li>Click 'Next'.</li>
              <li>Ensure that 'Enforce' is selected, then click 'Next'.</li>
              <li>Click 'Finish'. It may take a few seconds for the rule to be saved.</li>
              <li>Click 'Done'.</li>
            </ul>
            <span>Next add a rule to ensure that secure emails are not incorrectly identified as spam:</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In 'Mail flow', select 'Rules'.</li>
              <li>Select '+ Add a rule' and then 'Bypass spam filtering'.</li>
              <li>Enter the name: <span class="sp-copy" (click)="copy('StayPrivate Allow')">StayPrivate Allow</span>
              </li>
              <li>Under 'Apply this rule if' select 'The sender'. Then, to the right, select 'IP address is in any of
                these ranges or exactly matches'. Enter <span class="sp-copy"
                  (click)="copy('18.130.40.2')">18.130.40.2</span> in the
                box. Then click 'Add'.</li>
              <li>Tick the box next to 18.130.40.2. Then click 'Save'.</li>
              <li>Click 'Next'.</li>
              <li>Ensure that 'Enforce' is selected, then click 'Next'.</li>
              <li>Click 'Finish'. It may take a few seconds for the rule to be saved.</li>
              <li>Click 'Done'.</li>
            </ul>
          </div>
          <div class="main-margin">
            <div class="div-margin" style="max-width: 750px; ">
              <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
                style="float: left;">Back</button>
              <button mat-flat-button color="primary" class="sp-background" style="float: right;"
                matStepperNext>Next</button>
            </div>
          </div>
        </mat-step>
        <mat-step label="Replies">
          <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
            Add a rule to ensure inbound secure emails are copied to StayPrivate
          </span>
          <div class="list">
            <span>To ensure that direct replies from secure corporate domains are included in StayPrivate channels,
              incoming secure replies are identified and blind copied to server@stayprivatemail.com. Firstly, set up a
              new contact:</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In the left-hand menu, select 'Recipients' then select 'Contacts'.</li>
              <li>Select 'Add a mail contact'.</li>
              <li>Under Contact type select 'Mail contact'.</li>
              <li>Under 'Display name' enter: <span class="sp-copy"
                  (click)="copy('StayPrivateServer')">StayPrivateServer</span></li>
              <li>Under 'Alias' enter: <span class="sp-copy"
                  (click)="copy('StayPrivateServer')">StayPrivateServer</span></li>
              <li>Under 'External email address' enter: <span class="sp-copy"
                  (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span></li>
              <li>Click 'Next'.</li>
              <li>Click 'Next' again, then click 'Create'.</li>
              <li>Click 'Done'.</li>
            </ul>
            <span>Now add a rule to ensure that secure emails are copied to server@stayprivatemail.com:</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In the left-hand menu, select 'Mail flow' and select 'Rules'.</li>
              <li>Select '+ Add a rule' and 'Create a new rule'.</li>
              <li>Enter the name: <span class="sp-copy" (click)="copy('Send secure replies to StayPrivate')">Send secure
                  replies to StayPrivate</span></li>
              <li>Under 'Apply this rule if' select 'The subject or body'.</li>
              <li>Then select 'subject or body matches these text patterns' and enter <span class="sp-copy"
                (click)="copy('#stayprivate-secure-reply')">#stayprivate-secure-reply</span>.</li>
              <li>Click 'Add'.</li>
              <li>Tick the box next to #stayprivate-secure-reply. Then click 'Save'.</li>
              <li>Under 'Do the following' select 'Add Recipients' then, to the right, select 'to the Bcc box'.</li>
              <li>Find 'StayPrivateServer' in the list and tick the box next to it. Click 'Save'.</li>
              <li *ngIf="!domain">Under 'Except if' select 'The sender' then, to the right, select 'domain is'. Enter
                your email domain (for example, mycompany.com) in the box.</li>
              <li *ngIf="domain">Under 'Except if' select 'The sender' then, to the right, select 'domain is'. Enter
                <span class="sp-copy" (click)="copy(domain)">{{domain}}</span> in the box.</li>
              <li *ngIf="!domain">Tick the box next to your domain. Then click 'Save'.</li>
              <li *ngIf="domain">Tick the box next to {{domain}}. Then click 'Save'.</li>
              <li>Click the blue '+' next to the box with the text 'domain is'.</li>
              <li>Under 'And' select 'The sender'. Then, to the right, select 'IP address is in any of these ranges or
                exactly matches'.</li>
              <li>Enter <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span> in the box. Then click
                'Add'.</li>
              <li>Tick the box next to 18.130.40.2. Then click 'Save'.</li>
              <li>Click 'Next'.</li>
              <li>Check that 'Enforce' is selected, then click 'Next'.</li>
              <li>Click 'Finish'. It may take a few seconds for the rule to be saved.</li>
              <li>Click 'Done'.</li>
            </ul>
          </div>
          <div class="main-margin">
            <div class="div-margin" style="max-width: 750px; ">
              <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
                style="float: left;">Back</button>
              <button mat-flat-button color="primary" class="sp-background" style="float: right;"
                matStepperNext>Next</button>
            </div>
          </div>
        </mat-step>
        <mat-step label="Enable">
          <span class="subtitle sp-title sp-bold" style="font-size: 15px; ">
            Enable the rules
          </span>
          <div class="list">
            <span>The final step is simply to ensure that the rules set up above are enabled.</span>
            <ul style="padding-top: 20px;padding-bottom: 15px;">
              <li>In the left-hand menu, select 'Rules'.</li>
              <li>Find the rule 'Identify messages to send via StayPrivate'. Click on the word 'Disabled' next to the
                rule.</li>
              <li>Click on the switch under 'Enable or disable rule' to enable the rule.</li>
              <li>Click on the close button top right. It will take a few seconds for the page to update.</li>
              <li>Find the rule 'StayPrivate Allow'. Click on the word 'Disabled' next to the rule.</li>
              <li>Click on the switch under 'Enable or disable rule' to enable the rule.</li>
              <li>Click on the close button top right. It will take a few seconds for the page to update.</li>
              <li>Find the rule 'Send secure replies to StayPrivate'. Click on the word 'Disabled' next to the rule.
              </li>
              <li>Click on the switch under 'Enable or disable rule' to enable the rule.</li>
              <li>Click on the close button top right. It will take a few seconds for the page to update.</li>
            </ul>
            <span>That is it! StayPrivate will start working right away.</span>
          </div>
          <div class="main-margin">
            <div class="div-margin" style="max-width: 750px; ">
              <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
                style="float: left;">Back</button>
              <button mat-flat-button color="primary" class="sp-background" style="float: right;"
                *ngIf="this.originStepper" (click)="finish()">Finish</button>
            </div>
          </div>
        </mat-step>
        <ng-template matStepperIcon="edit">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="12px" y="12px" viewBox="0 0 488 500" style="width: 14px;" xml:space="preserve">
            <g>
              <path style="fill: #FFFFFF;" d="M485.95,258.2L245.86,498.29c-0.98,0.98-2.56,0.98-3.54,0L2.23,258.2c-0.47-0.47-0.73-1.1-0.73-1.77V44.78
                  c0-2.23,2.69-3.34,4.27-1.77l236.56,236.56c0.98,0.98,2.56,0.98,3.54,0L482.41,43.01c1.57-1.57,4.27-0.46,4.27,1.77v211.65
                  C486.68,257.09,486.42,257.73,485.95,258.2z" />
              <path style="fill: #FFFFFF;" d="M443.57,1.33H44.61c-2.23,0-3.34,2.69-1.77,4.27l199.48,199.48c0.98,0.98,2.56,0.98,3.54,0L445.34,5.6
                  C446.91,4.02,445.8,1.33,443.57,1.33z" />
            </g>
          </svg>
        </ng-template>
      </mat-horizontal-stepper>
    </mat-step>

  </mat-step>
  <mat-step *ngIf="!domain">
    <span class="paragraph">
      To use the Automated Configuration, enter your corporate email domain (such as company.com):
    </span>
    <div class="div-margin" style="padding-top:20px;">
      <mat-form-field appearance="outline" style="width: 100%;max-width: 300px; display: block;">
        <input matInput placeholder="stayprivate.com" class="text-padding" [(ngModel)]="inputDomain" type="text">
        <!-- <mat-error *ngIf="setupForm.controls['Domain'].hasError('required')">
        A domain is <strong>required</strong>.
      </mat-error>
      <mat-error *ngIf="setupForm.controls['Domain'].hasError('pattern')">
        The domain must be <strong>valid</strong>. @ symbol is not required.
      </mat-error> -->
      </mat-form-field>
    </div>
    <span class="paragraph">
      Please note that the domain needs to be provisioned with StayPrivate for the service to operate. You can check
      this by logging into the StayPrivate dashboard at <a target="_blank"
        [routerLink]="['/']">https://dashboard.stayprivate.com</a>.
    </span>
    <div class="div-margin" style="max-width: 750px; padding: 20px 10px">
      <button mat-flat-button color="primary" class="sp-background center" style="float: right;"
        (click)="this.setupStepper.next()">Next</button>
      <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.setupStepper.selectedIndex = 0"
        style="float: left;">Back</button>
    </div><br>
  </mat-step>
  <!-- START OF SETUP VIA MSPOWERSHELL -->
  <mat-step>

    <span class="paragraph">
      For those with experience with PowerShell, our solution can be easily intergrated using PowerShell and the Exchange Online PowerShell Module.<br><br>
      You will need to have done the following before continuing:
      <div class="list" >
        <ul style="padding-top: 20px;padding-bottom: 15px;">
          <li>Have PowerShell installed. We recommend PowerShell 7.</li>
          <li>Have installed the ExchangeOnlineManagement module.</li>
          <li>Have administrator access to your Microsoft 365 account.</li>
          <li>Ensure there are no StayPrivate mailflow rules currently in place.</li>
        </ul>
        <span>Further details can be found here: <br><a href="https://learn.microsoft.com/en-us/powershell/exchange/connect-to-exchange-online-powershell?view=exchange-ps">https://learn.microsoft.com/en-us/powershell/exchange/connect-to-exchange-online-powershell?view=exchange-ps</a></span>
      </div>
    </span>
    
    
    <div class="div-margin" style="max-width: 750px;margin: 30px auto;">
      <button mat-stroked-button class="sp-back-btn" type="button"
        (click)="this.setupStepper.selectedIndex = 0">Back</button>
      <button mat-flat-button color="primary" matStepperNext class="sp-background "
        style="float: right;">Next</button>
    </div>
  </mat-step>


  <mat-step>
    <span class="paragraph">
      Copy and Paste the following commands into your PowerShell.
      <div class="list" >
        <ul style="padding-top: 20px;padding-bottom: 15px;">
          <li class='powershell-li'><span class="sp-copy" (click)="copy(powershellRules.connect)">{{powershellRules.connect}}</span></li>
          <li class='powershell-li'>Copy the code given and enter it at; <a href="https://microsoft.com/devicelogin">https://microsoft.com/devicelogin</a>, and follow the steps.</li>
          <li class='powershell-li'>Wait for your powershell session to be authenticated</li>
          <li class='powershell-li'><span class="sp-copy" (click)='copy(powershellRules.outboundConnector)'>{{powershellRules.outboundConnector}}</span></li>
          <li class='powershell-li'><span class="sp-copy" (click)='copy(powershellRules.inboundConnector)'>{{powershellRules.inboundConnector}}</span></li>
          <li class='powershell-li'><span class="sp-copy" (click)='copy(powershellRules.identifyRule)'>{{powershellRules.identifyRule}}</span></li>
          <li class='powershell-li'><span class="sp-copy" (click)='copy(powershellRules.replyRule)'>{{powershellRules.replyRule}}</span></li>
          <li class='powershell-li'><span class="sp-copy" (click)='copy(powershellRules.allowRule)'>{{powershellRules.allowRule}}</span></li>

        </ul>
      </div>
    </span>  
    <div class="div-margin" style="max-width: 750px;margin: 30px auto;">
      <button mat-stroked-button class="sp-back-btn" type="button"
        (click)="this.setupStepper.selectedIndex = 2">Back</button>
        <button mat-flat-button color="primary" class="sp-background" style="float: right;"
        *ngIf="this.originStepper" (click)="finish()">Finish</button>
    </div>
  </mat-step>

  <mat-step>
    <!-- 5 -->
    <!-- 5 -->
    <span class="paragraph">
      StayPrivate requires temporary access to configure your company's Microsoft mail flow settings. <br> <br> Copy the
      code and
      then click 'Authenticate' to open a Microsoft authentication window. Then follow the instructions to grant access.
    </span>

    <mat-spinner *ngIf="loading" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>
    <div *ngIf="!loading" class="main-margin">
      <div class="div-margin" *ngIf="dataSource[1].status != 'S'">
        <mat-label>Authentication Code</mat-label><br>
        <mat-form-field appearance="outline" style="width: 100%;max-width: 300px; display: block;">
          <input matInput placeholder="Generating code..." class="text-padding" [(ngModel)]="msAuthToken"
            [disabled]="true">
          <button matSuffix mat-icon-button aria-label="Copy Authentication Code" (click)="copy(msAuthToken)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="div-margin" *ngIf="dataSource[1].status != 'S'">
        <button mat-flat-button color="primary" class="sp-background center" style="float: right;"
          (click)="openAuth()">Authenticate</button>
        <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.setupStepper.selectedIndex = 0"
          style="float: left;">Back</button>
      </div><br>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2" style="width: 70%; margin: auto;"
        *ngIf="processing">
        <ng-container matColumnDef="stage">
          <th mat-header-cell *matHeaderCellDef> Stage </th>
          <td mat-cell *matCellDef="let step"> {{step.stage}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Status </th>
          <td mat-cell *matCellDef="let step" style="text-align: -webkit-center;">
            <mat-spinner *ngIf="step.status==='P'" diameter="20"></mat-spinner>
            <mat-icon *ngIf="step.status==='S'" style="color:#02b302;">done</mat-icon>
            <mat-icon *ngIf="step.status==='F'" style="color:#da1c1c;">close</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <span class="paragraph" *ngIf="dataSource[1].status != 'S'">
      Or if you would rather update the settings yourself, simply follow the instructions
      <a role="button" [routerLink]="" (click)="this.setupStepper.selectedIndex = 0">here</a>.
    </span> <br>
    <div class="link-box" style="margin-bottom: 20px;">
      <div class="link-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </svg>
      </div>
      <span class="link-text">
        If you do not have administrator access to the email server, please ask the email administrator to complete the
        configuration using
        the following link:
        <a target="_blank" [routerLink]="['/microsoftsetup']" [queryParams]="{'1': link}">
          https://dashboard.stayprivate.com/microsoftsetup<span *ngIf="link">?1=</span>{{link}}</a>
      </span>
    </div>
  </mat-step>


</mat-horizontal-stepper>