import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';

@Component({
  selector: 'app-sp-spinner',
  templateUrl: './sp-spinner.component.html',
  styleUrls: ['./sp-spinner.component.css']
})
export class SpSpinnerComponent implements OnInit, AfterViewInit,OnDestroy {

  constructor() { }
  @ViewChild('parallelogram', {static: false}) canvas: ElementRef;
  myCanvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  ngOnInit(): void {
    console.log('spinner created')
  }
  intervalID
  favChanger
  ngAfterViewInit(): void {
    this.context = this.canvas.nativeElement.getContext('2d');
    // this.intervalID = setTimeout(this.drawReverse, 10, this.canvas, -36, 30, 30, Math.PI * 5/6, 12, 12);
    // this.favChanger = setTimeout(this.updateFavicon, 1000);
    this.drawReverse(this.canvas, -36, 30, 30, Math.PI * 5/6, 12, 12)
  }
  @HostListener('unloaded')
  destroyed=false
  ngOnDestroy() {
    this.destroyed=true
    console.log('Items destroyed');
  }

  
  
  updateFavicon() {
  };
  
  // to stop the animation clearTimeout(intervalID) and remove the element;
  
  draw(canvas, count, h1, h2, theta, stepsfadein, stepswaitagain) {
  
      var ctx = canvas.nativeElement.getContext('2d');
      ctx.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
  
      const scaling = canvas.nativeElement.clientWidth / 100;
  
      const steps1 = 24;
      const steps2 = 18;
      const steps3 = 12;
      const steps4 = 12;
      const steps5 = 18;
      const c1 = 22.5 * scaling;
      const r1 = 35.43 * scaling;
      const c2 = 66 * scaling;
      const r2 = 41.4 * scaling;
      const sinphi = 1/Math.sqrt(3);
      const h1start = 30;
      const h2start = 30;
      const h1end = 0;
      const h2end = 30;
      const hh = 36 * scaling;
      const centre = 50 * scaling;
  
      let opacity = 0;
  
      let theta1 = theta;
      let theta2 = theta1 + Math.PI * 2/3;
      let theta3 = theta2 + Math.PI * 2/3;
  
      if (count < -stepsfadein-stepswaitagain) {
          opacity = 0;
      } else if (count < -stepswaitagain) {
          opacity = (count + stepsfadein + stepswaitagain) / stepsfadein;
      } else {
          opacity = 1;
      }
  
      ctx.globalAlpha = opacity;
  
      let x1 = centre + Math.cos(theta1)*r1;
      let y1 = c1 - Math.sin(theta1)*r1*sinphi + h1 * scaling;
      let x2 = centre + Math.cos(theta2)*r1;
      let y2 =  c1 - Math.sin(theta2)*r1*sinphi + h1 * scaling;
      let x3 = centre + Math.cos(theta3)*r1;
      let y3 =  c1 - Math.sin(theta3)*r1*sinphi + h1 * scaling;
      let ymax = Math.max(y1, y2, y3);
      let ymin = Math.min(y1, y2, y3);
  
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x3, y3);
      ctx.closePath();
  
      var grd = ctx.createLinearGradient(0,ymin,0,ymax);
  
      grd.addColorStop(0,'#4F97C1');
      grd.addColorStop(1,'#4D95BF');
  
      ctx.fillStyle = '#4482af';
  
      ctx.fill();
  
      x1 = centre + Math.cos(theta1)*r2;
      y1 = c2 - Math.sin(theta1)*r2*sinphi + h2 * scaling;
      x2 = centre + Math.cos(theta2)*r2;
      y2 =  c2 - Math.sin(theta2)*r2*sinphi + h2 * scaling;
      x3 = centre + Math.cos(theta3)*r2;
      y3 =  c2 - Math.sin(theta3)*r2*sinphi + h2 * scaling;
      ymax = Math.max(y1, y2, y3);
      ymin = Math.min(y1, y2, y3) - hh;
  
      if (theta1 > Math.PI * 2/3) {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x3, y3-hh);
          ctx.lineTo(x2, y2-hh);
          ctx.lineTo(x1, y1-hh);
          ctx.closePath();
      } else if (theta1 >= Math.PI * 1/3) {    
          ctx.beginPath();
          ctx.moveTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x3, y3-hh);
          ctx.lineTo(x2, y2-hh);
          ctx.closePath();  
      } else {    
          ctx.beginPath();
          ctx.moveTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x1, y1);
          ctx.lineTo(x1, y1-hh);
          ctx.lineTo(x3, y3-hh);
          ctx.lineTo(x2, y2-hh);
          ctx.closePath();  
      }
  
      grd = ctx.createLinearGradient(0,ymin,0,ymax);
      grd.addColorStop(0,'#4D95BF');
      grd.addColorStop(1,'#4A8DB8');
  
      ctx.fillStyle = '#4482af';
      
      ctx.fill();
      let x = 0;
      let fx = 0;
  
      if (count < 0 ) {
          count++;
      } else if (count < steps1) {
          theta = theta - Math.PI * 2 / 3 / steps1;
          count++;
      } else if (count < steps1 + steps2) {
          count++;
      } else if (count < steps1 + steps2 + steps3) {
          x = (count - steps1 - steps2) / steps3;
          fx = 3 * x * x - 2 * x * x * x;
          h1 = h1 + (h1end - h1) * fx; 
          h2 = h2 + (h2end - h2) * fx;
          count++; 
      } else if (count < steps1 + steps2 + steps3 + steps4) {
          x = (count - steps1 - steps2 - steps3) / steps4;
          fx = 3 * x * x - 2 * x * x * x;
          h1 = h1 + (h1start - h1) * fx; 
          h2 = h2 + (h2start - h2) * fx;
          count++;
      } else if (count < steps1 + steps2 + steps3 + steps4 + steps5) {
          count++;        
      } else {
          theta = Math.PI * 5/6;
          count = 0;
      }
  
      this.intervalID = setTimeout(this.draw, 10, canvas, count, h1, h2, theta, stepsfadein, stepswaitagain);
  
  }
  
  
  drawReverse(canvas, count, h1, h2, theta, stepsfadein, stepswaitagain) {
      var ctx = canvas.nativeElement.getContext('2d');
      ctx.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
  
      const scaling = canvas.nativeElement.clientWidth / 100;
  
      const steps1 = 24;
      const steps2 = 18;
      const steps3 = 24;
      const steps4 = 24;
      const steps5 = 18;
      const c1 = 22.5 * scaling;
      const r1 = 35.43 * scaling;
      const c2 = 66 * scaling;
      const r2 = 41.4 * scaling;
      const sinphi = 1/Math.sqrt(3);
      const h1start = 30;
      const h2start = 30;
      const h1end = 0;
      const h2end = 30;
      const hh = 36 * scaling;
      const centre = 50 * scaling;
  
      let opacity = 0;
  
      let theta1 = theta;
      let theta2 = theta1 + Math.PI * 2/3;
      let theta3 = theta2 + Math.PI * 2/3;
  
      if (count < -stepsfadein-stepswaitagain) {
          opacity = 0;
      } else if (count < -stepswaitagain) {
          opacity = (count + stepsfadein + stepswaitagain) / stepsfadein;
      } else {
          opacity = 1;
      }
  
      ctx.globalAlpha = opacity;
      let x1 = centre - Math.cos(theta1)*r1;
      let y1 = c1 - Math.sin(theta1)*r1*sinphi + h1 * scaling;
      let x2 = centre - Math.cos(theta2)*r1;
      let y2 =  c1 - Math.sin(theta2)*r1*sinphi + h1 * scaling;
      let x3 = centre - Math.cos(theta3)*r1;
      let y3 =  c1 - Math.sin(theta3)*r1*sinphi + h1 * scaling;
      let ymax = Math.max(y1, y2, y3);
      let ymin = Math.min(y1, y2, y3);
  
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x3, y3);
      ctx.closePath();
  
      var grd = ctx.createLinearGradient(0,ymin,0,ymax);
  
      grd.addColorStop(0,'#4F97C1');
      grd.addColorStop(1,'#4D95BF');
  
      ctx.fillStyle = '#4482af';
  
      ctx.fill();
  
      x1 = centre - Math.cos(theta1)*r2;
      y1 = c2 - Math.sin(theta1)*r2*sinphi + h2 * scaling;
      x2 = centre - Math.cos(theta2)*r2;
      y2 =  c2 - Math.sin(theta2)*r2*sinphi + h2 * scaling;
      x3 = centre - Math.cos(theta3)*r2;
      y3 =  c2 - Math.sin(theta3)*r2*sinphi + h2 * scaling;
      ymax = Math.max(y1, y2, y3);
      ymin = Math.min(y1, y2, y3) - hh;
  
      if (theta1 > Math.PI * 2/3) {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x3, y3-hh);
          ctx.lineTo(x2, y2-hh);
          ctx.lineTo(x1, y1-hh);
          ctx.closePath();
      } else if (theta1 >= Math.PI * 1/3) {    
          ctx.beginPath();
          ctx.moveTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x3, y3-hh);
          ctx.lineTo(x2, y2-hh);
          ctx.closePath();  
      } else {    
          ctx.beginPath();
          ctx.moveTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x1, y1);
          ctx.lineTo(x1, y1-hh);
          ctx.lineTo(x3, y3-hh);
          ctx.lineTo(x2, y2-hh);
          ctx.closePath();  
      }
  
      grd = ctx.createLinearGradient(0,ymin,0,ymax);
      grd.addColorStop(0,'#4D95BF');
      grd.addColorStop(1,'#4A8DB8');
  
      ctx.fillStyle = '#4482af';
      
      ctx.fill();
  console.log(count)

      let x = 0;
      let fx = 0;
  
      if (count < 0 ) {
          count++;
      } else if (count < steps1) {
          theta = theta - Math.PI * 2 / 3 / steps1;
          count++;
      } else if (count < steps1 + steps2) {
          count++;
      } else if (count < steps1 + steps2 + steps3) {
          x = (count - steps1 - steps2) / steps3;
          fx = 3 * x * x - 2 * x * x * x;
          h1 = h1 + (h1end - h1) * fx; 
          h2 = h2 + (h2end - h2) * fx;
          count++; 
      } else if (count < steps1 + steps2 + steps3 + steps4) {
          x = (count - steps1 - steps2 - steps3) / steps4;
          fx = 3 * x * x - 2 * x * x * x;
          h1 = h1 + (h1start - h1) * fx; 
          h2 = h2 + (h2start - h2) * fx;
          count++;
      } else if (count < steps1 + steps2 + steps3 + steps4 + steps5) {
          count++;        
      } else {
          theta = Math.PI * 5/6;
          count = 0;
      }
      if(!this.destroyed){
        this.intervalID = setTimeout(() => {
          this.drawReverse( canvas, count, h1, h2, theta, stepsfadein, stepswaitagain);
        }, 10)
      }
      
      // this.intervalID = setTimeout(this.drawReverse, 10, canvas, count, h1, h2, theta, stepsfadein, stepswaitagain);
  
  }
}
