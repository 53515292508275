import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Globals } from 'src/app/globals';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-setup-roundtrip',
  templateUrl: './setup-roundtrip.component.html',
  styleUrls: ['./setup-roundtrip.component.css']
})
export class SetupRoundtripComponent implements OnInit {

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    public route: ActivatedRoute,
    private clipboard: Clipboard,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }
  @ViewChild('setupStepper') public myStepper: MatStepper;
  @ViewChild('standardStepper') public standardStepper: MatStepper;
  @ViewChild('microsoftStepper') public microsoftStepper: MatStepper;
  @ViewChild('googleStepper') public googleStepper: MatStepper;
  @Output() setupFinished = new EventEmitter();
  @Input() public eventEmit: any;
  @Input() public originStepper: MatStepper;
  @Input() public companyId: string;
  @Input() public isSignUp: boolean;
  @Input() public keyword: string;
  @Input() public domain: string;
  @Input() public companyName: string;
  link: string;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['1']) {
            this.link = params['1']
      } else if (this.companyId) {
        let param = new HttpParams()
        param = param.append('companyId',this.companyId)
        this.http.get<any>(this.globals.getServicesUrl() + 'company/createLink',{params:param}).subscribe({
          next: data => {
            this.link = data.id
            this.keyword=data.keyword
            this.domain=data.domain
            this.companyName=data.name

          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
      }
    });
  }
  finish(){
    this.setupFinished.emit()

  }
  copy(copyVariable) {
    this.clipboard.copy(copyVariable);
    this._snackBar.open("Copied!", "OK", { duration: 3000 });
  }
}
