import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { domain } from 'process';
import { Globals } from 'src/app/globals';
import { SetupCompleteComponent } from '../setup-complete/setup-complete.component';

@Component({
  selector: 'app-company-setup',
  templateUrl: './company-setup.component.html',
  styleUrls: ['./company-setup.component.css']
})
export class CompanySetupComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private globals: Globals,
    private http: HttpClient,
    public dialog: MatDialog,

  ) { }
  keyword : string;
  domain: string;
  companyName: string;
  link: string;
  standalon: Boolean;

  ngOnInit(): void {
    this.globals.checkTheme()

    this.route.queryParams.subscribe(params => {
      if (params['1']) {
        let param = new HttpParams()
        param = param.append('linkId', params['1'])
        this.http.get<any>(this.globals.getServicesUrl() + 'company/setupLinkData', { params: param }).subscribe({
          next: data => {
            this.link = params['1']
            this.keyword = data.keyword
            this.domain = data.domain
            this.companyName = data.name
          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
      }
      if (params['2']) {
        if(params['2']=='Y') { this.standalon = false; } 
        else{this.standalon=true;}
      } else{ this.standalon=true;}
    });
  }
  finish(event) {
    const dialogRef = this.dialog.open(SetupCompleteComponent, {
      width: '400px',
      data: {
        keyword: event.keyword,
        domain: event.domain
      },
      autoFocus: false
    });
  }
}
