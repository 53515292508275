<div class="compose-window compose-window-top">
    
    <span class="compose-text-top">New Email</span>
    <span class="compose-close"> X </span>
</div>
<div class="compose-window compose-window-main">
    <div class="recipient">
        <span class="recipient-to">To</span>
        <input type="text" class="recipient-input">
    </div>
    <!-- <div class="recipient">
        <span class="recipient-to">Cc</span>
        <input type="text" class="recipient-input">
    </div>
    <div class="recipient">
        <span class="recipient-to">Bcc</span>
        <input type="text" class="recipient-input">
    </div> -->
    <div class="recipient">
        <input type="text" class="recipient-input" placeholder="Subject:">
    </div>
    <div>
        <div class="compose-text-editor" [froalaEditor]="options" ></div>
    </div>
    <div>
        Send
    </div>
</div>
