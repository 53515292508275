import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-csv-download-dialog',
  templateUrl: './csv-download-dialog.component.html',
  styleUrls: ['./csv-download-dialog.component.css']
})
export class CsvDownloadDialogComponent implements OnInit {

  constructor(
    private http:HttpClient,
    private fb: FormBuilder,
    private globals:Globals,
    public dialogRef: MatDialogRef<CsvDownloadDialogComponent>,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    
  }
  
  dbPasswordForm = this.fb.group({
    dbPassword: [null, Validators.required]  
  });
  hidePassword = true;
  isLoading=false;

  download(){
  this.isLoading=true;

    let param = new HttpParams();
    param = param.append('pwd', this.dbPasswordForm.value.dbPassword );
    this.http.get(this.globals.getServicesUrl()+"billing/download", { params:param, responseType: 'blob' }).subscribe({
    next: data => {
      this.isLoading=false;

      let binaryData = [];
      binaryData.push(data);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: 'text/csv;'}));
      downloadLink.setAttribute('download', "Invoice Billing.csv");
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
      this.dialogRef.close();
    },
    error: error => {
      this.isLoading=false;

      if(error.status==401){
        this._snackBar.open("Incorrect Password.","OK",{duration:5000} );
      }else{this._snackBar.open("Unknown Error.","OK",{duration:5000} );}

    }
  });
  }

}
