<mat-spinner diameter="70" style="margin: 50px auto 50px auto;" *ngIf="loading"></mat-spinner>

<mat-horizontal-stepper [linear]="false" #setupStepper style="margin-top: 30px;" class="invis-stepper" *ngIf="!loading">
  <mat-step>
    <span class="subtitle">Current settings:
    </span>
    <div class="main-margin" >
      <table class="table-style"style="margin-bottom: 40px;">
        <tr>
          <td>Email server:</td>
          <td style="text-align: right;">{{currentInfo.controls['Provider'].value}}</td>
        </tr>
        <tr>
          <td>Domain:</td>
          <td style="text-align: right;">{{currentInfo.controls['Domain'].value}}</td>
        </tr>
        <tr>
          <td>Keyword:</td>
          <td style="text-align: right;">{{currentInfo.controls['Keyword'].value}}</td>
        </tr>
        <tr *ngIf="currentInfo.controls['Security'].value">
          <td>Configuration:</td>
          <td style="text-align: right;">{{currentInfo.controls['Security'].value}}</td>
        </tr>
      </table>
      <table  *ngIf="currentInfo.controls['Security'].value">
        <tr>
          <td>To configure your company email server, click here:</td>
          <td><button mat-flat-button color="primary" style="width: 220px; float: right;"
              (click)="goToInstructions(currentInfo.controls['Provider'].value)" 
              class="sp-background setup-button center" [disabled]="companyState=='I'">Configure</button></td>
        </tr>
      </table>
      <table *ngIf="this.globals.getCompanyType()!='Customer'&& !isSignUp">
        <tr>
          <td>To re-run the full setup process, click here:</td>
          <td><button mat-flat-button color="primary" style="width: 220px; float: right;" matStepperNext
              class="sp-background setup-button center" [disabled]="companyState=='I'">Re-run Full Setup</button></td>
        </tr>
      </table>
      <table *ngIf="this.globals.getCompanyType()!='Customer'&& isSignUp">
        <tr>
          <td>To re-run the full setup process, click here:</td>
          <td><button mat-flat-button color="primary" style="width: 220px; float: right;" matStepperNext (click)="setSecurity('RX')" 
              class="sp-background setup-button center" [disabled]="companyState=='I'">Re-run Full Setup</button></td>
        </tr>
      </table>
    </div>
  </mat-step>

  <!-- PICK SECURITY LEVEL -->
  <mat-step >
    <!-- 1 -->
    <!-- 1 -->
    <span class="subtitle">Select your security level:
    </span>
    <button mat-stroked-button class="setup-button" (click)="setSecurity('RX')" [disabled]="companyState=='I'"
      style="width: 230px;">
      <mat-icon class="sp-title button-svg">manage_search</mat-icon> Keyword Only
    </button>
    <button mat-stroked-button class="setup-button" (click)="setSecurity('SM')" [disabled]="companyState=='I'" *ngIf="tier=='Professional Plus'"
      style="width: 230px;">
      <mat-icon class="sp-title button-svg">auto_fix_normal</mat-icon> Content Based
    </button>
    <button mat-stroked-button class="setup-button" (click)="setSecurity('ST')" [disabled]="companyState=='I'"
      style="width: 230px;">
      <mat-icon class="sp-title button-svg">lock</mat-icon> Enforced
    </button>
    <button mat-stroked-button class="sp-back-btn setup-button center" style="width: 230px;" matStepperPrevious
      *ngIf="landing">Back</button>
    <!-- 1 -->
    <!-- 1 -->
  </mat-step>
  
  <!-- END PICK SECURITY LEVEL -->

  <!-- PICK PROVIDER -->
  <mat-step>
    <!-- 2 -->
    <!-- 2 -->
    <span class="subtitle">Select your corporate email provider:
    </span>
    <div class="main-margin">
      <button mat-stroked-button class="setup-button" (click)="this.setProvider('microsoft')"
        [disabled]="companyState=='I'" style="width: 230px;">
        <svg class="button-svg" enable-background="new 0 0 2499.6 2500" viewBox="0 0 2499.6 2500"
          xmlns="http://www.w3.org/2000/svg">
          <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b" />
          <path d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z" fill="#80cc28" />
          <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef" />
          <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09" />
        </svg>
        Microsoft 365</button>
      <button mat-stroked-button class="setup-button" (click)="this.setProvider('google')"
        [disabled]="companyState=='I'" style="width: 230px;">
        <svg class="button-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <g>
            <path fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
            </path>
            <path fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
            </path>
            <path fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
            </path>
            <path fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
            </path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </g>
        </svg>
        Google Workspace</button>
      <button mat-stroked-button class="setup-button" matStepperNext [disabled]="companyState=='I'"
        style="width: 230px;">
        <mat-icon class="sp-title" style="margin: 0px 20px 2px 0px;">mail</mat-icon> Other
      </button>
      <button mat-stroked-button class="sp-back-btn setup-button center" style="width: 230px;" *ngIf="!isSignUp"
      matStepperPrevious>Back</button>
      <button mat-stroked-button class="sp-back-btn setup-button center" style="width: 230px;" *ngIf="landing && isSignUp"
      (click)="this.setupStepper.selectedIndex = 0">Back</button>
    </div>
    <!-- 2 -->
    <!-- 2 -->
  </mat-step>
  <mat-step>
    <!--3 -->
    <!--3 -->
    <span class="paragraph" *ngIf="setupInfo.controls['Domain'].value=='otherspf'">
      There are two ways of configuring StayPrivate to work with a corporate email server. The preferred approach is the
      'Standard' configuration in which StayPrivate processes secure emails then returns them back to your corporate
      email server for forwarding to recipients.
    </span>
    <span class="paragraph">
      The 'Direct' configuration is designed for situations where your corporate email server is not able to accept
      return
      emails from StayPrivate. In this case, StayPrivate can be configured to send emails directly on behalf of your
      company. To enable this,
      your company DNS records first need to be updated to add StayPrivate as a permitted sender.
    </span>
    <span class="paragraph">
      Choose how you would like to configure StayPrivate:
    </span>
    <div class="main-margin">
      <button mat-flat-button color="primary" style="width: 220px;" (click)="this.setProvider('othermx')"
        class="sp-background setup-button center">Standard</button>
      <button mat-flat-button color="primary" style="width: 220px;" matStepperNext
        class="sp-background setup-button center">Direct</button>
      <button mat-stroked-button class="sp-back-btn setup-button center" style="width: 220px;"
        (click)="this.setupStepper.selectedIndex = 2">Back</button>
    </div>
    <!--3 -->
    <!--3 -->
  </mat-step>
  <!-- END PICK PROVIDER -->


  <mat-step>
    <!--4 -->
    <!--4 -->
    <span class="paragraph">
      Firstly, please follow the steps below to update your corporate DNS records to permit StayPrivate to send emails from your corporate domain.
    </span>
    
    <div class="list">
      <ol>
        <li>Log into the DNS administration console.</li>
        <li>Find the current SPF record – this is a TXT record and normally starts with
          'v=spf1' – and add the text: <span class="sp-copy"
            (click)="copy('include:stayprivatemail.com')">include:stayprivatemail.com</span> before
          the trailing '-all'.<br>OR, if you do not have an SPF record, add a new TXT record with the following:
          <span class="sp-copy" (click)="copy('v=spf1 a mx include:stayprivatemail.com -all')">v=spf1 a mx
            include:stayprivatemail.com -all</span>.
        </li>
        <li>Add a new CNAME record called <span class="sp-copy"
            (click)="copy('stayprivate._domainkey')">stayprivate._domainkey</span> with the
          value: <span class="sp-copy"
            (click)="copy('stayprivate._domainkey.stayprivatemail.com')">stayprivate._domainkey.stayprivatemail.com</span>.
        </li>
      </ol>
      <div class="div-margin" style="max-width: 700px;">
        <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
          style="float: left;">Back</button>
        <button mat-flat-button type="submit" color="primary" class="sp-background add-company-button"
           style="float: right;" (click)="this.setProvider('otherspf')">Next</button>
      </div>
    </div>
    
    <!--4 -->
    <!--4 -->
  </mat-step>


  <!-- SET KEYWORD AND DOMAIN -->
  <mat-step>
    <!--5 -->
    <!--5 -->
    <span class="paragraph">
      Enter your corporate email domain (e.g. example.com) <span *ngIf="!isSignUp"> and choose a keyword to identify emails to send securely</span>.
      Then
      click 'Provision' to provision your company on the StayPrivate platform.
    </span>
    <form [formGroup]="setupInfo" class="main-margin" autocomplete="off">
      <div class="div-margin">
        <mat-label class="company-detail-tab company-detail-tab-active">Domain</mat-label><br>
        <mat-form-field appearance="outline" style="width: 100%;max-width: 300px; display: block;">
          <input matInput placeholder="stayprivate.com" class="text-padding" formControlName="Domain" type="text">
          <mat-error *ngIf="setupInfo.controls['Domain'].hasError('required')">
            A domain is <strong>required</strong>.
          </mat-error>
          <mat-error *ngIf="setupInfo.controls['Domain'].hasError('pattern')">
            The domain must be <strong>valid</strong>. @ symbol is not required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="div-margin" *ngIf="!isSignUp">
        <mat-label class="company-detail-tab company-detail-tab-active">Keyword (optional)</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;max-width: 300px; display: block;">
          <input matInput placeholder="#stayprivate" class="text-padding" formControlName="Keyword" type="text">
          <button matSuffix mat-icon-button [matMenuTriggerFor]="KeywordHelp" aria-label="Info button"
            style="bottom:3px" type="button">
            <mat-icon class="info-icon">info_outline</mat-icon>
          </button>
          <mat-error *ngIf="setupInfo.controls['Keyword'].hasError('required')">
            A keyword is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="div-margin">
        <button mat-stroked-button class="sp-back-btn" type="button" (click)="back()" style="float: left;">Back</button>
        <button mat-flat-button disabled *ngIf="!setupInfo.valid" style="float: right;">Provision</button>
        <button mat-flat-button type="submit" color="primary" class="sp-background add-company-button"
          *ngIf="setupInfo.valid" style="float: right;" (click)="verifyProvision(setupInfo)">Provision</button>
      </div>
    </form>
    <!--5 -->
    <!--5 -->
  </mat-step>
  <!-- END OF SET KEYWORD AND DOMAIN -->

  <!-- SETUP INSTRUCTIONS -->
  <mat-step>
    <!--6 -->
    <!--6 -->
    <ng-container *ngIf="this.setupStepper.selectedIndex === 6">
      <ng-container *ngIf="currentInfo.controls['Provider'].value == 'Microsoft 365'">
        <app-setup-microsoft [companyId]="createdId" [isSignUp]="isSignUp" (setupFinished)="finish($event)" [originStepper]="setupStepper">
        </app-setup-microsoft>
      </ng-container>
      <ng-container *ngIf="currentInfo.controls['Provider'].value == 'Google Workspace'">
        <app-setup-google [companyId]="createdId" [isSignUp]="isSignUp" [originStepper]="setupStepper" (setupFinished)="finish($event)">
        </app-setup-google>
      </ng-container>
      <ng-container *ngIf="currentInfo.controls['Provider'].value == 'Other Standard'">
        <app-setup-roundtrip [companyId]="createdId" [isSignUp]="isSignUp" (setupFinished)="finish($event)" [originStepper]="setupStepper">
        </app-setup-roundtrip>
      </ng-container>
      <ng-container *ngIf="currentInfo.controls['Provider'].value == 'Other Direct'">
        <app-setup-spf [companyId]="createdId" [isSignUp]="isSignUp" (setupFinished)="finish($event)" [originStepper]="setupStepper">
        </app-setup-spf>
      </ng-container>
      
    </ng-container>
    <!--6 -->
    <!--6 -->
  </mat-step>
  <!-- END SETUP INSTRUCTIONS -->

</mat-horizontal-stepper>


<mat-menu #KeywordHelp="matMenu">
  <div style="padding: 10px;">You can change the keyword or add further keywords later – or even build more complex
    rules based on sender, recipient, content etc.</div>
</mat-menu>