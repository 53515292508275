import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RESOURCES } from './resources.data';
export interface Resource {
  docName: string;
  docLink: string;
  imageUrl?: string;
}
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  constructor(private titleService: Title) { }
  resources=RESOURCES;
  displayedColumns: string[] = ['Thumbnail','DocName',];
  ngOnInit(): void {
    this.titleService.setTitle("Resources - STAY PRIVATE");
  }

}
 