import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { Globals } from 'src/app/globals';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  types = new FormControl();

  sortTypes: string[] = ['Company Added', 'Company Provisioned', 'Usage Exceeded', 'Logo Uploaded', 'Branding Added', 'Branding Removed', 'User Count Increased', 'User Count Decreased'];
  constructor(
    private titleService: Title,
    private http: HttpClient,
    private globals: Globals
  ) { }
  @ViewChild('tabGroup') public notificationTab: MatTabGroup;

  notifications = null
  searchTerm = ""
  notificationsList = []
  loading = true;
  collapse(i, notification) {
    var coll = document.getElementById(i);
    var collIcon = document.getElementById('i'+i);
    if (coll.style.maxHeight) {
      coll.style.maxHeight = null;
      coll.style.borderStyle = "none";
      // coll.style.display = "none";
      notification.Opened = false;
    } else {
      // coll.style.display = "block";
      coll.style.borderStyle = "solid";
      coll.style.maxHeight = coll.scrollHeight + "px";
      coll.style.opacity = "1";
      notification.Opened = true;
    }
  }
  collapseDate(i) {
    var coll = document.getElementById(i);
    var collIcon = document.getElementById('i'+i);
    
    if (coll.style.maxHeight!='0px') {
      coll.style.maxHeight = '0px';
      collIcon.classList.remove("notif-rotate")
    } else {
      coll.style.maxHeight = coll.scrollHeight + "px";
      collIcon.classList.add("notif-rotate")
    }
  }
  consoleL(el){
    console.log(el)
  }
  async ngOnInit(): Promise<void> {

    this.titleService.setTitle("Notifications - STAY PRIVATE");
    this.types.setValue(['Company Added', 'Company Provisioned', 'Usage Exceeded', 'Logo Uploaded', 'Branding Added', 'Branding Removed', 'User Count Increased', 'User Count Decreased']);
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    this.loading = true;
    this.http.get<any>(this.globals.getServicesUrl() + 'company/getNotifications', { params: param }).subscribe({
      next: data => {
        this.loading = false;
        this.notifications = data
        this.filter()
      },
      error: error => {
        this.loading = false;
        console.error('There was an error!');
      }
    });
  }
  filter() {
    let filter = this.types.value
    let newList = []
    let i = 0
    for (let notification of this.notifications) {
      if (filter.find((element) => element === notification.Type)) {
        if (notification.Message.toLowerCase().includes(this.searchTerm.toLowerCase())) {
          notification.Hierarchy.reverse();
          if (notification.Currency && notification.NewPrice) {
            if (notification.Currency == "GBP") { notification.NewPrice = "£" + notification.NewPrice; notification.Currency = "" }
            if (notification.Currency == "USD") { notification.NewPrice = "$" + notification.NewPrice; notification.Currency = "" }
            if (notification.Currency == "AUD") { notification.NewPrice = "$" + notification.NewPrice; notification.Currency = "" }
            if (notification.Currency == "EUR") { notification.NewPrice = "€" + notification.NewPrice; notification.Currency = "" }
          }
          if (notification.NewLimit && notification.NewLimit.StorageLimit) {
            notification.NewLimit.StorageLimit = notification.NewLimit.StorageLimit / 1024
            notification.NewLimit.StorageLimitOld = notification.NewLimit.StorageLimitOld / 1024
          }
          notification.Opened = false;
          newList.push(notification)
        }
      }
      i++
      if (i == this.notifications.length) {
        const groups = newList.reduce((groups, notifi) => {
          let date = this.dateConvert(notifi.NotificationDateUTC)
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

          let todayD = new Date()
          let todayS = todayD.getDate() + " " + months[todayD.getMonth()] + " " + todayD.getFullYear();
          let yesterdayD = new Date()
          yesterdayD.setDate(todayD.getDate() - 1);
          let yesterdayS = yesterdayD.getDate() + " " + months[yesterdayD.getMonth()] + " " + yesterdayD.getFullYear();
          if (date == todayS) { date = "Today" }
          if (date == yesterdayS) { date = "Yesterday" }
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(notifi);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            utc: groups[date][0].NotificationDateUTC,
            notifis: groups[date]
          };
        });
        groupArrays.sort(function (a, b) {
          return b.utc - a.utc;
        });
        for (let group of groupArrays) {
          group.notifis.sort(function (a, b) {
            return b.NotificationDateUTC - a.NotificationDateUTC;
          });
        }
        this.notificationsList = groupArrays
      }
    }
  }
  dateConvert(str) {
    let date = new Date(parseInt(str))
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
  }
  timeConvert(str) {
    let date = new Date(parseInt(str))
    return date.toLocaleString('en-GB', { hour: 'numeric', hour12: false }) + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  }
  goToPref() {
    this.notificationTab.selectedIndex = 1
  }

}
