<div *ngIf="state=='google'">
    <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="center sp-title">We just need to verify
        your email
    </mat-card-subtitle>
</div>
<div *ngIf="state!='google'">
    <h1 mat-dialog-title class="add-company-title sp-title" style="font-size: 19px;" *ngIf="valid==null">Checking your
        domains...</h1>
    <h1 mat-dialog-title class="add-company-title sp-title" style="font-size: 19px;" *ngIf="valid==false">Error checking
        domain!</h1>
    <h1 mat-dialog-title class="add-company-title sp-title" style="font-size: 19px;" *ngIf="valid==true">Domain check
        complete!</h1>
</div>

<div *ngFor="let record of records" style="padding-top: 5px;padding-bottom:5px;">
    <div *ngIf="state=='google'">
        <p style="margin-bottom: 0px; font-size: 17px;" class="sp-title center">
            {{record.name}}</p>
        <div>
            <mat-horizontal-stepper [linear]="false" #setupStepper style="margin-top: 30px;" *ngIf="record.googleError==null">
                <mat-step>
                    <span class="center">Please enter your email to verify with. </span>
                    <div class="center">
                        <mat-form-field appearance="outline"  style="width: 260px;">
                            <input matInput placeholder="username" class="field-padding"
                                style="vertical-align: text-bottom;">
                            <span matSuffix class="field-padding"
                                style="vertical-align: text-top;">@{{record.name}}</span>
                        </mat-form-field>

                    </div>
                    <button mat-stroked-button class="cancel-button center c-margin" (click)="sendCode()" matStepperNext>Send Code</button>

                </mat-step>
                <mat-step>
                    <span class="center">Please enter your 6-digit verification code. </span>
                    <div class="center">
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Example Company Ltd" class="field-padding">
                            <button matSuffix *ngIf="record.verified==null" mat-icon-button>
                                <mat-icon>navigate_next</mat-icon>
                            </button>
                        </mat-form-field>
                        <!--clicking this should send code and set verified to false-->
                        <mat-spinner *ngIf="record.verified==false" [diameter]="23"
                            style="top: 10px; display: inline-block;">
                        </mat-spinner>
                        <mat-icon *ngIf="record.verified==true"
                            style="top: 7px; display: inline-block;position: relative;color: green;">done</mat-icon>
                    </div>
                    <button mat-stroked-button class="cancel-button center c-margin" (click)="resendCode()" matStepperPrevious>Resend Code</button>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
        <!-- <p style="margin-bottom: 0px; height: 25px;">Google Check: 
            <mat-icon *ngIf="record.googleBool==true" style="top: 7px; display: inline-block;position: relative;color: green;">done</mat-icon>
            <mat-icon *ngIf="record.googleBool==false" style="top: 7px; display: inline-block;position: relative;color: red;">close</mat-icon>
            <mat-spinner *ngIf="record.googleBool==null" [diameter]="23" style="top: 10px; display: inline-block;"></mat-spinner>
        </p>  -->
        <mat-error *ngIf="record.googleError!=null" class="center">
            {{record.googleError}}.
        </mat-error>
    </div>
    <div *ngIf="state!='google'">
        <mat-divider></mat-divider>
        <p style="margin-bottom: 0px;margin-top: 10px; font-size: 17px;" class="add-company-title sp-title">
            {{record.name}}</p>
        <p style="margin-bottom: 0px; height: 25px;">SPF Check:
            <mat-icon *ngIf="record.spfBool==true"
                style="top: 7px; display: inline-block;position: relative;color: green;">done</mat-icon>
            <mat-icon *ngIf="record.spfBool==false"
                style="top: 7px; display: inline-block;position: relative;color: red;">close</mat-icon>
            <mat-spinner *ngIf="record.spfBool==null" [diameter]="23" style="top: 10px; display: inline-block;">
            </mat-spinner>
        </p>
        <p style="margin-bottom: 5px; height: 25px;">
            DKIM Check:
            <mat-icon *ngIf="record.dkimBool==true"
                style="top: 7px; display: inline-block;position: relative;color: green;">done</mat-icon>
            <mat-icon *ngIf="record.dkimBool==false"
                style="top: 7px; display: inline-block;position: relative;color: red;">close</mat-icon>
            <mat-spinner *ngIf="record.dkimBool==null" [diameter]="23" style="top: 7px; display: inline-block;">
            </mat-spinner>
        </p>
        <mat-error
            *ngIf="record.spfError!=null&&record.spfError!='Email not found'&&record.spfError!='Failed to reach server. Please try again.'">
            {{record.spfError}}.
        </mat-error>
        <mat-error *ngIf="record.dkimError!=null">
            {{record.dkimError}}.
        </mat-error>
    <mat-error *ngIf="message">{{message}}</mat-error>

    </div>

</div>


<span *ngIf="valid==true">
    <p class=" sp-title" style="font-size: 13px; margin-top: 10px;">Please click Setup Company to finish your setup.</p>
</span>

<div mat-dialog-actions>

    <button mat-stroked-button class="cancel-button" (click)="back()">Back</button>
    <!-- <button mat-stroked-button class="ok-button" [disabled]="!valid" (click)="onSubmit()" >OK</button> -->
    <button mat-flat-button color="primary" class="sp-background add-company-button center" style="float: right;"
        *ngIf="valid" (click)="onSubmit()">Setup Company</button>
</div>
<!-- <mat-list role="list">
    <mat-list-item *ngFor="let record of records" role="listitem">
        {{record.name}}<br>
        SPF Check: {{record.spfBool}}<br>
        <mat-error *ngIf="record.SPFError!=null">
            {{record.spfError}}
        </mat-error>
        DKIM Check: {{record.dkimBool}}<br>
        <mat-error *ngIf="record.dkimError!=null">
            {{record.dkimError}}
        </mat-error>
    </mat-list-item>
</mat-list> -->