<mat-card class="form-padding" *ngIf="loading">
  <mat-spinner diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>

</mat-card>

<mat-card class="form-padding" *ngIf="!loading">
  <mat-card-title class="title-padding sp-title" style="position: relative; top:2px; padding-bottom: 25px;">
    {{addCompanyForm.value.CompanyName}}
    <span *ngIf="showConfigEdit"class="config-edit">
      <button *ngIf="!editConfigMode" mat-icon-button class="edit-icon" (click)="editConfigClicked()">
        <mat-icon>edit</mat-icon>
      </button>
  
      <button *ngIf="editConfigMode&&!configDirty" mat-flat-button class="edit-button sp-disabled" color="primary" disabled>Update Company</button>
      <button *ngIf="editConfigMode&&configDirty" mat-flat-button class="sp-background edit-button" color="primary"
      (click)="onConfigSubmit()">Update Company</button>
      <button *ngIf="editConfigMode" mat-stroked-button class="sp-back-btn edit-button" type="button"
        (click)="onConfigCancel()">Cancel Edit</button>
    </span>
    <span *ngIf="showEdit" style="position: relative; bottom:7px;">
      <button mat-stroked-button class="setup-pill"
        *ngIf='!addCompanyForm.value.DomainList[0]&&addCompanyForm.value.InitialTier!="0"&&addCompanyForm.value.StatusInd=="A"' disabled>Setup
        Incomplete</button>
      <button mat-stroked-button class="suspended-pill" *ngIf='addCompanyForm.value.StatusInd=="I"'>Company Suspended</button>
      <button mat-stroked-button class="deleted-pill" *ngIf='addCompanyForm.value.StatusInd=="D"'>Company Deleted</button>

      <button *ngIf="!editMode&&addCompanyForm.value.StatusInd=='A'" mat-icon-button class="edit-icon" (click)="editClicked()">
        <mat-icon>edit</mat-icon>
      </button>

      <button *ngIf="editMode&&!addCompanyForm.dirty&&currentSeatCount==this.seatCount" mat-flat-button class="edit-button sp-disabled" color="primary" disabled>Update Company</button>
      <button *ngIf="editMode&&!addCompanyForm.dirty&&currentSeatCount!=this.seatCount" mat-flat-button class="sp-background edit-button" color="primary"
        (click)="onSubmit()">Update Company</button>
        <button *ngIf="editMode&&addCompanyForm.dirty" mat-flat-button class="sp-background edit-button" color="primary"
        (click)="onSubmit()">Update Company</button>
      <button *ngIf="editMode" mat-stroked-button class="sp-back-btn edit-button" type="button"
        (click)="onCancel()">Cancel Edit</button>
    </span>

  </mat-card-title>
  <mat-tab-group id="tabGroup" #tabGroup (selectedTabChange)="onTabChanged($event);" style="width: 100%;">
    <mat-tab label="Details">
      <mat-card-content>
        <form [formGroup]="addCompanyForm" style="margin-top: 25px;" autocomplete="off">

          <!-- Company Name Field -->
          <div class="field">
            <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyName'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Company name</mat-label>
   
            <mat-form-field appearance="outline" class="max-width">
              <input class="text-padding" matInput placeholder="Example Company Ltd" formControlName="CompanyName">
              <mat-error *ngIf="addCompanyForm.controls['CompanyName'].hasError('required')">
                Company name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Company Ident Field -->
          <div class="field">
            <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyIdent'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Unique company identifier</mat-label>
            <mat-form-field appearance="outline" class="max-width">
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="example-company-name" formControlName="CompanyIdent">
              <mat-error *ngIf="addCompanyForm.controls['CompanyIdent'].hasError('required')">
                Company identifier is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Company Type Selector -->
          <div class="field">
            <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyType'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Company type</mat-label>
            <mat-form-field appearance="outline" class="max-width">
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="example-company-name" formControlName="CompanyType">
            </mat-form-field>
          </div>

          <!-- Pricing Tier Field -->
          <div class="field" *ngIf="!(editMode && (tier=='0' || tier==''))">
            <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['InitialTier'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Pricing tier</mat-label>
            <mat-form-field appearance="outline" class="max-width">
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput formControlName="InitialTier">
            </mat-form-field>
          </div>
          <div class="field" *ngIf="editMode && (tier=='0' || tier=='')"  style="float: right;">
            <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['InitialTier'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Pricing tier</mat-label>
            <!-- <mat-form-field appearance="outline" class="max-width">
                <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput formControlName="InitialTier">
              </mat-form-field> -->
            <mat-form-field appearance="outline" class="max-width">
              <mat-select [formControl]="addCompanyForm.controls['InitialTier']" style="height: 21px;">
                <mat-option *ngFor="let initialTier of initialTiers" [value]="initialTier.id">
                  {{initialTier.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="field" *ngIf="(tier=='113'||tier=='114') && editMode" style="float: left;">
            <mat-label class="company-detail-tab company-detail-tab-active">Licenses</mat-label>
            <mat-form-field appearance="outline" class="max-width">
              <mat-select [formControl]="addCompanyForm.controls['SeatCount']"
              placeholder="Up to 3 Users" style="height: 21px;">
                <mat-option [value]="3">Up to 3 Users</mat-option>
                  <mat-option [value]="4">4 Users</mat-option>
                  <mat-option [value]="5">5 Users</mat-option>
                  <mat-option [value]="6">6 Users</mat-option>
                  <mat-option [value]="7">7 Users</mat-option>
                  <mat-option [value]="8">8 Users</mat-option>
                  <mat-option [value]="9">9 Users</mat-option>
                  <mat-option [value]="10">10 Users</mat-option>
                  <mat-option [value]="11">11 Users</mat-option>
                  <mat-option [value]="12">12 Users</mat-option>
                  <mat-option [value]="13">13 Users</mat-option>
                  <mat-option [value]="14">14 Users</mat-option>
                  <mat-option [value]="15">15 Users</mat-option>
                  <mat-option [value]="16">16 Users</mat-option>
                  <mat-option [value]="17">17 Users</mat-option>
                  <mat-option [value]="18">18 Users</mat-option>
                  <mat-option [value]="19">19 Users</mat-option>
                  <mat-option [value]="20">20 Users</mat-option>
                  <mat-option [value]="21">21 Users</mat-option>
                  <mat-option [value]="22">22 Users</mat-option>
                  <mat-option [value]="23">23 Users</mat-option>
                  <mat-option [value]="24">24 Users</mat-option>
                  <mat-option [value]="25">Unlimited Users</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field" *ngIf="(tier=='113'||tier=='114') && !editMode">
            <mat-label class="company-detail-tab company-detail-tab-active">Licenses</mat-label>
            <mat-form-field appearance="outline" class="max-width" >
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="example-company-name" disabled [value]="seatCountView">
            </mat-form-field>
          </div>
          <div class="field" *ngIf="(tier=='2'||tier=='3') && editMode && addCompanyForm.value.ParentCompanyId != '9584' ">
            <mat-label class="company-detail-tab company-detail-tab-active">User count</mat-label>
            <mat-form-field appearance="outline" class="max-width" >
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput type="number"
              [(ngModel)]="seatCount"  (change)="add()" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
          <div class="field" *ngIf="(tier=='2'||tier=='3') && !editMode && addCompanyForm.value.ParentCompanyId != '9584' ">
            <mat-label class="company-detail-tab company-detail-tab-active">User count</mat-label>
            <mat-form-field appearance="outline" class="max-width" >
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="example-company-name" disabled [value]="seatCount">
            </mat-form-field>
          </div>
          <!-- Email Domain Field -->
          <div class="field " *ngFor="let domain of addCompanyForm.value.DomainList; ">
            <mat-label class="company-detail-tab"[ngClass]="(editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Company domain</mat-label>
            <!-- <button mat-icon-button class="domain-button" *ngIf="i==0 && editMode" (click)="addNewItem()"><mat-icon>add</mat-icon></button> -->
            <!-- <button mat-icon-button class="domain-button" *ngIf="i>=1 && editMode" (click)="deleteNewItem(i)"><mat-icon>remove</mat-icon></button> -->
            <mat-form-field appearance="outline" class="max-width" disabled>
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="examplecompany.com" disabled [value]="domain">
            </mat-form-field>
          </div>
          <!-- Setup Method Field -->
          <div class="field " *ngIf="setupMethod">
            <mat-label class="company-detail-tab"[ngClass]="(editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Mail server</mat-label>
            <mat-form-field appearance="outline" class="max-width" disabled>
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="examplecompany.com" disabled [value]="setupMethod">
            </mat-form-field>
          </div>
          <!-- Keyword Field -->
          <div class="field " *ngIf="keyword">
            <mat-label class="company-detail-tab"[ngClass]="(editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Company keyword</mat-label>
            <mat-form-field appearance="outline" class="max-width" disabled>
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="examplecompany.com" disabled [value]="keyword">
            </mat-form-field>
          </div>
          <div class="field">
            <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['DataCountryCode'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Data location</mat-label>
            <mat-form-field appearance="outline" class="max-width">
              <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                placeholder="example-company-name" formControlName="DataCountryCode">
              <mat-error *ngIf="addCompanyForm.controls['DataCountryCode'].hasError('required')">
                Data location is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Optionals -->
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngIf="canAutoDelete && keyword">
              <mat-expansion-panel-header style="margin: 0px 10px;">
                <mat-panel-title>
                  <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Settings
                  </mat-card-subtitle>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="field">
                <mat-checkbox color="primary" style="padding: 0px 0px 15px 10px; display: block;" formControlName="AllowMsgAutoDeleteFlag" labelPosition="before"><span style="padding-right: 20px;">Email auto-deletion enabled:</span> 
                </mat-checkbox>
              </div>
              <div class="field" *ngIf="addCompanyForm.value.AllowMsgAutoDeleteFlag">
                <mat-label class="company-detail-tab" [ngClass]="(editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Auto delete keyword</mat-label>
                <mat-form-field appearance="outline" class="max-width" disabled>
                  <input [ngClass]="(editMode)? 'sp-label':''" class="text-padding" matInput
                    placeholder="examplecompany.com" disabled [value]="expireKeyword">
                </mat-form-field>
              </div>

              <!-- Contact Email Field -->
              <div class="field" *ngIf="addCompanyForm.value.AllowMsgAutoDeleteFlag">
                <mat-label class="company-detail-tab"[ngClass]="(editMode&&addCompanyForm.controls['CompanyName'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Automatically delete emails after:
                </mat-label>
                <mat-form-field appearance="outline" style="width: calc(100% - 100px);"  class="remove-right">
                  <input class="text-padding" matInput type="number" placeholder="example@stayprivate.com"
                    formControlName="MaxTimeToDeleteInd" multiple>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100px; vertical-align: middle;" class="remove-left">
                  <mat-select [formControl]="addCompanyForm.controls['MaxTimeToDeleteIndFormat']" style="height: 21px;" class="select-padding">
                    <mat-option *ngFor="let timeFormat of timeFormats" [value]="timeFormat.value" >
                      <span>{{timeFormat.viewValue}}</span>
                   </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- Contact Name Field -->
              </div>
              <div class="field" *ngIf="addCompanyForm.value.AllowMsgAutoDeleteFlag">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyName'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Automatically delete read emails after:</mat-label>
                <mat-form-field appearance="outline" style="width: calc(100% - 100px);"  class="remove-right">
                  <input class="text-padding" matInput type="number" formControlName="TimeToDeleteAfterReadInd"
                    placeholder="0" multiple>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100px; vertical-align: middle;"  class="remove-left">
                  <mat-select [formControl]="addCompanyForm.controls['TimeToDeleteAfterReadIndFormat']" class="select-padding"
                    style="height: 21px;">
                    <mat-option *ngFor="let timeFormat of timeFormats" [value]="timeFormat.value">
                      {{timeFormat.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header style="margin: 0px 10px;">
                <mat-panel-title>
                  <mat-card-subtitle style="padding-left: 10px; padding-top: 15px;" class="sp-title">Contact Details
                  </mat-card-subtitle>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- Contact Name Field -->
              <div class="field">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&!addCompanyForm.controls['ContactName'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Contact name</mat-label>
                <mat-form-field appearance="outline" class="max-width">
                  <input class="text-padding" matInput placeholder="John Doe" formControlName="ContactName" multiple>
                </mat-form-field>
              </div>
              <!-- Contact Email Field -->
              <div class="field">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['ContactEmail'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Contact email</mat-label>
                 <mat-form-field appearance="outline" class="max-width">
                  <input class="text-padding" matInput placeholder="example@stayprivate.com"
                    formControlName="ContactEmail" multiple>
                </mat-form-field>
              </div>
              <!-- Contact Telephone Field -->
              <div class="field">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['ContactTelNo'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Contact telephone number</mat-label>
                <mat-form-field appearance="outline" class="max-width">
                  <input class="text-padding" matInput placeholder="02071015000" formControlName="ContactTelNo">
                </mat-form-field>
              </div>
              <!-- Company Telephone Field -->
              <div class="field">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyTelNo'].disabled)? 'company-detail-tab-d':'company-detail-tab-active'">Company telephone number</mat-label>
                <mat-form-field appearance="outline" class="max-width">
                  <input class="text-padding" matInput placeholder="020 7101 5000" formControlName="CompanyTelNo">
                </mat-form-field>
              </div>
              <!-- Company VAT Field -->
              <div class="field">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyVatNo'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">VAT number</mat-label>
                 <mat-form-field appearance="outline" class="max-width">
                  <input class="text-padding" matInput placeholder="0000000000" formControlName="CompanyVatNo">
                </mat-form-field>
              </div>
              <!-- Company Address Field -->
              <div class="field" style="vertical-align: top;">
                <mat-label class="company-detail-tab" [ngClass]="(editMode&&addCompanyForm.controls['CompanyAddress'].disabled)? 'company-detail-tab-disabled':'company-detail-tab-active'">Address</mat-label>
                <mat-form-field appearance="outline" class="max-width">
                  <textarea class="text-padding" style="overflow: hidden;" matInput
                    placeholder="StayPrivate Ltd, 33 Cavendish Square, London W1G 0PW" formControlName="CompanyAddress"
                    rows="3"></textarea>
                </mat-form-field>
              </div>
              <!-- SetUp Email Field -->
            </mat-expansion-panel>
          </mat-accordion>
        </form>
      </mat-card-content>
      <div class="suspended-overlay" *ngIf="this.company.StatusInd=='I'"><span>Account Suspended<br>Please contact
        support for more information.</span></div>
    </mat-tab>
    <mat-tab label="Users">
      <ng-template matTabContent>
        <app-user-list [companyState]="this.company.StatusInd" [owner]="this.company.Owner"></app-user-list>
        <div class="suspended-overlay" *ngIf="company.StatusInd=='I'"><span>Account Suspended<br>Please contact support
            for more information.</span></div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Statistics" *ngIf="addCompanyForm.value.DomainList!=''">
      <ng-template matTabContent>
        <app-company-stats [setup]="addCompanyForm.value.EmailProcessRulesDomainList[0].RulesInd"></app-company-stats>
        <div class="suspended-overlay" *ngIf="this.company.StatusInd=='I'"><span>Account Suspended<br>Please contact
            support for more information.</span></div>
      </ng-template>
    </mat-tab>
    <mat-tab id="configTab" #setupTab label="Configuration" *ngIf="addCompanyForm.value.EmailProcessRulesDomainList[0]&&addCompanyForm.value.EmailProcessRulesDomainList[0].RulesInd!=''">
      <ng-template matTabContent>
        <app-company-config [company]="company" [editMode]="editConfigMode" (showConfigEdit)="showConfig($event)"(configDirty)="onConfigDirty($event)"(configFormM)="getConfig($event)">
        </app-company-config>
        <div class="suspended-overlay" *ngIf="company.StatusInd=='I'"><span>Account Suspended<br>Please contact support
            for more information.</span></div>
      </ng-template>
    </mat-tab>
    
    <mat-tab id="setupTab" #setupTab label="Setup">
      <ng-template matTabContent>
        <app-company-setup-process [createdId]="companyID" [companyName]="addCompanyForm.value.CompanyName"
          [companyDomain]="addCompanyForm.value.DomainList[0]" [companyState]="this.company.StatusInd"
          (setupFinished)="tabGroup.selectedIndex=0">
        </app-company-setup-process>
        <div class="suspended-overlay" *ngIf="company.StatusInd=='I'"><span>Account Suspended<br>Please contact support
            for more information.</span></div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Billing" *ngIf="globals.isSpUser()">
      <ng-template matTabContent>
        <app-company-billing></app-company-billing>
      </ng-template>
    </mat-tab>
    <mat-tab label="Branding">
      <ng-template matTabContent>
        <app-company-logo [thisCompanyName]="this.company.CompanyName" [teir]="this.company.InitialTier"
          [type]="this.company.SPMCompanyType" [companyState]="this.company.StatusInd"></app-company-logo>
        <div class="suspended-overlay" *ngIf="this.company.StatusInd=='I'"><span>Account Suspended<br>Please contact
            support for more information.</span></div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>