import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, DoCheck, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { io } from 'socket.io-client';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SetupCompleteComponent } from '../setup-complete/setup-complete.component';
import { domain } from 'process';
import { SetupDkimComponent } from '../setup-dkim/setup-dkim.component';
import { ProvisionCompleteComponent } from '../provision-complete/provision-complete.component';

export interface PowershellFlow {
  stage: string;
  status: string;
}
const POWERSHELL_FLOW: PowershellFlow[] = [
  { stage: 'Authenticating', status: "P" },
  { stage: 'Verifying domain', status: "W" },
  { stage: 'Adding connectors', status: "W" },
  { stage: 'Adding mail-flow rules', status: "W" },
  { stage: 'Finishing up', status: "W" },
];
@Component({
  selector: 'app-company-setup-process',
  templateUrl: './company-setup-process.component.html',
  styleUrls: ['./company-setup-process.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class CompanySetupProcessComponent implements OnInit, DoCheck {

  constructor(
    private fb: FormBuilder,
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    public route: ActivatedRoute,
    private clipboard: Clipboard,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }
  @Output() setupFinished = new EventEmitter();
  @ViewChild('setupStepper') public myStepper: MatStepper;
  @Input() public tier: string;
  @Input() public createdId: string;
  @Input() public companyName: string;
  @Input() public companyState: string;
  @Input() public companyDomain: string;
  //PROVIDERS
  //microsoft: Roundtrip through Microsoft.
  //google: Roundtrip through Google.
  //othermx: Roundtrip through any other provider.
  //otherspf: Alternative to roundtrip. We send the email on their behalf.

  blankForm(form): any {
    return form = this.fb.group({
      Security: null,
      Provider: null,
      Keyword: null,
      Domain: [null, [Validators.required, Validators.pattern('^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$')]],
      Connector: null
    });
  }
  setupInfo = this.fb.group({
    Security: null,
    Provider: null,
    Keyword: null,
    Domain: [null, [Validators.required, Validators.pattern('^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$')]],
    Connector: null
  });
  currentInfo = this.fb.group({
    Security: null,
    Provider: null,
    Keyword: null,
    Domain: [null, [Validators.required, Validators.pattern('^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$')]],
    Connector: null
  });

  loading = true;
  processing = false;
  isSignUp = false;
  landing = false;
  checked = null;
  showSecurityLevel = true;

  displayedColumns: string[] = ['stage', 'status'];
  dataSource = POWERSHELL_FLOW;
  msAuthToken: "";
  msSetupLink: string;


  ngDoCheck() {
    if (this.checked == false) {
      if (this.landing == false && this.myStepper) {
        
        this.myStepper.selectedIndex = 1
        if(this.isSignUp){
          this.setSecurity('RX')
        }
        this.checked = true
      }
    }
  }
  async ngOnInit(): Promise<void> {
    console.log(this.tier)
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    if (this.route.snapshot.params['id']) {
      this.createdId = this.route.snapshot.params['id']
      param = param.append('CompanyId', this.createdId);
      param = param.append('companyId', this.createdId);
    } else { param = param.append('companyId', this.globals.getCompanyId()); }

    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: companyData => {
        this.http.get(this.globals.getServicesUrl() + 'company/getCompanyDetails', { params: param }).subscribe({
          next: providerData => {
            let providerName: string;
            let securityLevel: string;
            let securityLevelInd: string;
            if (providerData[0].setupMethod == 'microsoft' || providerData[0].setupMethod == 'mx') { providerName = 'Microsoft 365';} else
              if (providerData[0].setupMethod == 'google') { providerName = 'Google Workspace'; } else
                if (providerData[0].setupMethod == 'othermx') { providerName = 'Other Standard' } else
                  if (providerData[0].setupMethod == 'otherspf' || providerData[0].setupMethod == 'spf') { providerName = 'Other Direct' }
            if (companyData.EmailProcessRulesDomainList[0]) {
              securityLevelInd= companyData.EmailProcessRulesDomainList[0].RulesInd;
              if (securityLevelInd == 'RX') { securityLevel = 'Keyword Only' } else
                if (securityLevelInd == 'ST') { securityLevel = 'Enforced' } else
                  if (securityLevelInd == 'SM') { securityLevel = 'Content Based' }
            }
            this.currentInfo.patchValue({
              Security: securityLevel,
              Provider: providerName,
              Keyword: companyData.CompanyKeyword,
              Domain: companyData.DomainList[0],
            })
            this.setupInfo.patchValue({
              Security: securityLevelInd,
              Provider: providerName,
              Keyword: companyData.CompanyKeyword,
              Domain: companyData.DomainList[0],
            })
            this.isSignUp = providerData[0].webSignup=='Y'
            
            this.landing = !!companyData.DomainList[0];
            this.checked = false;
            this.loading = false;
          },
          error: error => {
            console.error('There was an error!');
            this.landing = false;
            this.loading = false;
            this.checked = false;
          }
        });
      },
      error: error => {
        console.error('There was an error!');
        this.landing = false;
        this.loading = false;
        this.checked = false;
      }
    });

  }
  setSecurity(level: string) {
    this.setupInfo.patchValue({ Security: level })
    this.myStepper.next();
  }
  setProvider(provider: string) {
    this.setupInfo.patchValue({ Provider: provider })
    this.myStepper.selectedIndex = 5
  }
  goToInstructions(method: string) {
    this.myStepper.selectedIndex = 6
  }
  back() {
    let provider = this.setupInfo.controls.Provider.value
    if (provider=='otherspf'){this.myStepper.selectedIndex = 4}
    else if (provider=='othermx'){this.myStepper.selectedIndex = 3}
    else {this.myStepper.selectedIndex = 2}
  }
  verifyProvision() {
    if (this.companyState == "A") {
      let domain = new FormData;
      domain.append('domain', this.setupInfo.controls.Domain.value);
      if (this.setupInfo.controls.Provider.value == 'microsoft') {
        this.http.post<any>(this.globals.getServicesUrl() + "dns/mxCheck", domain).subscribe({
          next: data => {
            if (data.type == 'microsoft365') {
              this.setupInfo.patchValue({ Connector: this.setupInfo.controls.Domain.value + ':M365C:' + data.mxRecord.exchange })
              this.provision()
            } else if (data.type == 'google') {
              this._snackBar.open("Sorry this looks like a Google domain! Please hit 'Back' and select Google as the email provider.", "OK", { duration: 5000 });
            } else if (data.type == 'other') {
              this._snackBar.open("Sorry this does not look like a Microsoft domain! Please hit 'Back' and select Other as the email provider.", "OK", { duration: 5000 });
            }
          },
          error: error => {
            this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
          }
        });
      } else if (this.setupInfo.controls.Provider.value == 'spf') {
        this.http.post<any>(this.globals.getServicesUrl() + "dns/spfCheck", domain).subscribe({
          next: data => {
            this.http.post<any>(this.globals.getServicesUrl() + "dns/dkimCheck", domain).subscribe({
              next: data => {
                this.setupInfo.patchValue({ Connector: this.setupInfo.controls.Domain.value + ':' })
                this.provision()
              },
              error: error => {
                this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
              }
            });
          },
          error: error => {
            this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
          }
        });
      } else if (this.setupInfo.controls.Provider.value == 'google') {
        this.http.post<any>(this.globals.getServicesUrl() + "dns/mxCheck", domain).subscribe({
          next: data => {
            if (data.type == 'microsoft365') {
              this._snackBar.open("Sorry this looks like a Microsoft domain! Please hit 'Back' and select Microsoft as the email provider.", "OK", { duration: 5000 });
            } else if (data.type == 'google') {
              this.setupInfo.patchValue({ Connector: this.setupInfo.controls.Domain.value + ':GWSR' })
              this.provision()
            } else if (data.type == 'other') {
              this._snackBar.open("Sorry this does not look like a Google domain! Please hit 'Back' and select Other as the email provider.", "OK", { duration: 5000 });
            }
          },
          error: error => {
            this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
          }
        });
      } if (this.setupInfo.controls.Provider.value == 'othermx') {
        this.http.post<any>(this.globals.getServicesUrl() + "dns/mxCheck", domain).subscribe({
          next: data => {
            if (data.type == 'microsoft365') {
              this._snackBar.open("Sorry this looks like a Microsoft domain! Please hit 'Back' and select Microsoft as the email provider.", "OK", { duration: 5000 });
            } else if (data.type == 'google') {
              this._snackBar.open("Sorry this looks like a Google domain! Please hit 'Back' and select Google as the email provider.", "OK", { duration: 5000 });
            } else if (data.type == 'other') {
              this.setupInfo.patchValue({ Connector: this.setupInfo.controls.Domain.value + ':M365C:' + data.mxRecord.exchange })
              this.provision()
            }
          },
          error: error => {
            this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
          }
        });
      }
    }

  }
  async provision() {

    let domain = new FormData;
    domain.append('AuthToken', await this.globals.getAuthToken());
    domain.append('UserName', this.globals.getSystemUserName());
    domain.append('CompanyId', this.createdId);
    domain.append('DomainList', this.setupInfo.controls.Domain.value);
    domain.append('ApiSendDomainList', this.setupInfo.controls.Domain.value);
    if(!this.setupInfo.controls.Keyword.value){this.setupInfo.patchValue({Keyword:'#stayprivate'})}
    domain.append('CompanyKeyword', this.setupInfo.controls.Keyword.value);
    domain.append('IgnoreKeyword', '#ignore');
    domain.append('EmailProcessRulesDomainList', this.setupInfo.controls.Domain.value + ':' + this.setupInfo.controls.Security.value);
    if (this.setupInfo.controls.Connector.value != null) {
      domain.append('CustomMailServerDomainList', this.setupInfo.controls.Connector.value);
    } else { domain.append('CustomMailServerDomainList', "") }
    this.http.post<any>(this.globals.getApiUrl() + 'api/editSPMailCompany.php', domain).subscribe({
      next: data => {
        let form = new FormData;
        form.append('companyId', this.createdId);
        form.append('setupMethod', this.setupInfo.controls.Provider.value);
        form.append('keyword', this.setupInfo.controls.Keyword.value);
        form.append('domain', this.setupInfo.controls.Domain.value);
        this.http.post<any>(this.globals.getServicesUrl() + 'company/updateCompanySetup', form).subscribe({
          next: data => {
          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
        const dialogRef = this.dialog.open(ProvisionCompleteComponent, {
          width: '400px',
          autoFocus: false
        });
        this.myStepper.next();
        let prov
        if (this.setupInfo.controls.Provider.value == 'microsoft' || this.setupInfo.controls.Provider.value == 'mx') { prov = 'Microsoft 365' } else
          if (this.setupInfo.controls.Provider.value == 'google') { prov = 'Google Workspace' } else
            if (this.setupInfo.controls.Provider.value == 'othermx') { prov = 'Other Standard' } else
              if (this.setupInfo.controls.Provider.value == 'otherspf' || this.setupInfo.controls.Provider.value == 'spf') { prov = 'Other Direct' }
        let secu
        if (this.setupInfo.controls.Security.value == 'RX') { secu = 'Keyword Only' } else
          if (this.setupInfo.controls.Security.value == 'ST') { secu = 'Enforced' } else
            if (this.setupInfo.controls.Security.value == 'SM') { secu = 'Content Based' }

        this.currentInfo.patchValue({
          Security: secu,
          Provider: prov,
          Keyword: this.setupInfo.controls.Keyword.value,
          Domain: this.setupInfo.controls.Domain.value,
        })


      },
      error: error => {
        this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });

      }
    });

  }

  loadDNS() {
    const dialogRef = this.dialog.open(SetupDkimComponent, {
      width: '650px',
      autoFocus: false
    });
  }
  finish(event) {
    if (event != null) {
      const dialogRef = this.dialog.open(SetupCompleteComponent, {
        width: '400px',
        data: {
          keyword: event.keyword,
          domain: event.domain
        },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.setupFinished.emit()
        }
        if (result == false) {
        }
      });
    } else {
      this.setupFinished.emit()
    }

  }
  copy(copyVariable) {
    this.clipboard.copy(copyVariable);
    this._snackBar.open("Copied!", "OK", { duration: 3000 });
  }
  generateLink(form: FormGroup) {
    let img: string;
    this.myStepper.selectedIndex = 5
    if (localStorage.getItem("img-url") != null) {
      img = (this.globals.getImgUrl() + localStorage.getItem("img-url") + "logo-small.png?t=" + new Date().getTime())
    } else { img = "https://stayprivate.com/SP_Logo_Icon+Text_Blue.svg" }
    this.http.get<any>(this.globals.getServicesUrl() + 'company/createLink', { params: { companyId: this.createdId, keyword: form.value.Keyword, domain: form.value.Domain, imageUrl: img } }).subscribe({
      next: data => {
        this.msSetupLink = "https://dashboard.stayprivate.com/setup/" + data.url
      },
      error: error => {
        console.error(error)
      }
    });
  }
}
