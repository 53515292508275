import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.css']
})
export class ComposeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public options: Object = {
    events: {
      'froalaEditor.initialized': function (e, editor) {
        editor.edit.off();
      }
    },
    attribution: false,
    Key:'dKA5cC3C2B2H2E2B5A3D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4B3E4B2D2E3B1A1==',
    charCounterCount: false,
    fontSizeSelection: true,
    fontSize: ['8', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '40', '44', '48', '40', '52', '60', '72', '96'],
    fontFamily: {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Inconsolata,sans-serif': 'Inconsolata',
      'Open Sans,sans-serif': 'Open Sans',
      "'Poppins',sans-serif": 'Poppins',
      'Roboto Mono,sans-serif': 'Roboto Mono',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "'Times New Roman',Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
    },
    fontFamilySelection: true,
    fontFamilyDefaultSelection: 'Poppins',
    toolbarButtons: {
      'moreText': {
        'buttons': ['fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'textColor', 'backgroundColor', 'strikeThrough', 'subscript', 'superscript', 'clearFormatting'],
        'buttonsVisible': 0
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOLSimple', 'formatUL', 'paragraphFormat', 'lineHeight', 'outdent', 'indent'],
        'buttonsVisible': 0
      },
      'moreRich': {
        'buttons': ['insertHR', 'insertLink', 'insertImage', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
        'buttonsVisible': 0
      }
    }
  };
}
