import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanySetupProcessComponent } from '../company-setup-process/company-setup-process.component';

@Component({
  selector: 'app-provision-complete',
  templateUrl: './provision-complete.component.html',
  styleUrls: ['./provision-complete.component.css']
})
export class ProvisionCompleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CompanySetupProcessComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: {keyword: string,domain: string}
    ) { }

  ngOnInit(): void {
  }

}
