<span class="subtitle" style="padding-bottom: 10px;">Configuring your company DNS</span>
<span class="paragraph">Updating the company DNS records allows StayPrivate to send private emails on
    behalf of the company:</span>
    <div class="list">
        <ol>
          <li>Log into the DNS administration console.</li>
          <li>Find the current SPF record – this is a TXT record and normally starts with
            'v=spf1' – and add the text: <span class="sp-copy" (click)="copy('include:stayprivatemail.com')">include:stayprivatemail.com</span> before
            the trailing '-all'.<br>OR, if you do not have an SPF record, add a new TXT record with the following:
            <span class="sp-copy" (click)="copy('v=spf1 a mx include:stayprivatemail.com -all')">v=spf1 a mx include:stayprivatemail.com -all</span>.</li>
          
          <li>Add a new CNAME record called <span class="sp-copy" (click)="copy('stayprivate._domainkey')">stayprivate._domainkey</span> with the
            value: <span class="sp-copy" (click)="copy('stayprivate._domainkey.stayprivatemail.com')">stayprivate._domainkey.stayprivatemail.com</span>.
            </li>

        </ol>
      </div>
<div mat-dialog-actions>
    <!-- <button mat-stroked-button class="cancel-button" (click)="dialogRef.close()">Close</button> -->
    <button mat-flat-button class="sp-background ok-button" color="primary"
        (click)="dialogRef.close(true)">Done</button>

</div>