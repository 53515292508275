<div style="margin-top: 25px; padding: 0px 10px;">
  <span *ngIf="!logo">To apply custom branding to your StayPrivate encrypted emails, simply upload your logo below. The StayPrivate graphics team will then incorporate your logo with the text ‘powered by StayPrivate’ before pushing it live. This process typically takes 1 business day.</span>
  <span *ngIf="logo && !custlogo">Thank you for uploading your logo. The StayPrivate graphics team will add a co-branded version of your logo shortly.</span>
  <div class="logo-box" [ngClass]="(logo || custlogo)? 'double-center':''">
    <mat-card-title class="title-spacer sp-title">Your logo</mat-card-title>
    <mat-card *ngIf="logo" class="image-card mat-elevation-z2">
      <img style="width: 100%; max-width: 500px;" [src]="logo" /><br>
    </mat-card>
    <mat-card *ngIf="!logo" style="aspect-ratio: 2.2;"  class="image-card mat-elevation-z2">
      <div class="dashline">
        <mat-icon class="upload-icon material-icons-outlined">cloud_upload</mat-icon>
        <span style="margin: 10px auto">Click 'Upload logo' to add custom branding<br><span style="font-size: 12px;">Logo must be in .png format</span></span>
      </div>
    </mat-card>
    <label class="custom-file-upload sp-background logo-button" *ngIf="!logo" style="margin: 10px auto;float: none">
      Upload logo
      <input type='file' (change)="uploadLogo($event)" [(ngModel)]="logoupload">
    </label>
    <label class="custom-file-upload sp-background logo-button" *ngIf="logo">
      Update logo
      <input type='file' (change)="uploadLogo($event)" [(ngModel)]="logoupload">
    </label>
  </div>
  <div class="logo-box double-center" *ngIf="logo || custlogo" [ngClass]="(editMode)? 'double-center':''">
    <mat-card-title class="title-spacer sp-title">Co-branded Logo</mat-card-title>
    <mat-card *ngIf="custlogo" class="image-card mat-elevation-z2">
      <img style="width: 100%; max-width: 500px;" [src]="custlogo" /><br>
    </mat-card>
    <mat-card *ngIf="!custlogo" style="aspect-ratio: 2.2;"  class="image-card mat-elevation-z2">
      <div class="dashline">
        <mat-icon class="upload-icon material-icons-outlined">schedule</mat-icon>
        <span style="margin: 10px auto">Your co-branded logo will appear here soon</span>
      </div>
    </mat-card>
    <label class="custom-file-upload sp-background logo-button" *ngIf="!custlogo && globals.isSpUser()"
      style="margin: 10px auto;float: none">
      Upload logo
      <input type='file' (change)="uploadCustLogo($event)" [(ngModel)]="custlogoupload">
    </label>
    <label class="custom-file-upload sp-background logo-button" *ngIf="custlogo  && globals.isSpUser()"
      style="margin: 10px auto;float: none">
      Update logo
      <input type='file' (change)="uploadCustLogo($event)" [(ngModel)]="custlogoupload">
    </label>
  </div>
  <div *ngIf="logo || custlogo">
    <mat-card-title class="logo-title sp-title">Remove Branding</mat-card-title>
    <span class="logo-font">This will remove the co-branded logo from all future secure email. Emails will instead be sent without any branding. </span><br>
    <button mat-stroked-button style="color: #f44336; border-color: rgb(244, 67, 54); margin:20px 0px"
      (click)="removeLogo()">
      Remove Branding
    </button>
  </div>

  <mat-spinner *ngIf="loading" diameter="70" style="margin: 50px auto 50px auto;"></mat-spinner>

</div>