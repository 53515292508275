import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatStepper } from '@angular/material/stepper';
import { io } from 'socket.io-client';
import { SetupCompleteComponent } from '../setup-complete/setup-complete.component';
import { domain } from 'process';
import { SetupSteps } from 'src/app/setupSteps';

export interface PowershellFlow {
  stage: string;
  status: string;
}
const POWERSHELL_FLOW: PowershellFlow[] = [
  { stage: 'Authenticating', status: "P" },
  { stage: 'Verifying domain', status: "W" },
  { stage: 'Adding connectors', status: "W" },
  { stage: 'Adding mail-flow rules', status: "W" },
  { stage: 'Finishing up', status: "W" },
];
@Component({
  selector: 'app-setup-microsoft',
  templateUrl: './setup-microsoft.component.html',
  styleUrls: ['./setup-microsoft.component.css']
})
export class SetupMicrosoftComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private globals: Globals,
    private setupSteps: SetupSteps,
    private http: HttpClient,
    private router: Router,
    public route: ActivatedRoute,

    private clipboard: Clipboard,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }
  @ViewChild('setupStepper') public myStepper: MatStepper;
  @ViewChild('microsoftStepper') public microsoftStepper: MatStepper;
  @Output() setupFinished = new EventEmitter();
  @Input() public eventEmit: any;
  @Input() public originStepper: MatStepper;
  @Input() public companyId: string;
  @Input() public isSignUp: boolean;
  @Input() public keyword: string;
  @Input() public domain: string;
  @Input() public companyName: string;

  autoDisabled = false;//True to grey out autosetup button
  powershellRules = {
    connect: '',
    outboundConnector: '',
    inboundConnector: '',
    identifyRule: '',
    replyRule: '',
    allowRule: ''
  }
  inputDomain = "";
  loading = false;
  displayedColumns: string[] = ['stage', 'status'];
  dataSource;
  msAuthToken: "";
  processing = false;
  personalRule = false;
  microsoftSetup;
  link: string;
  onTabChanged($event) {
    if ($event.selectedIndex === 0) {
      this.dataSource = [
        { stage: 'Authenticating', status: "P" },
        { stage: 'Verifying domain', status: "W" },
        { stage: 'Adding connectors', status: "W" },
        { stage: 'Adding mail-flow rules', status: "W" },
        { stage: 'Finishing up', status: "W" },
      ];
      if (this.inputDomain != "") {
        this.domain = ""
        this.inputDomain = ""
      }
    } else {
    }
  }
  ngOnInit(): void {
    this.dataSource = POWERSHELL_FLOW;
    this.route.queryParams.subscribe(params => {
      if (params['1']) {
        this.link = params['1']
      } else if (this.companyId) {
        let param = new HttpParams()
        param = param.append('companyId', this.companyId)
        this.http.get<any>(this.globals.getServicesUrl() + 'company/createLink', { params: param }).subscribe({
          next: data => {
            this.link = data.id
            this.keyword = data.keyword
            this.domain = data.domain
            this.companyName = data.name
            this.powershellRules = {
              connect: 'Connect-ExchangeOnline -Device -DelegatedOrganization ' + this.domain,
              outboundConnector: 'New-OutboundConnector -Name "Send to StayPrivate" -UseMxRecord $false -SmartHosts sendsecure.stayprivate.com -IsTransportRuleScoped $true',
              inboundConnector: 'New-InboundConnector -Name "Receive from StayPrivate" -SenderDomains *.' + this.domain + ' -ConnectorType OnPremises -CloudServicesMailEnabled $true -RestrictDomainsToCertificate $true -RequireTls $true -TlsSenderCertificateName *.stayprivate.com',
              identifyRule: 'New-TransportRule "Identify messages to send via StayPrivate" -SentToScope "NotInOrganization" -SenderDomainIs "' + this.domain + '" -ExceptIfSenderIpRanges "18.130.40.2" -ExceptIfHeaderMatchesMessageHeader "x-stayprivate-processed" -ExceptIfHeaderMatchesPatterns "true"  -RouteMessageOutboundConnector "Send to StayPrivate"',
              replyRule: 'New-TransportRule "Send secure replies to StayPrivate" -SubjectOrBodyMatchesPatterns "#stayprivate-secure-reply" -ExceptIfSenderDomainIs "' + this.domain + '" -ExceptIfSenderIpRanges "18.130.40.2" -BlindCopyTo server@stayprivatemail.com',
              allowRule: 'New-TransportRule "StayPrivate Allow" -SenderIpRanges "18.130.40.2" -SetSCL "-1"'
            }
            if(this.isSignUp){
              this.powershellRules.identifyRule = 'New-TransportRule "Identify messages to send via StayPrivate" -SubjectOrBodyMatchesPatterns "#stayprivate" -SenderDomainIs "' + this.domain + '" -ExceptIfSenderIpRanges "18.130.40.2" -ExceptIfHeaderMatchesMessageHeader "x-stayprivate-processed" -ExceptIfHeaderMatchesPatterns "true"  -RouteMessageOutboundConnector "Send to StayPrivate"'
            }
            

          },
          error: error => {
            console.error('There was an error!', error);
          }
        });
      }
    });

    this.microsoftSetup = this.setupSteps.getMicrosoftSteps(this.keyword);

  }
  setupCompany() {
    let addPer = "N"
    let standAlone ="N"
    if (this.personalRule == true) { addPer = "Y" } else { addPer = "N" }
    if (this.isSignUp == true) { standAlone = "Y" } else { standAlone = "N" }

    if (!this.domain) { this.domain = this.inputDomain; this.keyword = "#stayprivate" }
    // this.domain=this.inputDomain
    this.myStepper.selectedIndex = 4

    if (this.domain) {
      const socket = io(this.globals.getServicesUrl(), {
        query: {
          "Domain": this.domain,
          "Keyword": this.keyword,
          "Personal": addPer,
          "StandAlone": standAlone
        }
      });
      this.loading = true;
      socket.on('authToken', (data) => {
        this.msAuthToken = data;
        this.loading = false;
      })
      let fetchedDomains;
      socket.on('domains', (data) => {
        fetchedDomains = data;

      })
      socket.on('message', (data) => {
        if (data === "Authenticated") { this.dataSource[0].status = "S"; this.dataSource[1].status = "P" }
        if (data === "Domain verified") { this.dataSource[1].status = "S"; this.dataSource[2].status = "P" }
        if (data === "Connectors Added") { this.dataSource[2].status = "S"; this.dataSource[3].status = "P" }
        if (data === "Rules Added") { this.dataSource[3].status = "S"; this.dataSource[4].status = "P" }
        if (data === "Finished Up") { this.dataSource[4].status = "S"; this.finish() }
      })
      socket.on('error', (data) => {
        if (data === "Error authenticating") {
          this.dataSource[0].status = "F"
          socket.disconnect()
          socket.close()
          this.myStepper.selectedIndex = 0
          this._snackBar.open("There was an issue authenticating your domain. Please contact support@stayprivate.com.", "OK", { duration: 3000 });

        }
        if (data === "Domain not found in server") {
          this.dataSource[0].status = "F"
          socket.disconnect()
          socket.close()
          this.myStepper.selectedIndex = 0
          this._snackBar.open("Your domain was not found. Please try again or contact support@stayprivate.com.", "OK", { duration: 3000 });
        }
        if (data === "Error adding connectors") { this.dataSource[2].status = "F"; this.dataSource[3].status = "P" }
        if (data === "Error adding rules") { this.dataSource[3].status = "F"; this.dataSource[4].status = "P" }
        if (data === "Session Expired") {
          this.dataSource[0].status = "F"
          socket.disconnect()
          socket.close()
          this.myStepper.selectedIndex = 0
          this._snackBar.open("Your session has timed out. Please try again.", "OK", { duration: 3000 });
        }
      })
      socket.on('json', (data) => {
      })
      socket.on("disconnect", (reason) => {
        socket.disconnect()
        socket.close()
        console.log('disconnect', reason)
      });

    }
  }
  copy(copyVariable) {
    this.clipboard.copy(copyVariable);
    this._snackBar.open("Copied!", "OK", { duration: 3000 });
  }
  finish() {
    this.setupFinished.emit({ domain: this.domain, keyword: this.keyword })

  }
  openAuth() {
    this.processing = true;
    window.open("https://microsoft.com/devicelogin", "", "top=100,left=100,width=500,height=600");
  }
}
