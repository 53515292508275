<mat-card-title class="title-padding sp-title">
    Notification Preferences
    <!-- <button mat-flat-button class="sp-background edit-button" color="primary"
    >Preferences</button> -->
    <button mat-stroked-button class="sp-back-btn " style="float: right; margin-right: 10px;" (click)="goToNotif()">
        <span class="material-icons-outlined">history</span> History
    </button>
</mat-card-title>
<span class="small-pad">Select the notifications you would like to recieve via email.</span> 
<form [formGroup]="preferenceForm" autocomplete="off" class="small-pad">
    
<table style="width: 100%;     margin: 30px 0px 10px 0px;" >
        <tr style=" border-bottom: solid 1px #ccc;">
            <td style="width: 40%;">
                <mat-card-subtitle class="sp-title" style="margin-bottom: 5px;"></mat-card-subtitle>
            </td>
            <td style="text-align: center;">
                <mat-card-subtitle class="sp-title" style="margin-bottom: 5px;">My Company</mat-card-subtitle>
            </td>
            <td style=" text-align: center;" *ngIf="globals.getCompanyType()!='Customer'">
                <mat-card-subtitle class="sp-title" style="margin-bottom: 5px;">Wholesaler Company</mat-card-subtitle>
            </td>
            <td style="text-align: center;" *ngIf="globals.getCompanyType()!='Customer'">
                <mat-card-subtitle class="sp-title" style="margin-bottom: 5px;">Partner Company</mat-card-subtitle>
            </td>
            <td style="text-align: center;" *ngIf="globals.getCompanyType()!='Customer'">
                <mat-card-subtitle class="sp-title" style="margin-bottom: 5px;">Customer Company</mat-card-subtitle>
            </td>
        </tr>
        
        <tr *ngIf="globals.getCompanyType()!='Customer'">
            <td>New Company Added</td>
            <td class="cell">
            </td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="NewCompanyTypeWFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="NewCompanyTypeRFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="NewCompanyTypeCFlag">
                    <span class="slider round"></span>
                </label>
            </td>
        </tr>
        <tr>
            <td>Company Provisioned</td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="ProvOwnCompanyFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="ProvCompanyTypeWFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="ProvCompanyTypeRFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="ProvCompanyTypeCFlag">
                    <span class="slider round"></span>
                </label>
            </td>
        </tr>
        <tr>
            <td>Branding Added</td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="AddBrandOwnCompanyFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="AddBrandCompanyTypeWFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="AddBrandCompanyTypeRFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="AddBrandCompanyTypeCFlag">
                    <span class="slider round"></span>
                </label>
            </td>
        </tr>
        <tr>
            <td>Branding Removed</td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="RmvBrandOwnCompanyFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="RmvBrandCompanyTypeWFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="RmvBrandCompanyTypeRFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="RmvBrandCompanyTypeCFlag">
                    <span class="slider round"></span>
                </label>
            </td>
        </tr>
        <tr>
            <td>Account Overage</td>
            <td class="cell">
                <label class="switch">
                    <input type="checkbox" formControlName="UsageOverOwnCompanyFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="UsageOverHrchyCompanyTypeWFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="UsageOverHrchyCompanyTypeRFlag">
                    <span class="slider round"></span>
                </label>
            </td>
            <td class="cell" *ngIf="globals.getCompanyType()!='Customer'">
                <label class="switch">
                    <input type="checkbox" formControlName="UsageOverHrchyCompanyTypeCFlag">
                    <span class="slider round"></span>
                </label>
            </td>
        </tr>
</table>
</form>
