<div class="full-screen">
  <div class="centered">
    <form [formGroup]="resetPasswordForm" novalidate >
      <mat-card class="form-padding">
        <div class="loginImageContainer">
          <img class="loginImage" src="https://stayprivate.com/SP_Logo_Icon+Text_Blue.svg"/>
        </div>
        <mat-card-title>
          Forgotten Password
        </mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col">
                <mat-form-field >   
                    <mat-label>Password</mat-label>
                    <input matInput formnovalidate [type]="hidePassword ? 'password' : 'text'"  onBlur="" formControlName="password" >
                    <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')">
                        A password is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('pattern')">
                        Requires both upper and lower case letters and at least one number
                    </mat-error>   
                    <mat-error *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('notEqual')">
                      Passwords must match
                  </mat-error> 
                  </mat-form-field>
            
            
                  <mat-form-field>   
                    <mat-label>Confirm Password</mat-label>
                    <button type="button" mat-icon-button matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm" onBlur="" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirm">
                      <mat-icon>{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <input matInput matchingValidator="password" formnovalidate formControlName="passwordConfirm" [type]="hidePasswordConfirm ? 'password' : 'text'" >  
                    <mat-error  *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('required')">
                        A password is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('notEqual')">
                        Passwords must match
                    </mat-error> 
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <button mat-flat-button color="primary" [disabled]="!resetPasswordForm.valid" class="sp-background" (click)="onSubmit()">Reset Password</button>
          </div>
          <div class="row">
            <a mat-flat-button routerLink="/login">
              Back to log in
            </a>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>


