<div style="text-align: center;">
    <img src="../../../../assets/images/warningIllustration.svg" alt=""
        style="display: flex; width: 400px; padding: 30px 0px;    margin: auto;">
    <mat-card-title style="color: #f44336;">Are you sure?</mat-card-title>
    <p style="margin: 15px 0px">
        You are about to remove custom branding from your StayPrivate account.<br>
        All secure emails will no longer carry your custom co-branded logo.
    </p>
    <p>To remove all custom branding from your system, select 'Confirm' below.</p>
    <p>To update your logo instead, press 'Cancel' then select 'Update logo'.</p>
    <div>
        <button mat-stroked-button style="color: #407ba6; border-color: #407ba6; margin:20px 30px 20px auto; width: 25%;" (click)="cancel()">
            Cancel
        </button>
        <button mat-flat-button color="primary" style="background-color: #f44336; margin:20px auto 20px 30px;width: 25%;" (click)="confirm()">
            Confirm
        </button>
    </div>

</div>