<span class="subtitle">
  Google Configuration Process
</span>
<div class="wrapper">
  <div class="arrow-steps clearfix">
    <div class="step"[ngClass]="(googleStepper.selectedIndex >= 0)? 'current':''"> <span>Access</span> </div>
    <div class="step"[ngClass]="(googleStepper.selectedIndex >= 1)? 'current':''"> <span>Hosts</span> </div>
    <div class="step"[ngClass]="(googleStepper.selectedIndex >= 2)? 'current':''"> <span>Rules 1</span> </div>
    <div class="step"[ngClass]="(googleStepper.selectedIndex >= 3)? 'current':''"> <span>Rules 2</span> </div>
    <div class="step"[ngClass]="(googleStepper.selectedIndex >= 4)? 'current':''"> <span>Inbound</span> </div>
    <div class="step"[ngClass]="(googleStepper.selectedIndex >= 5)? 'current':''"> <span>Replies</span> </div>
  </div>
</div>
<mat-horizontal-stepper #googleStepper class="invis-stepper" labelPosition="bottom"
  style="max-width: 750px; margin: auto;">
  <mat-step label="Access">
    <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
      Access the Google Admin area
    </span>
    <div class="list"  style="margin-bottom: 30px;">
      <span>You will require administrator access to your Google mail server in order to configure it to work with StayPrivate:</span>
      <ul style="padding-top: 20px;padding-bottom: 15px;">
        <li>Log into the Google Admin console at: <a target="_blank"
            href="https://admin.google.com ">https://admin.google.com</a></li>
        <li>Select '<img src="//www.gstatic.com/apps/cpanel/resources/img/apps-hub-48.png">
          Apps'.</li>
        <li>Select 'Google Workspace' and from the list, click on '<img src="https://ssl.gstatic.com/images/branding/product/1x/hh_gmail_128dp.png"> Gmail'.</li>
      </ul>
      <div class="link-box" style="margin-bottom: 20px;">
        <div class="link-svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#407ba6"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
        </div>
        <span class="link-text" >
          If you do not have administrator access, please ask the administrator to complete the configuration using the following link: 
          <a target="_blank" [routerLink]="['/googlesetup']" [queryParams]="{'1': link}" > https://dashboard.stayprivate.com/googlesetup<span *ngIf="link">?1=</span>{{link}}</a>
        </span>
      </div>
    </div>
    <div class="main-margin">
      <div class="div-margin" style="max-width: 750px; ">
        <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.originStepper.selectedIndex = 0" *ngIf="this.originStepper"
          style="float: left;">Back</button>

        <button mat-flat-button color="primary" class="sp-background" style="float: right;" matStepperNext>Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step label="Hosts">
    <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
      Add the StayPrivate secure server as a host
    </span>
    <div class="list">
      <span>To send secure emails, StayPrivate needs to be first added as a host:</span>
      <ul style="padding-top: 20px;padding-bottom: 15px;">
        <li>Click on 'Hosts'.</li>
        <li>Click on 'ADD ROUTE' to add a new mail route.</li>
        <li>Enter the name: <span class="sp-copy" (click)="copy('StayPrivate')">StayPrivate</span></li>
        <li>In the 'Enter host name or IP' field enter <span class="sp-copy" (click)="copy('sendsecure.stayprivate.com')">sendsecure.stayprivate.com</span> and in the numeric field next to it, enter the port number: <span class="sp-copy" (click)="copy('587')">587</span></li>
        <li>Click 'Save'.</li>
      </ul>
    </div>
    <div class="main-margin">
      <div class="div-margin" style="max-width: 750px; ">
        <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
        <button mat-flat-button color="primary" class="sp-background" style="float: right;" matStepperNext>Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step label="Rules 1">
    <span class="subtitle sp-title sp-bold" style="font-size: 15px; ">
      Add the StayPrivate rule for outbound emails
    </span>
    <div class="list">
      <span>The next step is to add two rules so that the email server can identify which emails to send via the StayPrivate host:</span>
      <ul style="padding-top: 20px;padding-bottom: 15px;">
        <li>Go back to the Gmail settings page by clicking on 'Settings for Gmail' near the top.</li>
        <li>Scroll down to near the bottom and click on 'Compliance'. Then scroll down to 'Content compliance' and click 'CONFIGURE' (or if you already have a rule specified, click 'ADD ANOTHER RULE').</li>
        <li>Scroll down to 'Content compliance' and click 'CONFIGURE' (or if you already have a rule specified, click 'ADD ANOTHER RULE').</li>
        <li>Under 'Content compliance' enter the text: <span class="sp-copy" (click)="copy('Identify messages to send via StayPrivate')">Identify messages to send via StayPrivate'</span></li>
        <li>Under '1. Email messages to affect' select 'Outbound'.</li>
        <li>Under '2. Add expressions that describe...' select 'If ALL of the following match the message'. Then click on 'ADD'.</li>
        <li *ngIf="isSignUp">Click on 'Simple content match' and select 'Advanced content match'. Then under 'Location' select 'Body'.</li>
        <li *ngIf="isSignUp">Under 'Content' enter the keyword <span class="sp-copy" (click)="copy('#stayprivate')">#stayprivate</span>.</li>
        <li *ngIf="isSignUp">Click 'SAVE'.</li>
        <li *ngIf="isSignUp">Then under '2. Add expressions that...' select 'ADD' again.</li>
        <li>Click on 'Simple content match' and select 'Advanced content match'. Then under 'Location' select 'Full Headers'.</li>
        <li>Under 'Match type' select 'Not contains text'.</li>
        <li>Under 'Content' enter  the text: <span class="sp-copy" (click)="copy('x-stayprivate-processed: true')">x-stayprivate-processed: true</span>.</li>      
        <li>Click 'SAVE'.</li>
        <li>In '3. If the above expressions match, do the following' under 'Route' select 'Change route'.</li>
        <li>Click on 'Normal routing' and select 'StayPrivate'.</li>
        <li>Then under 'Spam' select 'Bypass the spam filter for this message'.</li>
        <li>Click on 'SAVE' bottom right.</li>
      </ul>
    </div>
    <div class="main-margin">
      <div class="div-margin" style="max-width: 750px; ">
        <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
        <button mat-flat-button color="primary" class="sp-background" style="float: right;" matStepperNext>Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step label="Inbound">
    <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
      Add a rule to accept incoming secure emails from StayPrivate
    </span>
    <div class="list">
      <span>After processing, StayPrivate sends secure emails back to your corporate email server for delivering onto the recipient. The email server needs to be configured to accept and relay secure emails sent by StayPrivate:</span>
      <ul style="padding-top: 20px;padding-bottom: 15px;">
        <li>Go back to the Gmail settings page by clicking on 'Settings for Gmail' near the top.</li>
        <li>Scroll down to the bottom and click on 'Routing'. Under 'Routing' scroll down and under 'SMTP relay service' click 'CONFIGURE' (or if you already have a rule specified, click 'ADD ANOTHER RULE').</li>
        <li>Under 'SMTP relay service' enter the text <span class="sp-copy" (click)="copy('Receive from StayPrivate')">Receive from StayPrivate</span>.</li>
        <li>Under '2. Authentication' select 'Only accept mail from the specified IP addresses'. Then click 'ADD'.</li>
        <li>Under 'Description' enter the text <span class="sp-copy" (click)="copy('StayPrivate')">StayPrivate</span> and under 'Enter IP address/range' enter the text: <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span></li>
        <li>Click 'SAVE'.</li>
        <li>Under '3. Encryption' select 'Require TLS encryption',</li>
        <li>Click on 'SAVE' bottom right.</li>
      </ul>
    </div>
    <div class="main-margin">
      <div class="div-margin" style="max-width: 750px; ">
        <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
        <button mat-flat-button color="primary" class="sp-background" style="float: right;" matStepperNext>Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step label="Replies">
    <span class="subtitle sp-title sp-bold" style="font-size: 15px; ">
      Add a rule to ensure secure replies are handled by StayPrivate
    </span>
    <div class="list">
      <span>To ensure that direct replies from secure corporate domains are included in StayPrivate, incoming secure replies are identified and blind copied to <span class="sp-copy" (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span>. This is achieved by adding a further compliance rule:</span>
      <ul style="padding-top: 20px;padding-bottom: 15px;">
        <li>Go to the Gmail settings page by clicking on 'Settings for Gmail' near the top.</li>
        <li>Scroll down to near the bottom and select 'Compliance'. Under 'Content compliance' click 'ADD ANOTHER RULE'.</li>
        <li>Under 'Content compliance' enter: <span class="sp-copy" (click)="copy('Send secure replies to StayPrivate')">Send secure replies to StayPrivate</span></li>
        <li>Under '1. Email messages to affect' select 'Inbound'.</li>
        <li>Under '2. Add expressions that...' select 'ADD'.</li>
        <li>Click on 'Simple content match' and select 'Advanced content match'. Then under 'Location' select 'Body'..</li>
        <li>Under 'Content' enter  <span class="sp-copy" (click)="copy('#stayprivate-secure-reply')">#stayprivate-secure-reply</span> then click 'SAVE'.</li>
        <li>In '3. If the above expressions match, do the following' under 'Route' select 'Modify message'.</li>
        <li>Under 'Also deliver to' select 'Add more recipients' then select 'ADD'.</li>
        <li>Under 'Recipient address:' enter: <span class="sp-copy" (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span></li>
        <li>Select 'SAVE'.</li>
        <li>Finally, click on 'SAVE' bottom right. This is important - otherwise your changes may be lost.</li>
      </ul>
      <span>
        That's it! StayPrivate will start working automatically.
      </span>
    </div>
    <div class="main-margin">
      <div class="div-margin" style="max-width: 750px; ">
        <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious style="float: left;">Back</button>
        <button mat-flat-button color="primary" class="sp-background" style="float: right;" *ngIf="this.originStepper"
          (click)="finish()">Finish</button>
      </div>
    </div>
  </mat-step>
  <ng-template matStepperIcon="edit">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="12px" y="12px" viewBox="0 0 488 500" style="width: 14px;" xml:space="preserve">
      <g>
        <path style="fill: #FFFFFF;" d="M485.95,258.2L245.86,498.29c-0.98,0.98-2.56,0.98-3.54,0L2.23,258.2c-0.47-0.47-0.73-1.1-0.73-1.77V44.78
                    c0-2.23,2.69-3.34,4.27-1.77l236.56,236.56c0.98,0.98,2.56,0.98,3.54,0L482.41,43.01c1.57-1.57,4.27-0.46,4.27,1.77v211.65
                    C486.68,257.09,486.42,257.73,485.95,258.2z" />
        <path style="fill: #FFFFFF;" d="M443.57,1.33H44.61c-2.23,0-3.34,2.69-1.77,4.27l199.48,199.48c0.98,0.98,2.56,0.98,3.54,0L445.34,5.6
                    C446.91,4.02,445.8,1.33,443.57,1.33z" />
      </g>
    </svg>
  </ng-template>
</mat-horizontal-stepper>