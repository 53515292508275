import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-company-stats',
  templateUrl: './company-stats.component.html',
  styleUrls: ['./company-stats.component.css']
})
export class CompanyStatsComponent implements OnInit {

  constructor(private http: HttpClient, private globals: Globals, private route: ActivatedRoute) { }
  @Input() public setup: any;

  dateAccessed: Date
  recipientTotal: number
  userTotal: number
  emailSent24h: number
  emailSent7d: number
  emailSent28d: number
  emailSent30d: number
  emailSentTotal: number
  emailReceived24h: number
  emailReceived7d: number
  emailReceived28d: number
  emailReceivedTotal: number
  storageTotal: number
  monthlyRetailPrice: number

  emailsChecked: number = 0;
  emailsWithIgnore: number = 0;
  keywordUsers: number = 0;
  keywordEmails: number = 0;
  smartUsers: number = 0;
  smartEmails: number = 0;
  standardUsers: number = 0;
  standardEmails: number = 0;

  noStats = false;
  async ngOnInit(): Promise<void> {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1)
    const EndDate = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    if (this.route.snapshot.params['id']) {
      param = param.append('CompanyId', this.route.snapshot.params['id']);
    } else {
    }
    param = param.append('StartDate', "1990-10-14");
    param = param.append('EndDate', EndDate);
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyStats.php', { params: param }).subscribe({
      next: data => {
        if (data.StatsList[0] == null) {
          this.noStats = true
        }
        //Date Accessed
        this.dateAccessed = new Date(0);
        this.dateAccessed.setUTCSeconds(data.StatsList[data.StatsList.length - 1].StatsDateUTC)
        //Recipient Total
        this.recipientTotal = data.StatsList[data.StatsList.length - 1].NumActiveRecipients;
        //User Total
        this.userTotal = data.StatsList[data.StatsList.length - 1].NumActiveUsers;
        //Email Sent 24 Hours
        if (data.StatsList[data.StatsList.length - 2] != null) {
          this.emailSent24h = data.StatsList[data.StatsList.length - 1].NumEmailsSentTotal - data.StatsList[data.StatsList.length - 2].NumEmailsSentTotal;
        }
        //Email Sent 7 Days
        let checkingS7 = true
        let iS7 = 8;
        while (checkingS7 == true) {


          if (iS7 == 1) {
            checkingS7 = false
          } else {
            if (data.StatsList[data.StatsList.length - iS7] != null) {
              this.emailSent7d = data.StatsList[data.StatsList.length - 1].NumEmailsSentTotal - data.StatsList[data.StatsList.length - iS7].NumEmailsSentTotal;
              checkingS7 = false
            } else {
              iS7 = iS7 - 1;
            }
          }

        }
        //Email Sent 28 Days
        let checkingS28 = true
        let iS28 = 29;
        while (checkingS28 == true) {
          if (iS28 <= 1) {
            checkingS28 = false
          } else {
            if (data.StatsList[data.StatsList.length - iS28] != null) {
              this.emailSent28d = data.StatsList[data.StatsList.length - 1].NumEmailsSentTotal - data.StatsList[data.StatsList.length - iS28].NumEmailsSentTotal;
              checkingS28 = false
            } else {
              iS28--;
            }
          }
        }
        //Email Sent 30 Days
        let checkingS30 = true
        let iS30 = 31;
        while (checkingS30 == true) {
          if (iS30 <= 1) {
            checkingS30 = false
          } else {
            if (data.StatsList[data.StatsList.length - iS30] != null) {
              this.emailSent30d = data.StatsList[data.StatsList.length - 1].NumEmailsSentTotal - data.StatsList[data.StatsList.length - iS30].NumEmailsSentTotal;
              checkingS30 = false
            } else {
              iS30--;
            }
          }
        }

        //Email Sent Total
        this.emailSentTotal = data.StatsList[data.StatsList.length - 1].NumEmailsSentTotal;
        //Email Received 24 Hours
        if (data.StatsList[data.StatsList.length - 2] != null) {
          this.emailReceived24h = data.StatsList[data.StatsList.length - 1].NumEmailsReceivedTotal - data.StatsList[data.StatsList.length - 2].NumEmailsReceivedTotal;
        }
        //Email Received 7 Days
        let checkingR7 = true
        let iR7 = 8;
        while (checkingR7 == true) {
          if (iR7 <= 1) {
            checkingR7 = false
          } else {
            if (data.StatsList[data.StatsList.length - iR7] != null) {
              this.emailReceived7d = data.StatsList[data.StatsList.length - 1].NumEmailsReceivedTotal - data.StatsList[data.StatsList.length - iR7].NumEmailsReceivedTotal;
              checkingR7 = false
            } else {
              iR7--;
            }
          }

        }
        //Email Received 28 Days
        let checkingR28 = true
        let iR28 = 29;
        while (checkingR28 == true) {
          if (iR28 <= 1) {
            checkingR28 = false
          } else {
            if (data.StatsList[data.StatsList.length - iR28] != null) {
              this.emailReceived28d = data.StatsList[data.StatsList.length - 1].NumEmailsReceivedTotal - data.StatsList[data.StatsList.length - iR28].NumEmailsReceivedTotal;
              checkingR28 = false
            } else {
              iR28--;
            }
          }

        }
        //Email Received Total
        this.emailReceivedTotal = data.StatsList[data.StatsList.length - 1].NumEmailsReceivedTotal;
        //Storage Total
        this.storageTotal = data.StatsList[data.StatsList.length - 1].TotalStorageUsedMB / 1024;
        //Monthly Retail Price
        let PriceA = 10 + 2.50 * Math.max(0, Math.floor((Number(this.recipientTotal) + 24) / 25) - 4) + 2.50 * Math.max(0, Math.floor(Number(this.storageTotal) / 10));
        let PriceB = 35 + 9.50 * Math.max(0, Math.floor((Number(this.recipientTotal) + 124) / 125) - 4) + 9.50 * Math.max(0, Math.floor(Number(this.storageTotal) / 50));
        let PriceC = 65 + 15 * Math.max(0, Math.floor((Number(this.recipientTotal) + 249) / 250) - 4) + 15 * Math.max(0, Math.floor(Number(this.storageTotal) / 100));
        this.monthlyRetailPrice = Math.min(PriceA, PriceB, PriceC);
        if (data.EmailProcessStatsList &&data.EmailProcessStatsList[0]) {
          // emailsChecked:number
          this.emailsChecked = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumCheckedEmails;
          // emailsWithIgnore:number
          this.emailsWithIgnore = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumEmailsWithIgnore;
          // keywordUsers:number
          this.keywordUsers = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumSPMSendersIfRX;
          // keywordEmails:number
          this.keywordEmails = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumSentViaSPMIfRX;
          // smartUsers:number
          this.smartUsers = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumSPMSendersIfSM;
          // smartEmails:number
          this.smartEmails = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumSentViaSPMIfSM;
          // standardUsers:number
          this.standardUsers = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumSPMSendersIfST;
          // standardEmails:number
          this.standardEmails = data.EmailProcessStatsList[data.EmailProcessStatsList.length - 1].NumSentViaSPMIfST;
        }
      },
      error: error => { console.error('There was an error!'); }
    })
  }

}
