import { getSyntheticPropertyName } from '@angular/compiler/src/render3/util';
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import 'froala-editor/js/plugins.pkgd.min.js';
import { domain } from 'process';
// import 'froala-editor/js/third_party/spell_checker.min.js';

@Component({
  selector: 'app-company-config',
  templateUrl: './company-config.component.html',
  styleUrls: ['./company-config.component.css']
})
export class CompanyConfigComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
  ) { }
  @Input() public company: any;
  @Input() public editMode: boolean;
  @Output() public showConfigEdit = new EventEmitter<boolean>();
  @Output() public configDirty = new EventEmitter<boolean>();
  @Output() public configFormM = new EventEmitter<any>();

  configForm = this.fb.group({
    CompanyKeyword: null,
    IgnoreKeyword: null,
    domains: null,
    RelaxedList: null,
    SmartList: null,
    StandardList: null,
    EnforcementType: null,
    EnforcementTypeFull: null,
    Statement: null,
  });

  ngOnInit(): void {
    this.showConfigEdit.emit(true);
    this.configForm.patchValue({
      CompanyKeyword: this.company.CompanyKeyword,
      IgnoreKeyword: this.company.IgnoreKeyword,
      domains: this.company.IgnoreKeyword,
      RelaxedList: this.company.EmailProcessRulesRelaxedList.join('\n'),
      SmartList: this.company.EmailProcessRulesSmartList.join('\n'),
      StandardList: this.company.EmailProcessRulesStandardList.join('\n'),
      EnforcementType: this.company.EmailProcessRulesDomainList[0].RulesInd,
      EnforcementTypeFull: this.getTypeName(this.company.EmailProcessRulesDomainList[0].RulesInd),
      Statement: this.company.DefProtectSensitiveInfoText,
    })

    
    this.configForm.valueChanges.subscribe(val => {
      this.configDirty.emit(this.configForm.dirty);
      this.configFormM.emit(this.configForm.controls)
    })
  }
  ngOnChanges() {
    if (this.editMode) {
      this.configForm.enable()
    } else {
      this.configForm.disable()
      this.configForm.patchValue({
        CompanyKeyword: this.company.CompanyKeyword,
        IgnoreKeyword: this.company.IgnoreKeyword,
        domains: this.company.IgnoreKeyword,
        EnforcementType: this.company.EmailProcessRulesDomainList[0].RulesInd,
        EnforcementTypeFull: this.getTypeName(this.company.EmailProcessRulesDomainList[0].RulesInd),
        Statement: this.company.DefProtectSensitiveInfoText,
      })
    } 

  }
  ngOnDestroy() {
    this.showConfigEdit.emit(false);
  }
  
  getTypeName(code: string) {
    if (code == 'RX') {
      return 'Keyword Only'
    } else if (code == 'SM') {
      return 'Content Based'
    } else if (code == 'ST') {
      return 'Enforced'
    }
  }
  //TEXT EDITOR OPTIONS
  public options: Object = {
    events: {
      'froalaEditor.initialized': function (e, editor) {
        editor.edit.off();
      }
    },
    attribution: false,
    key:'dKA5cC3C2B2H2E2B5A3D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4B3E4B2D2E3B1A1==',
    charCounterCount: true,
    fontSizeSelection: true,
    fontSize: ['8', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '40', '44', '48', '40', '52', '60', '72', '96'],
    fontFamily: {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Inconsolata,sans-serif': 'Inconsolata',
      'Open Sans,sans-serif': 'Open Sans',
      "'Poppins',sans-serif": 'Poppins',
      'Roboto Mono,sans-serif': 'Roboto Mono',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "'Times New Roman',Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
    },
    toolbarButtons: {
      'moreText': {
        'buttons': ['fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'textColor', 'backgroundColor', 'strikeThrough', 'subscript', 'superscript', 'clearFormatting'],
        'buttonsVisible': 5
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOLSimple', 'formatUL', 'paragraphFormat', 'lineHeight', 'outdent', 'indent'],
        'buttonsVisible': 4
      },
      'moreRich': {
        'buttons': ['insertHR', 'insertLink', 'insertImage', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
        'buttonsVisible': 0
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'spellChecker', 'selectAll',], 'align': 'right'
      }
    }
  };

}
