<span>CSV Download</span><br>

<form (click)="$event.stopPropagation()"class="password-field" [formGroup]="dbPasswordForm">
    <mat-form-field appearance="outline" class="password-field">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="dbPassword">
        <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
  </form>
  <mat-spinner *ngIf="isLoading" [diameter]="30" style="    display: inline-block;"></mat-spinner>
  <a *ngIf="!isLoading" class="edit-button" mat-icon-button id=download-report (click)="download()"><mat-icon>arrow_forward</mat-icon></a> 
   
  