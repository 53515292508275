import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanySetupProcessComponent } from '../company-setup-process/company-setup-process.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-setup-dkim',
  templateUrl: './setup-dkim.component.html',
  styleUrls: ['./setup-dkim.component.css']
})
export class SetupDkimComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CompanySetupProcessComponent>,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }
  copy(copyVariable) {
    this.clipboard.copy(copyVariable);
    this._snackBar.open("Copied!", "OK", { duration: 3000 });
  }
}
