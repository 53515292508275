<form [formGroup]="configForm" style="margin-top: 25px;" autocomplete="off">
    <!-- Company Name Field -->
    <div class="field">
        <mat-label class="company-detail-tab"
            [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Company Keyword
        </mat-label>

        <mat-form-field appearance="outline" style="width: 100%;">
            <input class="text-padding" matInput placeholder="#stayprivate" formControlName="CompanyKeyword">
        </mat-form-field>
    </div>

    <!-- Company Name Field -->
    <div class="field">
        <mat-label class="company-detail-tab"
            [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Ignore Keyword
        </mat-label>

        <mat-form-field appearance="outline" style="width: 100%;">
            <input class="text-padding" matInput placeholder="#ignore" formControlName="IgnoreKeyword">
        </mat-form-field>
    </div>
    <!-- Company Name Field -->
    <div class="field" *ngIf="!editMode">
        <mat-label class="company-detail-tab"
            [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Encryption Mode
        </mat-label>

        <mat-form-field appearance="outline" style="width: 100%;">
            <input class="text-padding" matInput placeholder="#ignore" formControlName="EnforcementTypeFull">
        </mat-form-field>
    </div>
    <div class="field" style="float: left;" *ngIf="editMode">
        <mat-label class="company-detail-tab"
            [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Encryption Mode
        </mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select formControlName="EnforcementType" style="height: 21px;">
                <mat-option [value]="'RX'">Keyword Only</mat-option>
                <mat-option [value]="'SM'" *ngIf="company.InitialTier=='Professional Plus'">Content Based</mat-option>
                <mat-option [value]="'ST'">Enforced</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="config-break"></div>

    <div class="config-address">
        <!-- Company Address Field -->
        <div class="field config-field">
            <mat-label class="company-detail-tab"
                [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Keyword Only Users
            </mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
                <textarea class="text-padding config-textarea" matInput rows="11" placeholder="No users specified."
                    formControlName="RelaxedList"></textarea>
            </mat-form-field>
        </div>
        <!-- Company Address Field -->
        <div class="field config-field">
            <mat-label class="company-detail-tab"
                [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Content Based Users
            </mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
                <textarea class="text-padding config-textarea" matInput rows="11" placeholder="No users specified."
                    formControlName="SmartList"></textarea>
            </mat-form-field>
        </div>
        <!-- Company Address Field -->
        <div class="field config-field config-center">
            <mat-label class="company-detail-tab "
                [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Enforced Users
            </mat-label>
            <mat-form-field appearance="outline" style="width: 100%;">
                <textarea class="text-padding config-textarea" matInput rows="11" placeholder="No users specified."
                    formControlName="StandardList"></textarea>
            </mat-form-field>
        </div>
    </div>

    <mat-label class="company-detail-tab " style="top: 11px; left: 21px;"
                [ngClass]="(!editMode)? 'company-detail-tab-disabled':'company-detail-tab-active'">Welcome Text
            </mat-label>
    <div *ngIf="editMode" style="margin: 10px;" [froalaEditor]="options" [(froalaModel)]="company.DefProtectSensitiveInfoText"></div>
    <div *ngIf="!editMode" class="statement-preview" [froalaView]="company.DefProtectSensitiveInfoText"></div>
</form>