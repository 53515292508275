import { Component, DoCheck, OnInit } from '@angular/core';
import { BillingService } from '../components/company-billing/billing.service';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Globals } from '../globals';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CsvUpdateDialogComponent } from '../components/csv-update-dialog/csv-update-dialog.component';
import { io } from "socket.io-client";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {


  constructor(
    public globals: Globals,
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver,
    private billingService:BillingService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) { }
  appName = localStorage.getItem('app-name')
  myCompanyType;
  parentId;
  myCompanyId = this.globals.getCompanyId();
  mode = new FormControl('side');
  start: string;
  isDeveloper = false;
  myCompanyState="";
useStripeBilling=false;

  async ngOnInit(): Promise<void> {
    this.globals.refreshAuth();
    this.globals.checkTheme();
    this.myCompanyType = this.globals.getCompanyType();
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mode = new FormControl('over');
          this.start = 'false';
        } else {
          this.mode = new FormControl('side');
          this.start = 'true';
        }
      });
      this.route.data.subscribe((data:{data:any})=>{
        // console.log('tiers:',data.data)
      })
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: data => {
        if (data.CanUseApiFlag == "Y") { this.isDeveloper = true }
        if (data.SPMCompanyType == "S") { this.myCompanyType = "Super" }
        if (data.SPMCompanyType == "W" && data.IsDealerFlag == "N") { this.myCompanyType = "Wholesaler" }
        if (data.SPMCompanyType == "W" && data.IsDealerFlag == "Y") { this.myCompanyType = "Wholesaler" }
        if (data.SPMCompanyType == "R") { this.myCompanyType = "Partner" }
        if (data.SPMCompanyType == "C") { this.myCompanyType = "Customer" }
        this.parentId=data.ParentCompanyId;
        if(this.route.snapshot.queryParams.session_id&&data.StatusInd=='I'){}
        else{this.myCompanyState=data.StatusInd;}

      },
      error: error => { console.error('There was an error!'); }
    });;
    let param1 = new HttpParams();
    param1 = param1.append('AuthToken', await this.globals.getAuthToken());
    param1 = param1.append('UserName', this.globals.getSystemUserName());
    param1 = param1.append('companyId', this.myCompanyId);
    this.http.get(this.globals.getServicesUrl() + 'company/getCompanyDetails', { params: param1 }).subscribe({
      next: data => {
        if (!data[0].showStripeFlag) { data[0].showStripeFlag = "N" }

        this.useStripeBilling= data[0].showStripeFlag.includes("Y")
          
      },
      error: error => { console.error('There was an error!'); }
    });
  }
  async genCompanies() {
    let domain = new FormData;
    domain.append('UserName', this.globals.getSystemUserName());
    domain.append('AuthToken', await this.globals.getAuthToken());
    this.http.post<any>(this.globals.getServicesUrl() + "company/addAllCompanies", domain).subscribe({
      next: data => {

      },
      error: error => {
        console.error(error)
      }
    });
  }
  async download() {
    let domain = new FormData;
    domain.append('EmailAddress', this.globals.getSystemUserName());
    domain.append('authToken', await this.globals.getAuthToken());
    this.http.post<any>(this.globals.getServicesUrl() + "utils/tracking", domain).subscribe({
      next: data => {

      },
      error: error => {
        console.error(error)
      }
    });
  }
  async downloadCompanies() {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    param = param.append('DataIncludeInd', 'F'); 
    this.http.get<any>(this.globals.getServicesUrl() + "company/companySignUpList", { params: param }).subscribe({
      next: data => {
        let date = new Date().toJSON().slice(0,10).split('-').reverse().join('-')
        console.log(data)
        this.billingService.downloadFile(data, 'CompanyList '+date);
      },
      error: error => {
        console.error(error)
      }
    });
  }
  update() {
    const dialogRef = this.dialog.open(CsvUpdateDialogComponent, {
      width: '325px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  logout() {
    let logout = new FormData;
    logout.append('AuthToken', localStorage.getItem("AuthToken"));
    logout.append('UserName', localStorage.getItem("systemUserName"));

    this.http.post<any>(this.globals.getApiUrl() + 'api/recordSPMailUserLogOut.php', logout).subscribe({
      next: data => {
      },
      error: error => {
        console.error('There was an error!');
      }
    });
    localStorage.removeItem("userName")
    localStorage.removeItem("systemUserName")
    localStorage.removeItem("AuthTokenExpiration")
    localStorage.removeItem("AuthToken")
    localStorage.removeItem("tokenType")
    this.globals.resetTracker();
    this.router.navigate(['/login'])
  }
  test(){
  }
}
