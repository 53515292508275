<h1 mat-dialog-title>Change Password</h1>
<div mat-dialog-content>
  <form [formGroup]="passwordForm" class="company-form">

    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Current Password</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input [type]="hidePasswordCurrent ? 'password' : 'text'" formControlName="passwordCurrent" autocomplete="off" placeholder="************"
          class="text-padding" matInput>
          <button type="button" class="hide-button text-padding" mat-icon-button matSuffix (click)="hidePasswordCurrent = !hidePasswordCurrent" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordCurrent">
            <mat-icon>{{hidePasswordCurrent ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="passwordForm.controls['passwordCurrent'].hasError('required')">
            A password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="passwordForm.controls['passwordCurrent'].hasError('pattern')">
            Please provide your <strong>current</strong> password
        </mat-error>            
      </mat-form-field>
    </div>
    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Password</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" autocomplete="off" placeholder="************"
          class="text-padding" matInput>
          <button type="button" class="hide-button text-padding" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="passwordForm.controls['password'].hasError('required')">
            A password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="passwordForm.controls['password'].hasError('pattern')">
            Requires both upper and lower case letters and at least one number
        </mat-error>             
      </mat-form-field>
    </div>
    <div>
      <mat-label class="company-detail-tab company-detail-tab-active">Confirm Password</mat-label>
      <mat-form-field appearance="outline" class="max-width">
        <input matchingValidator="password" formControlName="passwordConfirm" [type]="hidePasswordConfirm ? 'password' : 'text'" autocomplete="off" placeholder="************"
          class="text-padding" matInput>
          <button type="button" mat-icon-button class="hide-button text-padding"  matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirm">
            <mat-icon>{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error  *ngIf="passwordForm.controls['passwordConfirm'].hasError('required')">
            A password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="passwordForm.controls['passwordConfirm'].hasError('notEqual')">
            Passwords must match
        </mat-error>             
      </mat-form-field>
    </div> 
    </form>   
</div>
<div mat-dialog-actions>
  <button mat-stroked-button class="cancel-button sp-back-btn" (click)="onNoClick()">Cancel</button>
  <button *ngIf="!passwordForm.valid" mat-flat-button class="ok-button sp-disabled" disabled
    cdkFocusInitial>OK</button>
  <button *ngIf="passwordForm.valid" color="primary" mat-flat-button class="ok-button sp-background" (click)="onSubmit()"
    cdkFocusInitial>OK</button>

</div>