import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { BillingService } from './billing.service';

export interface File {
  name: string;
  link: string;
  date: string;
}
@Component({
  selector: 'app-company-billing',
  templateUrl: './company-billing.component.html',
  styleUrls: ['./company-billing.component.css']
})
export class CompanyBillingComponent implements OnInit {

  constructor(
    private globals: Globals, 
    private http: HttpClient,
    private route: ActivatedRoute, 
    private billingService:BillingService

  ) { }
  displayedColumns: string[] = ['name',  'date','more'];
  dataSource;
  companyId;
  async ngOnInit(): Promise<void> {
    if(this.route.snapshot.params['id']!=undefined){
      this.companyId=this.route.snapshot.params['id']
    }else{this.companyId=this.globals.getCompanyId()}
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken() );
    param = param.append('UserName', this.globals.getSystemUserName() );
    param = param.append('companyId', this.companyId );
    this.http.get(this.globals.getServicesUrl()+"billing/getFiles", { params:param}).subscribe({
      next: data => {
        this.dataSource = data;
      },
      error: error => {}

      
    });
  }
  download(fileKey:string){
    let fileType = fileKey.split('.');
    let param = new HttpParams();
    param = param.append('fileKey', fileKey );
    this.http.get(this.globals.getServicesUrl()+"billing/download", { params:param, responseType: 'blob' }).subscribe({
    next: data => {

      let binaryData = [];
      binaryData.push(data);
      let downloadLink = document.createElement('a');
      if(fileType[1]=='csv'){
        downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: 'text/csv;'}));
      downloadLink.setAttribute('download', "Usage.csv");

      }else if(fileType[1]=='pdf'){
        downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
        downloadLink.setAttribute('download', "Invoice.pdf");
    }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    },
    error: error => {
      console.error(error)

    }
  });
  }
  download1(jsonData){
    this.billingService.downloadFile(jsonData, 'Usage');
  }

}
