import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
class TierType {
  id: Number;
  name: string;
  order: number;
  description: string;
  baseRecipients?: Number;
  baseStorage?: Number;
  extraRecipients?: Number;
  extraStorage?: Number;
  branding?: Boolean;
  prices?: [{ currency: string, price: Number, extraUsage: Number }]
}
@Injectable()
export class Globals {
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }
  defaultUsername;
  defaultUserPwd;


  private userName: string;
  private owner: boolean;
  private companyId: string;
  private companyType: string;
  private companyName: string;
  private authTokenExpire: string;

  private ApiUrl: string;
  private ServicesUrl: string;
  private ImgUrl: string;
  private SPUsers: string[];
  private lastPage: string;
  private tiers: TierType[];
  private darkMode: boolean;

  start(prodMode: boolean) {
    if (prodMode == true) {
      this.ApiUrl = "https://app.stayprivate.com/ao_ws/"
      // this.ServicesUrl="https://services.stayprivate.com/"
      this.ServicesUrl = "https://services2.stayprivate.com/"
      this.ImgUrl = "https://s3.eu-west-2.amazonaws.com/stayprivate.logos/"
      this.defaultUsername = null;
      this.defaultUserPwd = null;
      this.SPUsers = ["stayprivatesales:9584", "stayprivatewholesaler:9585", "spmliveuser1:9580"]
    } else {
      this.defaultUsername = "spmsupertest1";
      this.defaultUserPwd = "%SPM%Test%Usr1";
      this.ApiUrl = "https://www.econumysecure.com/ao_ws/"
      this.ServicesUrl = "http://localhost:3030/"
      // this.ServicesUrl="http://services1.stayprivate.com:3000/"
      this.ImgUrl = "https://www.econumysecure.com"
      this.SPUsers = ["spmsupertest1:543"]
    }

    if (localStorage.getItem("darkMode") == 'true') { this.darkMode = true; this.toggleDarkMode('Y') } else
      if (localStorage.getItem("darkMode") == 'false') { this.darkMode = false; this.toggleDarkMode('Y') } else { this.darkMode = null; }


  }
  //Set value to null when not testing

  isSpUser() {
    if (this.SPUsers.find(user => user == this.getSystemUserName())) {
      return true;
    } else { return false; }
  }
  loadTiers(): Observable<any> {
    this.http.get<any>(this.ServicesUrl + 'utils/getTiers').subscribe({
      next: data => {
        console.log(data.tiers)

        this.tiers = data.tiers.sort((a, b) => {
          return a.order - b.order;
        });
      },
      error: error => {
      }

    });
    return this.http.get<any>(this.ServicesUrl + 'utils/getTiers')
  }
  getTiers() {
    console.log(this.tiers)
    return this.tiers;


  }
  getTier(tier) {
    console.log(this.tiers)

    return this.tiers.find((element) => element.id == tier);

  }
  getApiUrl() {
    return this.ApiUrl;
  }
  getServicesUrl() {
    return this.ServicesUrl;
  }
  getImgUrl() {
    return this.ImgUrl;
  }
  getUserName() {
    return this.userName;
  }
  setUserName(userInput: string) {
    this.userName = userInput
  }
  isOwner() {
    return this.owner;
  }
  setOwner(userInput: boolean) {
    this.owner = userInput
  }
  getCompanyName() {
    return this.companyName;
  }
  setCompanyName(userInput: string) {
    this.companyName = userInput
  }
  getCompanyType() {
    let typeCode = this.companyType
    if (typeCode == "g4OIkUKDWdkk1y17v") { typeCode = "Super" }
    else if (typeCode == "4PZyCH4eOjxzsc") { typeCode = "Wholesaler" }
    else if (typeCode == "Y0bgmrKacmE3vRy7HC") { typeCode = "Partner" }
    else if (typeCode == "nagoYmhF9/TU7qheCQ=") { typeCode = "Dealer" }
    else if (typeCode == "LChykO8gZQwW93wxrUW8") { typeCode = "Customer" }
    return typeCode;
  }
  setCompanyType(userInput: string) {
    let typeCode
    if (userInput == "Super") { typeCode = "g4OIkUKDWdkk1y17v" }
    else if (userInput == "Wholesaler") { typeCode = "4PZyCH4eOjxzsc" }
    else if (userInput == "Partner") { typeCode = "Y0bgmrKacmE3vRy7HC" }
    else if (userInput == "Dealer") { typeCode = "nagoYmhF9/TU7qheCQ=" }
    else if (userInput == "Customer") { typeCode = "LChykO8gZQwW93wxrUW8" }
    this.companyType = typeCode
  }

  getSystemUserName() {
    return localStorage.getItem("systemUserName")
  }
  setSystemUserName(userInput: string) {
    localStorage.setItem("systemUserName", userInput)
  }

  private timeoutTracker
  private timeoutTrackerHtml
  public timeoutTrackerObj = { time: { minute: 0, second: "00" }, limit: 0 }
  resetTracker() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker)
      clearInterval(this.timeoutTrackerHtml)
    }
  }
  getTracker() {
    return this.timeoutTrackerObj
  }
  refreshAuth() {
    this.authTokenExpire = JSON.stringify(new Date(new Date().getTime() + (60000 * 14.6)))

    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker)
    }
    this.timeoutTracker = setTimeout(() => {
      this.logout()
    }, (60000 * 14.6))
    this.timeoutTrackerObj.limit = 60 * 14.6
    // this.timeoutTrackerObj.time = 60 * 14.6
    if (this.timeoutTrackerHtml) {
      clearInterval(this.timeoutTrackerHtml)
    }
    this.timeoutTrackerHtml = setInterval(() => {
      this.timeoutTrackerObj.limit--
      this.timeoutTrackerObj.time.minute = Math.floor(this.timeoutTrackerObj.limit / 60)
      this.timeoutTrackerObj.time.second = (this.timeoutTrackerObj.limit - (this.timeoutTrackerObj.time.minute * 60)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })

    }, 1000)

  }
  checkTheme() {
    if (localStorage.getItem("background-color") == null) { localStorage.setItem("background-color", "#407ba6") }
    if (localStorage.getItem("foreground-color") == null) { localStorage.setItem("foreground-color", "#407ba6") }
    if (localStorage.getItem("highlight-color") == null) { localStorage.setItem("foreground-color", "#407ba6") }
    if (localStorage.getItem("border-color") == null) { localStorage.setItem("foreground-color", "#407ba6") }
    document.head.insertAdjacentHTML("beforeend",
      "<style>  .sp-background{background-color:" + localStorage.getItem('background-color') + "!important} "
      + ".sp-title{color:" + localStorage.getItem('foreground-color') + "!important} a{color:" + localStorage.getItem('foreground-color') + "!important}"
      + ".sp-sidenav{color:" + localStorage.getItem('highlight-color') + "}"
      + ".sp-color{color:" + localStorage.getItem('background-color') + "}"
      + ".sp-topnav{color:" + localStorage.getItem('border-color') + "!important}"
      + ".mat-ink-bar{background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-option.mat-selected{color:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-icon-button{color:" + localStorage.getItem('background-color') + "}"
      + ".mat-checkbox-checked.mat-primary .mat-checkbox-background{background-color:" + localStorage.getItem('background-color') + "}"
      + ".mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background{background-color:#b0b0b0}"
      + ".mat-progress-spinner circle, .mat-spinner circle{stroke:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-form-field-ripple{background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-pseudo-checkbox-checked{ background:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit { background-color: " + localStorage.getItem('background-color') + ";    color: #fff; }"
      + ".mat-slider-thumb { background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-slider-thumb-label{ background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".mat-slider-track-fill{ background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".arrow-steps .step.current { color: #fff!important;background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".arrow-steps .step.current:after {border-left: 17px solid " + localStorage.getItem('background-color') + "!important}"
      + ".company-detail-tab-active{   background-color: " + localStorage.getItem('background-color') + " }"
      + ".mat-form-field-appearance-outline .mat-form-field-outline { color: " + localStorage.getItem('background-color') + "  }"
      + ".mat-snack-bar-container {background:" + localStorage.getItem('background-color') + "!important}"
      + "input:checked + .slider { background-color:" + localStorage.getItem('background-color') + "!important}"
      + ".checkmark__circle {stroke:" + localStorage.getItem('background-color') + "!important}"
      + ".checkmark {box-shadow: inset 0px 0px 0px" + localStorage.getItem('background-color') + "!important}"
      + ".sp-copy{color:" + localStorage.getItem('foreground-color') + "!important}"
      + "svg[Attributes Style] {  fill: " + localStorage.getItem('background-color') + "!important}"
      + ".billing-font > b{color:" + localStorage.getItem('foreground-color') + "!important}"
      + ".tier-current{ box-shadow: 0px 0px 4px 0px " + localStorage.getItem('background-color') + " !important; border: " + localStorage.getItem('background-color') + " solid 1px!important; border-top: " + localStorage.getItem('background-color') + " solid 10px!important;}"
      + ".select-pill{border: solid 1px " + localStorage.getItem('background-color') + "; color: " + localStorage.getItem('foreground-color') + "!important;}"
      + ".selected-pill{border: solid 1px " + localStorage.getItem('highlight-color') + "; color: " + localStorage.getItem('highlight-color') + " !important;}"
      + ".current-pill{border-color: " + localStorage.getItem('highlight-color') + "; color: " + localStorage.getItem('highlight-color') + " !important;}"
      + ".tier-highlight{ box-shadow: 0px 0px 4px 0px " + localStorage.getItem('highlight-color') + "; border: " + localStorage.getItem('highlight-color') + " solid 1px; }"
      + ".strike-billing{ color: " + localStorage.getItem('highlight-color') + "; }"
      + ".strike-billing-line{ border: 1px solid " + localStorage.getItem('highlight-color') + "; }"
      + ".owner{color: " + localStorage.getItem('highlight-color') + "}"
      + ".nav-menu-link-active { background-color:" + localStorage.getItem('background-color') + "!important;}"
      + ".sp-back-btn{color: " + localStorage.getItem('foreground-color') + "; border-color:" + localStorage.getItem('background-color') + "!important;}"
      + ".upload-icon{color: " + localStorage.getItem('background-color') + "}"
      + ".stat-display-block {color:" + localStorage.getItem('background-color') + "}"
      + ".stat-display-block-new {color:" + localStorage.getItem('background-color') + "}"
      + ".sp-border{border-color:" + localStorage.getItem('background-color') + "!important}"
      + "</style>");
  }
  toggleDarkMode(start?: string) {
    if (start != 'Y') {
      this.darkMode = !this.darkMode
      localStorage.setItem("darkMode", '' + this.darkMode)
    }
    if (this.darkMode) {
      document.getElementById("main-body-target").classList.add('dark-mode');
      document.getElementById("main-body-target").classList.remove('light-mode');
    } else {
      document.getElementById("main-body-target").classList.add('light-mode');
      document.getElementById("main-body-target").classList.remove('dark-mode');
    }
  }
  async getAuthToken() {
    if (this.authTokenExpire) {
      if (new Date() > new Date(JSON.parse(this.authTokenExpire))) {
        localStorage.removeItem("systemUserName")
        localStorage.removeItem("AuthToken")
        return null;
      } else {
        return localStorage.getItem("AuthToken");
      }
    } else if (localStorage.getItem("systemUserName") && localStorage.getItem("AuthToken")) {
      let tempResp = null;
      await new Promise(async (resolve, reject) => {

        let param = new HttpParams();
        param = param = param.append('AuthToken', localStorage.getItem("AuthToken"));
        param = param.append('UserName', localStorage.getItem("systemUserName"));

        this.http.get<any>(this.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
          next: data => {
            this.refreshAuth()
            this.setCompanyName(data.CompanyName)
            if (data.SPMCompanyType == "S") { this.setCompanyType("Super") }
            if (data.SPMCompanyType == "W" && data.IsDealerFlag == "N") { this.setCompanyType("Wholesaler") }
            if (data.SPMCompanyType == "W" && data.IsDealerFlag == "Y") { this.setCompanyType("Wholesaler") }
            if (data.SPMCompanyType == "R") { this.setCompanyType("Partner") }
            if (data.SPMCompanyType == "C") { this.setCompanyType("Customer") }
            this.setOwner(data.Owner.UserName == localStorage.getItem("systemUserName"))
            tempResp = localStorage.getItem("AuthToken");

            resolve(localStorage.getItem("AuthToken"))
          },
          error: error => {
            console.error('There was an error!');
            localStorage.removeItem("systemUserName")
            localStorage.removeItem("AuthToken")
            tempResp = null;
            resolve(null)

          }
        });

      })
      return tempResp;



    }


  }
  setAuthToken(userInput: string) {
    localStorage.setItem("AuthToken", userInput)
    this.refreshAuth();
  }

  getCompanyId() {
    let temp = localStorage.getItem("systemUserName").split(":");
    let numeric_string = temp[temp.length - 1].replace(/[^0-9]/g, '');
    return numeric_string
  }
  getLastPath() {
    let temp = this.lastPage;
    this.lastPage = null;
    return temp;
  }
  private logout() {
    let logout = new FormData;
    logout.append('AuthToken', localStorage.getItem("AuthToken"));
    logout.append('UserName', localStorage.getItem("systemUserName"));
    this.http.post<any>(this.getApiUrl() + 'api/recordSPMailUserLogOut.php', logout).subscribe({
      next: data => {
        localStorage.removeItem("systemUserName")
        localStorage.removeItem("AuthToken")

        this.userName = null
        this.companyType = null
        this.authTokenExpire = null
        this._snackBar.open("Logged out for inactivity.", "OK", { duration: 5000 });
        this.lastPage = location.pathname;
        this.router.navigate(['/login'])
      },
      error: error => {
        localStorage.removeItem("systemUserName")
        localStorage.removeItem("AuthToken")

        this.userName = null
        this.companyType = null
        this.authTokenExpire = null
        this._snackBar.open("Logged out for inactivity.", "OK", { duration: 5000 });
        this.lastPage = location.pathname;
        this.router.navigate(['/login'])
        console.error('There was an error!');
      }
    });

  }

}