import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Globals } from 'src/app/globals';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.css']
})
export class DeveloperComponent implements OnInit {

  constructor(
    public globals: Globals,
    private http: HttpClient,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
    private titleService: Title
  ) { }
  keyMessage: string;
  apiAccessId: string;
  hasKey = false;
  isDeveloper = null;

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle("Developer - STAY PRIVATE");
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    this.http.get<any>(this.globals.getApiUrl() + 'api/getSPMailCompanyInfo.php', { params: param }).subscribe({
      next: data => {
        if (data.CanUseApiFlag == "Y") { this.isDeveloper = true } else { this.isDeveloper = false }
        this.apiAccessId = data.CompanyApiId
        if (data.HasApiPublicKeyFlag == "Y") { this.keyMessage = "You have already uploaded your public key."; this.hasKey = true; }
        else { this.keyMessage = "Please upload your public key." }
      },
      error: error => { console.error('There was an error!'); }
    });;
  }
  regenId() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        heading: 'Regenerate API Access ID',
        text: [
          'Are you sure that you wish to regenerate your API Access ID?',
          'This will mean that any API requests made using your current API Access ID will no longer work.'
        ]
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        let form = new FormData;
        form.append('AuthToken', await this.globals.getAuthToken());
        form.append('UserName', this.globals.getSystemUserName());
        form.append('CompanyId', this.globals.getCompanyId());
        this.http.post<any>(this.globals.getApiUrl() + 'api/regenerateSPMailCompanyApiId.php', form).subscribe({
          next: data => {
            location.reload()
          },
          error: error => {
            console.error('There was an error!');
          }
        });
      }
      if (result == false) {

      }
    });
  }
  copy() {
    this.clipboard.copy(this.apiAccessId);
    this._snackBar.open("Copied!", "OK", { duration: 3000 });

  }
  async getPublicKey() {
    let param = new HttpParams();
    param = param.append('AuthToken', await this.globals.getAuthToken());
    param = param.append('UserName', this.globals.getSystemUserName());
    this.http.get(this.globals.getApiUrl() + 'api/downloadSPMailCompanyApiPublicKey.php', { params: param, responseType: 'blob' }).subscribe({
      next: data => {

        let binaryData = [];
        binaryData.push(data);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        downloadLink.setAttribute('download', "public.key");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      },
      error: error => {
        console.error(error)

      }
    });
  }
  async onFileSelected(event) {

    const file: File = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('AuthToken', await this.globals.getAuthToken());
      formData.append('UserName', this.globals.getSystemUserName());
      formData.append('CompanyId', this.globals.getCompanyId());
      formData.append('UploadFileInd', event.target.files[0]);
      this.http.post<any>(this.globals.getApiUrl() + 'api/uploadSPMailCompanyApiPublicKey.php', formData).subscribe({
        next: data => {
          this.keyMessage = "You have successfully uploaded your public key."
        },
        error: error => {
          this._snackBar.open(error.error.ErrorMsg, "OK", { duration: 5000 });
        }
      });
    }
  }

}
