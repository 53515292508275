<div class="success-animation">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
</div>
<span class="subtitle">Company Provisioned</span>
<span class="paragraph">Your company has now been provisioned.</span>
<span class="paragraph">Click 'Next' to configure your company email server and get started using StayPrivate.</span>
<div mat-dialog-actions>
    <button mat-flat-button class="sp-background ok-button" color="primary"
        (click)="dialogRef.close(true)">Next</button>
</div>