<mat-horizontal-stepper [linear]="false" #setupStepper class="invis-stepper">
  <mat-step>
    <span class="subtitle">
      Standard Configuration
    </span>
    <div class="wrapper">
      <div class="arrow-steps clearfix">
        <div class="step" [ngClass]="(standardStepper.selectedIndex >= 0)? 'current':''"> <span>Access</span> </div>
        <div class="step" [ngClass]="(standardStepper.selectedIndex >= 1)? 'current':''"> <span>Outbound</span> </div>
        <div class="step" [ngClass]="(standardStepper.selectedIndex >= 2)? 'current':''"> <span>Inbound</span> </div>
        <div class="step" [ngClass]="(standardStepper.selectedIndex >= 3)? 'current':''"> <span>Replies</span> </div>
        <div class="step" [ngClass]="(standardStepper.selectedIndex >= 4)? 'current':''"> <span>Enable</span> </div>
      </div>
    </div>
    <mat-horizontal-stepper #standardStepper class="invis-stepper" labelPosition="bottom" [linear]="false"
      style="max-width: 750px; margin: auto;">
      <mat-step label="Access">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Access the Mail Server
        </span>

        <div class="list" style="margin-bottom: 30px;">
          <span>You will require administrator access to your email server in order to configure it to work with
            StayPrivate.</span>
        </div>
        <div class="link-box" style="margin-bottom: 20px;">
          <div class="link-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4482af">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
          </div>
          <span class="link-text">
            If you do not have administrator access to the email server, please ask the email administrator to complete
            the configuration using the following link:
            <a target="_blank" [routerLink]="['/standardsetup']" [queryParams]="{'1': link}">
              https://dashboard.stayprivate.com/standardsetup<span *ngIf="link">?1=</span>{{link}}</a>
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" (click)="this.originStepper.selectedIndex = 0"
              *ngIf="this.originStepper" style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Outbound">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Create an outbound connector to StayPrivate
        </span>
        <div class="list">
          <span>To connect with StayPrivate, outgoing secure emails need to be identified and relayed to <span
              class="sp-copy"
              (click)="copy('https://sendsecure.stayprivate.com')">https://sendsecure.stayprivate.com</span>. For most
            email servers this means:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>adding <span class="sp-copy"
                (click)="copy('https://sendsecure.stayprivate.com')">https://sendsecure.stayprivate.com</span> as a host
              or connector; and</li>
            <li>creating a conditional routing rule to route emails to this host if the email has an external recipient 
              and is not sent from the IP address <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span>
            </li>
          </ul>
          <span>
            For detailed instructions of how to do this for Microsoft email servers, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 1">here</a>.
          </span><br><br>
          <span>
            For detailed instructions of how to do this in Google Workspace, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 2">here</a>.
          </span>
        </div>

        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Inbound">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Accept inbound emails from StayPrivate
        </span>
        <div class="list">
          <span>In the standard setup, StayPrivate sends secure emails back to your corporate email server for
            forwarding onto the recipient. Your corporate email server therefore needs to be configured to accept and
            relay secure emails sent by StayPrivate. You can identify secure emails in either of two ways:</span>
          <ul style="padding-top: 20px;padding-bottom: 15px;">
            <li>by the sender IP address: <span class="sp-copy" (click)="copy('18.130.40.2')">18.130.40.2</span>; or
            </li>
            <li>by the certificate used to sign the emails: <span class="sp-copy"
                (click)="copy('*.stayprivate.com')">*.stayprivate.com</span>.</li>
          </ul>
          <span>
            For detailed instructions of how to do this for Microsoft email servers, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 1">here</a>.
          </span><br><br>
          <span>
            For detailed instructions of how to do this in Google Workspace, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 2">here</a>.
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              matStepperNext>Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Replies">
        <span class="subtitle sp-title sp-bold" style="font-size: 15px;">
          Forward secure replies to StayPrivate
        </span>
        <div class="list">
          <span>To ensure that secure replies from secure corporate domains are included in
            StayPrivate, incoming secure replies should
            be identified and blind copied to <span class="sp-copy"
              (click)="copy('server@stayprivatemail.com')">server@stayprivatemail.com</span>. For most email servers
            this is achieved by adding a compliance rule to add the above address to the 'bcc' field if an incoming
            email contains the text:<span class="sp-copy" (click)="copy('#stayprivate-secure-reply')">#stayprivate-secure-reply</span>.
          </span>
          <br><br>
          <span>
            For detailed instructions of how to do this for Microsoft email servers, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 1">here</a>.
          </span><br><br>
          <span>
            For detailed instructions of how to do this in Google Workspace, click <a [routerLink]=""
              (click)="this.setupStepper.selectedIndex = 2">here</a>.
          </span>
        </div>
        <div class="main-margin">
          <div class="div-margin" style="max-width: 750px; ">
            <button mat-stroked-button class="sp-back-btn" type="button" matStepperPrevious
              style="float: left;">Back</button>
            <button mat-flat-button color="primary" class="sp-background" style="float: right;"
              *ngIf="this.originStepper" (click)="finish()">Finish</button>
          </div>
        </div>
      </mat-step>
      <ng-template matStepperIcon="edit">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="12px" y="12px" viewBox="0 0 488 500" style="width: 14px;" xml:space="preserve">
          <g>
            <path style="fill: #FFFFFF;" d="M485.95,258.2L245.86,498.29c-0.98,0.98-2.56,0.98-3.54,0L2.23,258.2c-0.47-0.47-0.73-1.1-0.73-1.77V44.78
              c0-2.23,2.69-3.34,4.27-1.77l236.56,236.56c0.98,0.98,2.56,0.98,3.54,0L482.41,43.01c1.57-1.57,4.27-0.46,4.27,1.77v211.65
              C486.68,257.09,486.42,257.73,485.95,258.2z" />
            <path style="fill: #FFFFFF;" d="M443.57,1.33H44.61c-2.23,0-3.34,2.69-1.77,4.27l199.48,199.48c0.98,0.98,2.56,0.98,3.54,0L445.34,5.6
              C446.91,4.02,445.8,1.33,443.57,1.33z" />
          </g>
        </svg>
      </ng-template>
    </mat-horizontal-stepper>
  </mat-step>
  <mat-step>
    <span class="subtitle">
      Standard Configuration for Microsoft
    </span>
    <ng-container>
      <app-setup-microsoft [companyId]="companyId" (setupFinished)="finish()" [originStepper]="setupStepper">
      </app-setup-microsoft>
    </ng-container>
  </mat-step>
  <mat-step>
    <span class="subtitle">
      Standard Configuration for Google
    </span>
    <ng-container>
      <app-setup-google [companyId]="companyId" (setupFinished)="finish()" [originStepper]="setupStepper">
      </app-setup-google>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>