<mat-card class="company-form">
    <mat-card-title class="add-company-title sp-title">
      Company Setup<br>
    </mat-card-title>
      <app-company-setup-process [createdId]="companyID" [companyName]="addCompanyForm.value.CompanyName"
        [companyDomain]="addCompanyForm.value.DomainList.value[0]" [companyState]="this.company.StatusInd">
      </app-company-setup-process>
      <div class="suspended-overlay" *ngIf="this.company.StatusInd=='I'"><span>Account Suspended<br>Please contact
          support for more information.</span></div>
   
  </mat-card>