<span class="sp-title">Generate Usage and Invoices</span><br>
<mat-spinner *ngIf="isLoading"[diameter]="30" style="    display: inline-block;"></mat-spinner>
<div *ngIf="!isLoading">
  <span>Usage reports and invoices were last generated on {{lastAccessed}}.<br><br>Do you wish to generate again.</span><br>

  <button mat-flat-button class="sp-background edit-button" color="primary" (click)="updateDB()">Yes</button>
  <button mat-flat-button class="sp-background edit-button" color="primary" (click)="dialogRef.close()">No</button>
</div>



<!-- <mat-tab-group [selectedIndex]="selected.value" class="invis-header">
  <mat-tab label="First">
    <form (click)="$event.stopPropagation()"class="password-field" [formGroup]="dbPasswordForm">
      <mat-form-field appearance="outline" class="password-field">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="dbPassword">
          <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
    </form>
    <mat-spinner *ngIf="isLoading" [diameter]="30" style="    display: inline-block;"></mat-spinner>
    <a *ngIf="!isLoading" class="edit-button" mat-icon-button id=download-report (click)="getLastAccessed()"><mat-icon>arrow_forward</mat-icon></a> 
  </mat-tab>
  <mat-tab label="Second">    
    Last updated: {{lastAccessed}}<br>
    Do you wish to update again?
    <mat-spinner *ngIf="isLoading"[diameter]="30" style="    display: inline-block;"></mat-spinner>
    <a *ngIf="!isLoading" class="edit-button" mat-icon-button id=download-report (click)="updateDB()"><mat-icon>arrow_forward</mat-icon></a> 

  </mat-tab>
</mat-tab-group>
   -->

  